import axios from 'axios';
import React, { useState, useEffect } from 'react'

// import { io } from "socket.io-client";
import { getUser } from '../Utils/Common';

import { baseAPI } from '../config';
const retus = require("retus");

function Test() {

    // const { body } = retus("https://google.com");
    // //1console.log(body);

    retus.post(baseAPI+'/api/button/click/add',  {json:{label:'TESTTTT'}})




    const [userid, setUserid] = useState(null)
    const [allmsgs, setallmsgs] = useState('')
    // const [socket, setSocket] = useState(null)
    useEffect(() => {
        // window.addEventListener('beforeunload', e=>{
        //     e.preventDefault();
        //     retus.post(baseAPI+'/api/button/click/add',  {json:{label:'****'}})
        //     return e.returnValue = 'Are you sure?'
        // })

        // let blob = new Blob([JSON.stringify({label:'****'})], {});
        var blob= new Blob([JSON.stringify({label:'****'})], {type : 'application/json; charset=UTF-8'}); // the blob
        
        window.addEventListener('beforeunload', e=>{
            e.preventDefault();
            
            navigator.sendBeacon(baseAPI+'/api/button/click/add', blob);
            return e.returnValue = 'Are you sure?'
        })
        
        // axios.post(baseAPI+'/api/user/virtualLogin', {phone: getUser()}).then(r=>{

        //     //1console.log(r);
        //     setUserid(r.data.id)
        // })


        // setSocket(io("wss://api.acdexpo.com", {
        //     transports: ["polling"]
        //   }))
        
      
          
    }, [])

    // const socket = io("wss://api.acdexpo.com", {
    //   transports: ["polling"]
    // });


    
        


        // socket?.on("connect", () => {
        //     //1console.log("connected", socket?.id);
        // //   const x = socket.emit("send", {
        // //     "message_data":
        // //     {
        // //     "message": "new message 2",
        // //     "sender_role": "moderator",
        // //     "user_id": 1,
        // //     "event_id": 1,
        // //     "reply_to": 1,
        // //     }
            
        // //   });
        // //   //1console.log(x);
        // //   const y = socket.on('receive', (message) => {
        // //     //Make changes in front end
        // //     //1console.log(message);
        // //    })
        // //    //1console.log(y);
        // // window.addEventListener('beforeunload', ()=>{
        // //     //1console.log('%ctest', 'font-size:30px;color:blue');

        // //     axios.post(baseAPI+'/api/button/click/add', {
   
        // //         "label":'TESTTT'
                
        // //     })
        // // }, false)

        // // window.addEventListener('beforeunload', e=>{
        // //     e.preventDefault();
        // //     axios.post(baseAPI+'/api/button/click/add', {
   
        // //          "label":'TESTTT'
                        
        // //     })
        // //     return e.returnValue = 'Are you sure?'
        // // })

        
            
        // }, []);
// socket?.on('receive', (message) => {
//     //Make changes in front end
//     //1console.log('%c on receive','font-size: 30px; color:cornflowerblue', message);
//     setallmsgs(message)
//     })
// socket?.on("connect_error", (error) => {
//     //1console.log(error);
//     // revert to classic upgrade
//     // socket.io.opts.transports = ["polling", "websocket"];
// });
// socket?.on("disconnected", (error) => {
//     //1console.log("error", error);
// });
// socket?.on("error_message", (message) => {
//     //1console.log("error message from server", message);
// });
//     }, [socket])
    
    // function sendMessage(){
    //           socket.emit("send", {
                  
    //                 message: "new message 2",
    //                 sender_role: "moderator",
    //                 user_id: 4,
    //                 event_id: 44
                    
                    
                  
                 
    //             });
    //       }

    // function deleteMessage(){
    //     socket.emit('delete',
    //         {
    //          "message_id":57
            
    //         })
            
    // }
    // function getMessages(){
    //     socket.on('receive', (message) => {
    //         //Make changes in front end
    //         //1console.log(message);
    //         setallmsgs(message)
    //         })
    // }
    return (
        <div>
            {JSON.stringify(allmsgs) }
            
        </div>
    )
}

export default Test
