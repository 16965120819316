import React, { useState, useEffect } from 'react'

export default function Input(props) {
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function validateMobile(num){
        const re = /^(?:(\+))?(?:[0-9]{0,3}[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{4}\)))?(\d{4})(?:(?!\(\d{4})\))?[\s.\/-]?)?$/
       return re.test(num)
    }
    const [validEmail, setValidEmail] = useState(true)
    useEffect(() => {
        
        if(props.type==='email' && props.value!==''){
            setValidEmail(validateEmail(props.value))
        }
    }, [props.value])
    function checkType(){
        switch(props.type){
            case 'text area':
                return <textarea name={props.name} disabled={props.disabled} value={props.value} onChange={props.onChange} placeholder={props.placeholder?props.placeholder:'Enter '+props.name} style={{height: props.compress||props.compress2?'100px':''}}/>
                break;
            case 'email':
                return <input type={props.type} disabled={props.disabled} name={props.name} value={props.value} onChange={(e)=>{props.onChange(e);}} placeholder={props.placeholder?props.placeholder:'Enter '+props.name} style={{border: validEmail?'':'1px solid red'}} />
                break;

            case 'number':
                return <input type={props.type} disabled={props.disabled} name={props.name} value={props.value} min={props.min} max={props.max} onChange={(e)=>{props.onChange(e);}} placeholder={props.placeholder?props.placeholder:'Enter '+props.name} style={{height: props.compress||props.compress2?'30px':'', width: props.compress||props.compress2?'60%':''}}/>
            default:
                return <input type={props.type} disabled={props.disabled} name={props.name} value={props.value} onChange={props.onChange} placeholder={props.placeholder?props.placeholder:'Enter '+props.name} style={{height: props.compress||props.compress2?'30px':'', width: props.compress||props.compress2?'60%':''}}/>
        }
    }
    return (
        <div className='text-field' style={props.style}>
            <span className='label' style={{...props.labelStyle, height: props.compress2?'30px':''}}>{props.name}</span>
            {
                
               checkType()
                
                    
                
                
                
            
                
                // props.type==='text area'?
                // <textarea name={props.name} value={props.value} onChange={props.onChange} placeholder={props.placeholder?props.placeholder:'Enter '+props.name} />
                
                // :
                // <input type={props.type} name={props.name} value={props.value} onChange={props.onChange} placeholder={props.placeholder?props.placeholder:'Enter '+props.name} />
                
            }
        
        </div>
    )
}
