
import dashboardIcon from '../../img-src/icons/dashboard.png'
import manageEventIcon from '../../img-src/icons/calendar.png'
import archiveIcon from '../../img-src/icons/archive.png'
import analyticsIcon from '../../img-src/icons/analytics.png'
import downIcon from '../../img-src/icons/down.png'
import formIcon from '../../img-src/icons/form.png'
import accessIcon from '../../img-src/icons/access.png'
import React, { useState, useEffect } from 'react'

function AdminNav(props) {
    const [eventExpanded, setEventExpanded] = useState(true);
    const [analysisExpanded, setAnalysisExpanded] = useState(true)
    const [formresponsesExpanded, setFormresponsesExpanded] = useState(true)
    useEffect(() => {
        
        //1console.log(props);
    }, [props])
    return (
        <nav className='a-nav' style={props.style}>
            <ul className='a-nav-items'>
                <li className='a-nav-item' >
                    <div className='a-nav-item-header'  onClick={()=>window.location.href='/admin'} style={{backgroundColor: props.active===0?'#32AAA9':''}}>
                        <img src={dashboardIcon} />
                        <p>Dashboard</p>
                    </div>
                </li>
                <li className='a-nav-item' >
                    <div className='a-nav-item-header' style={{backgroundColor: props.active===1?'#32AAA9':''}}>
                        <img src={manageEventIcon}  onClick={()=>window.location.href='/admin/manage'} />
                        <p onClick={()=>window.location.href='/admin/manage'}>Create/Edit Event</p>
                        <img src={downIcon} className={eventExpanded? 'expand-icon rotate180': 'expand-icon'} onClick={()=>setEventExpanded(!eventExpanded)}/>
                    </div>
                    <ul className='a-nav-sub-items' style={{height: eventExpanded?'100%':0}}>
                        <li><a href='/admin/manage#basic-info'>Basic Info</a></li>
                        <li><a href='/admin/manage#speakers'>Speakers</a></li>
                        <li><a href='/admin/manage#handouts'>Handouts</a></li>
                        <li><a href='/admin/manage#giveaways'>Giveaways</a></li>
                        <li><a href='/admin/manage#agenda'>Agenda</a></li>
                        
                        <li><a href='/admin/manage#invitee-list'>Invitee List</a></li>
                        <li><a href='/admin/manage#event-link'>Event Display Link</a></li>
                        <li><a href='/admin/manage#chat-module'>Chat Module</a></li>
                        
                        <li><a href='/admin/manage#feedback-forms'>Feedback Forms</a></li>
                    </ul>
                </li>
            
                <li className='a-nav-item' onClick={()=>window.location.href='/admin/archive'} >
                    <div className='a-nav-item-header' style={{backgroundColor: props.active===2?'#32AAA9':''}}>
                        <img src={archiveIcon} />
                        <p>Archiving Room</p>
                    </div>
                </li>

                <li className='a-nav-item'  >
                    <div className='a-nav-item-header' style={{backgroundColor: props.active===3?'#32AAA9':''}}>
                        <img src={analyticsIcon}  onClick={()=>window.location.href='/admin/past'}/>
                        <p onClick={()=>window.location.href='/admin/past'}>Analysis</p>
                        <img src={downIcon} className={analysisExpanded? 'expand-icon rotate180': 'expand-icon'} onClick={()=>setAnalysisExpanded(!analysisExpanded)} />
                    </div>
                    <ul className='a-nav-sub-items' style={{height: analysisExpanded?'100%':0}}>
                        <li><a href='/admin/past'>Event Analysis</a></li>
                        {/* <li><a href='/admin/archive'>Archive Analysis</a></li> */}
                        <li><a href='/admin/general'>Platform Analysis</a></li>
                        
                    </ul>
                </li>

                
                <li className='a-nav-item' >
                    <div className='a-nav-item-header' style={{backgroundColor: props.active===4?'#32AAA9':''}}>
                        <img src={formIcon}  onClick={()=>window.location.href='/admin/reg-forms'}/>
                        <p onClick={()=>window.location.href='/admin/reg-forms'}>Form Responses</p>
                        <img src={downIcon} className={formresponsesExpanded? 'expand-icon rotate180': 'expand-icon'} onClick={()=>setFormresponsesExpanded(!formresponsesExpanded)} />
                    </div>
                    <ul className='a-nav-sub-items' style={{height: formresponsesExpanded?'100%':0}} >
                        <li><a href='/admin/reg-forms'>Registration Forms</a></li>
                        <li><a href='/admin/feedback-forms'>Feedback Forms</a></li>
                    </ul>
                </li>
                <li className='a-nav-item' onClick={()=>window.location.href='/admin/access'} >
                    <div className='a-nav-item-header' style={{backgroundColor: props.active===5?'#32AAA9':''}}>
                        <img src={accessIcon} />
                        <p>Access</p>
                        
                    </div>
                    
                </li>
            </ul>
        </nav>
    )
}

export default AdminNav
