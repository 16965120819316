import React, { useState, useEffect } from 'react'

import questionIcon from '../../img-src/icons/question.png'
import lorealLogo from '../../img-src/loreal logo.png';
import burgerIcon from '../../img-src/icons/menu-icon.png';
import AdminNav from './AdminNav';
import ManageEvent from './ManageEvent';
import AdminDashboard from './AdminDashboard';
import { getToken, removeAdminId, removeUserSession  } from '../../Utils/Common';
import { fixfontsize } from '../helpers';
import axios from 'axios';
import PanelTable from '../Util/PanelTable';
import { adminMinWidth } from '../content';
import calendarIcon from '../../img-src/icons/calendar.png'
import clockIcon from '../../img-src/icons/clock.png'
import FilterBar from '../Fields/FilterBar';
import { baseAPI } from '../../config';
function AdminGeneralPlatformAnalytics(props) {
    const [navExpanded, setNavExpanded] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }


    const [fromTime, setFromTime] = useState('')
    const [toTime, setToTime] = useState('')
    const [fromDay, setFromDay] = useState('')
    const [toDay, setToDay] = useState('')

    const [avgEngagementTime, setAvgEngagementTime] = useState(0)
    const [numOfEvents, setNumOfEvents] = useState(0)
    const [numRevisitingUsers, setNumRevisitingUsers] = useState(0)
    const [numOfUsers, setNumOfUsers] = useState(0)
    const [usersByRegion, setUserByRegion] = useState([])
    const [buttonClicks, setButtonClicks] = useState([])
    const [rooms, setRooms] = useState([])
    const [dayFilter, setDayFilter] = useState('')
    const [useDayFilter, setUseDayFilter] = useState(false)
    const [toTimeFilter, setToTimeFilter] = useState('')
    const [fromTimeFilter, setFromTimeFilter] = useState('')
    const [useTimeFilter, setUseTimeFilter] = useState('')
    const [isLiveData, setIsLiveData] = useState(true)
    const [pageLoad, setPageLoad] = useState(false)


    function refreshDataNoFilter(){
        axios.post(baseAPI+'/api/analysis/general','', {headers:{'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data);
            setAvgEngagementTime(r.data.average_engagement_time)
            setIsLiveData(true)
            setNumOfEvents(r.data.number_of_events)
            setNumRevisitingUsers(r.data.number_of_revisting_users)
            setUserByRegion(r.data.usersByRegion)
            setRooms(r.data.rooms)
            setButtonClicks(r.data.button_clicks)
            setNumOfUsers(r.data.total_active_users)
            // axios.get('http://api.acdexpo.com/api/user/all', {headers:{'Authorization': 'bearer '+getToken()}}).then(r=>{
            //     //1console.log(r.data);
            //     setNumOfUsers(r.data.number_of_user)
            // })

        })
    }

    

    useEffect(() => {

        

        //1console.log(dayFilter);
        let d = dayFilter
        //yyyy-mm-dd
        let todayDate = new Date().toLocaleString('fr-CA').split(',')[0]

        let fTime = ''
        if(fromTimeFilter==='')
            fTime = '00:00'
        else
            fTime = fromTimeFilter

        let tTime = ''
        if(toTimeFilter==='')
            tTime = '23:59'
        else
            tTime = toTimeFilter

        let day = ''
        if(dayFilter==='')
            day = new Date().toLocaleString('fr-CA').split(',')[0]  //yyyy-mm-dd
        else
            day = dayFilter
        


        

        let filterPayload = {
            "start_date": dayFilter + ' '+ fromTimeFilter,
            "end_date": dayFilter + ' '+ toTimeFilter
        }

        filterPayload = {
            "start_date": fromDay + ' '+ fromTime,
            "end_date": toDay + ' '+ toTime
        }
        if(pageLoad){
            axios.post(baseAPI+'/api/analysis/general',filterPayload, {headers:{'Authorization': 'bearer '+getToken()}}).then(r=>{
                    //1console.log(r.data);
                    setAvgEngagementTime(r.data.average_engagement_time)
                    setIsLiveData(false)
                    setNumOfEvents(r.data.number_of_events)
                    setNumRevisitingUsers(r.data.number_of_revisting_users)
                    setUserByRegion(r.data.usersByRegion)
                    setRooms(r.data.rooms)
                    setButtonClicks(r.data.button_clicks)
                    setNumOfUsers(r.data.total_active_users)
                    // axios.get('http://api.acdexpo.com/api/user/all', {headers:{'Authorization': 'bearer '+getToken()}}).then(r=>{
                    //     //1console.log(r.data);
                    //     setNumOfUsers(r.data.number_of_user)
                    // })

                }).catch(e=>{
                    // //1console.log('hhhh');
                    //1console.log(e);
                    
                    if(pageLoad)
                        refreshDataNoFilter()
                })
        }
        
    }, [fromDay,toDay,fromTime,toTime])
    useEffect(() => {
        document.title = "L'Oréal Expo - Platform Analytics"
        let nowMinus30 = new Date()
        nowMinus30.setMinutes(nowMinus30.getMinutes()-30)
        // nowMinus30.setHours(nowMinus30.getHours()+2)
        // //1console.log(nowMinus30);
        nowMinus30 = nowMinus30.toLocaleString('fr-CA').split(',')[0]+' '+nowMinus30.toLocaleString('en-GB').split(' ')[1].slice(0,-3)
        let now = new Date()
        // now.setHours(now.getHours()+2)
        now = now.toLocaleString('fr-CA').split(',')[0]+' '+now.toLocaleString('en-GB').split(' ')[1].slice(0,-3)

        //1console.log(nowMinus30,now);
        axios.post(baseAPI+'/api/analysis/general',{
            //30 mins ago
            "start_date": nowMinus30,
            //now
            "end_date": now
        }, {headers:{'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data);
            setAvgEngagementTime(r.data.average_engagement_time)
            
            setNumOfEvents(r.data.number_of_events)
            setNumRevisitingUsers(r.data.number_of_revisting_users)
            setUserByRegion(r.data.usersByRegion)
            setRooms(r.data.rooms)
            setButtonClicks(r.data.button_clicks)
            setNumOfUsers(r.data.total_active_users)
            setPageLoad(true)
            // axios.get('http://api.acdexpo.com/api/user/all', {headers:{'Authorization': 'bearer '+getToken()}}).then(r=>{
            //     //1console.log(r.data);
            //     setNumOfUsers(r.data.number_of_user)
            // })

        })
    }, [])
    return (
        
        <div style={{minWidth: adminMinWidth+'px'}}>
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%'}}>
                <AdminNav style={{width: navExpanded?'16%':0}} active={3}/>

                <div style={{width: navExpanded?'84%':'100%', transition: '0.3s', display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <h1 className='p-title' style={{textAlign: 'center', width: '100%'}}><span style={{animation:'blink 1s infinite', fontSize:'20px'}}>{isLiveData?'🔴':''}</span> General Platfrom Analysis</h1>
                    
                    <FilterBar fromDay={fromDay} onChangeFromDay={e=>setFromDay(e.target.value)} ToDay={toDay} onChangeToDay={e=>setToDay(e.target.value)} FromTime={fromTime} onChangeFromTime={e=>setFromTime(e.target.value)} toTime={toTime} onChangeToTime={e=>setToTime(e.target.value)}  style={{marginLeft:'10%',marginRight:'10%', marginBottom:'5%'}}/>
                        
                    <div className='panel-style'style={{margin: '0 10%', backgroundColor: '#F7F2EC', width:'87.5%'}} >
                        <div className='panel-row' style={{display:'flex', alignItems: 'center', height: '300px', width: '100%'}}>
                        <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', marginBottom:'5%'}}>
                                <p>Total number of events</p>
                                <div className='circle-num-text'><p style={{fontSize: fixfontsize(75, numOfEvents)}}>{numOfEvents}</p><p>{'Event(s)'}</p></div>
    
                            </div>
                            <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', marginBottom:'5%'}}>
                                <p>Total number of active users</p>
                                <div className='circle-num-text'><p style={{fontSize: fixfontsize(75, numOfUsers)}}>{numOfUsers}</p><p>{'User(s)'}</p></div>
    
                            </div>
                            <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', marginBottom:'5%'}}>
                                <p>Total number of revisiting users</p>
                                <div className='circle-num-text'><p style={{fontSize: fixfontsize(75, numRevisitingUsers)}}>{numRevisitingUsers}</p><p>{'User(s)'}</p></div>
    
                            </div>
                            <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', marginBottom:'5%'}}>
                                <p>Average engagement time</p>
                                <div className='circle-num-text'><p style={{fontSize: fixfontsize(75, avgEngagementTime===null?0:parseInt(avgEngagementTime)/60>=60?(parseInt(avgEngagementTime)/3600).toFixed(2):(parseInt(avgEngagementTime)/60).toFixed(1))}}>{avgEngagementTime===null?0:parseInt(avgEngagementTime)/60>=60?(parseInt(avgEngagementTime)/3600).toFixed(2):(parseInt(avgEngagementTime)/60).toFixed(1) }</p><p>{parseInt(avgEngagementTime)/60>=60?'Hour(s)':'Minute(s)'}</p></div>
    
                            </div>
                        </div>
                    </div>
                
                
                
                    <div className='row' style={{margin:'5%',     width: '90%'}}>
                        <PanelTable headers={['Button', 'Clicks']} title='Button Clicks' data={buttonClicks.map(b=>{return{d1:b.label, d2:b.count}})} style={{margin: '15px', width:'100%', height:'400px'}}/>
                        <PanelTable headers={['Rooms', 'Users', 'Engagement']} title='Room Analytics' data={rooms.map(b=>{return{d1:b.label, d2:b.number_of_users, 
                            d3:
                                b.average_engagement_time===null?'0':parseInt(b.average_engagement_time)/60>=60?(parseInt(b.average_engagement_time)/3600).toFixed(2).toString()+'hr':(parseInt(b.average_engagement_time)/60).toFixed(1).toString()+'min'
                                
                                
                            
                            }})} style={{margin: '15px', width:'100%', height:'400px'}} cols={3}/>
                        <PanelTable headers={['Region', 'Number']} title='Users per region' data={usersByRegion.map(b=>{return{d1:b.clinical_region, d2:b.count}})} style={{margin: '15px', width:'100%', height:'400px'}}/>
                    </div>
                    
                
                
                </div>

            </div>
            
        </div>
    )
}

export default AdminGeneralPlatformAnalytics
