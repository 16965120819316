// return the user data from the session storage
export const getUser = () => {
    //const userStr = sessionStorage.getItem('user');
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
   
  // return the token from the session storage
  export const getToken = () => {
    //return sessionStorage.getItem('token') || null;
    return localStorage.getItem('token') || null;
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    // sessionStorage.removeItem('token');
    // sessionStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
   
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    // sessionStorage.setItem('token', token);
    // sessionStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
  }


   // remove the user from the session storage
   export const removeUserSessionOnly = () => {
    // sessionStorage.removeItem('token');
    // sessionStorage.removeItem('user');
    
    localStorage.removeItem('user');
  }
   
  // set the user from the session storage
  export const setUserSessionOnly = (user) => {
    // sessionStorage.setItem('token', token);
    // sessionStorage.setItem('user', JSON.stringify(user));
    
    localStorage.setItem('user', JSON.stringify(user));
  }


   


  export const getAdminId = () => {
    //return sessionStorage.getItem('token') || null;
    return localStorage.getItem('adminId') || null;
  }
  // set the admin id
  export const setAdminId = (id) => {
    
    localStorage.setItem('adminId', id);
    
  }

  // remove the admin id
  export const removeAdminId = () => {
    
    localStorage.removeItem('adminId');
    
  }

  
  export const getDummyId = () => {
    //return sessionStorage.getItem('token') || null;
    return localStorage.getItem('dummyId') || null;
  }
 
  export const setdummyId = (id) => {
    
    localStorage.setItem('dummyId', id);
    
  }

  
  export const removeDummyId = () => {
    
    localStorage.removeItem('dummyId');
    
  }