import React, { useState, useEffect, useCallback } from 'react'
import Input from '../Fields/Input'
import Upload from '../Fields/Upload'
import { useSelector, useDispatch } from 'react-redux'
import { updateHandouts } from "../slices/adminSlice";
import { getToken } from '../../Utils/Common'
import axios from 'axios'
import { baseAPI } from '../../config';
function Handouts(props) {
    const [handoutsLocal, setHandoutsLocal] = useState([])
    const [isUploadingImage, setIsUploadingImage] = useState(false)
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
      var x = handoutsLocal.map(h=>{return{title: h.Name, file: h.Handout, picture:h.Picture, description: h.Description}})
      //1console.log(x);
        dispatch(updateHandouts(x))
    }, [handoutsLocal])
    const onChange = (index, event) => {
        if(event.target.name!=='Picture'&&event.target.name!=='Handout'){
            event.preventDefault();
            event.persist();
        }
        
    
        setHandoutsLocal((prev) => {
          return prev.map((item, i) => {
            if (i !== index) {
              return item;
            }
    
            return {
              ...item,
              [event.target.name]: event.target.value
            };
          });
        });
      };

    const handleAdd = (e) => {
    e.preventDefault();
    const inputState = {
        Name: "",
        Picture: "",
        Description: '',
        Handout:''
        

        
    };

   
    setHandoutsLocal((prev) => [...prev, inputState]);
    
    };
    function handleRemove(e, i){
        
        e.preventDefault()
        
        setHandoutsLocal((prev) => prev.filter((item) => item !== prev[i]));
    }



    useEffect(() => {
      
      //initialize with saved data if available
      if(props.initValues!==null){
        var x = props.initValues.map(s=>{return{Name: s.title, Handout: s.file, Picture: s.picture, Description: s.description}})
        setHandoutsLocal(x)
    }
    }, [])

    const onDropFile = useCallback((acceptedFiles, i, name) => {
      

      if(name==='Handout'){
        setIsUploadingFile(true)
      }
      if(name==='Picture'){
        setIsUploadingImage(true)
      }
      

      //1console.log(acceptedFiles[0]);
      var formData = new FormData()
      formData.append('file', acceptedFiles[0])

      axios.post(baseAPI+'/api/files/image/upload',  formData, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
          //1console.log(r.data.url);
          onChange(i, {target: {value: r.data.url, name: name}})
          if(name==='Handout'){
            setIsUploadingFile(false)
          }
          if(name==='Picture'){
            setIsUploadingImage(false)
          }
      }).catch(e=>{
          //1console.log(e);
          if(name==='Handout'){
            setIsUploadingFile(false)
          }
          if(name==='Picture'){
            setIsUploadingImage(false)
          }
      })

    }, [])
    
    return (
        <div className='form-wrapper' style={{filter: props.active?'none':'grayscale(100)', opacity: props.active?'1':'0.4'}}>
           
            {/* {JSON.stringify(handoutsLocal)} */}
            {handoutsLocal.map((s,i)=><div  style={{width: '100%'}} key={'handounts-a-'+i}>
                                    <p className='speaker-num'>Handout {i+1}</p>
                                    <button className='warning-btn' onClick={(e)=>handleRemove(e, i)} style={{marginLeft: '7%', padding: '0.3rem 1rem', marginBottom: '5%'}}>Remove Handout</button>
                                    <div style={{display: 'flex', flexWrap: 'wrap', padding: '0 5%'}}>
                                        
                                        <Input type='text' name='Name' value={s.Name}  onChange={(e)=>onChange(i,e)}  style={{flex: '1 1 45%'}}/>
                                        <Input type='text area' name='Description' value={s.Description}  onChange={e=>onChange(i,e)} style={{height: 'auto'}} style={{flex: '1 1 45%', height:'100%'}}/>
                                        <Upload name='Handout' onDrop={(acceptedFiles) => {onDropFile(acceptedFiles, i, 'Handout')}} fileName={s.Handout?.split('/')[4]?.slice(13)} compress={true} note={'*PDF files only'} isLoading={isUploadingFile}/>
                                        <Upload name='Picture' onDrop={(acceptedFiles) => {onDropFile(acceptedFiles, i, 'Picture')}} fileName={s.Picture?.split('/')[4]?.slice(13)} compress={true} accept="image/png, image/gif, image/jpeg" note={'*Recommended size is 450x300 px (3:2 Aspect ratio)'} isLoading={isUploadingImage}/>
                                        
                                    </div>

                                </div>)}
            
            <button className='a-link' onClick={handleAdd} style={{margin:'2% 0'}}>+ Add Handout</button>
        </div>
    )
}

export default Handouts
