import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { func } from 'prop-types';
function ManageWinners() {
    
    const [w1, setW1] = useState(' ');
    const [w2, setW2] = useState(' ');
    const [w3, setW3] = useState(' ');
    const [w4, setW4] = useState(' ');
    const [w5, setW5] = useState(' ');
    const [w6, setW6] = useState(' ');
    const [w7, setW7] = useState(' ');
    const [w8, setW8] = useState(' ');
    const [w9, setW9] = useState(' ');
    const [w10, setW10] = useState(' ');
    const [w11, setW11] = useState(' ');
    const [w12, setW12] = useState(' ');
    const [w13, setW13] = useState(' ');
    const [w14, setW14] = useState(' ');
    const [w15, setW15] = useState(' ');
    const [w16, setW16] = useState(' ');
    const [w17, setW17] = useState(' ');
    const [w18, setW18] = useState(' ');
    const [w19, setW19] = useState(' ');
    const [w20, setW20] = useState(' ');
    const [currentWinners, setCurrentWinners] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState();
    const b1Style ={
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
    const b2Style ={
        width: 'auto',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        placeItems: 'center'
        
    }
    function onSubmit(){
        setLoading('Loading...')
        
        // axios.post('https://api.acdexpo.com/api/winners', {winners: [w20,w19,w18,w17,w16,w15,w14,w13,w12,w11]}).then(r=>{
        //     //setLoading('');
        //     //1console.log(r);
        //     // setLoading('Winners set successfully.')
        //     // getWinners()
        //     axios.post('https://api.acdexpo.com/api/winners', {winners: [w10,w9,w8,w7,w6,w5,w4,w3,w2,w1]}).then(r=>{
        //     //setLoading('');
        //         //1console.log(r);
        //         setLoading('Winners set successfully.')
        //         getWinners()
        //     }).catch(e=>{
        //         setLoading('');
        //         setError('Error occurred while adding winners. Please try again later.')
        //         //1console.log(e);
        //     })
        // }).catch(e=>{
        //     setLoading('');
        //     setError('Error occurred while adding winners. Please try again later.')
        //     //1console.log(e);
        // })

        clearThenSubmit()
    }
    function onClearWinners(){
        setLoading('Loading...')
        axios.delete('https://api.acdexpo.com/api/winners').then(r=>{
            //setLoading('');
            //1console.log(r);
            setLoading('Winners cleared successfully.')
            getWinners()
        }).catch(e=>{
            setLoading('');
            setError('Error occurred while clearing winners. Please try again later.')
            //1console.log(e);
        })
    }
    function clearThenSubmit(){
        axios.delete('https://api.acdexpo.com/api/winners').then(r=>{
            //setLoading('');
            //1console.log(r);
            axios.post('https://api.acdexpo.com/api/winners', {winners: [w20,w19,w18,w17,w16,w15,w14,w13,w12,w11]}).then(r=>{
            //setLoading('');
                //1console.log(r);
                // setLoading('Winners set successfully.')
                // getWinners()
                axios.post('https://api.acdexpo.com/api/winners', {winners: [w10,w9,w8,w7,w6,w5,w4,w3,w2,w1]}).then(r=>{
                //setLoading('');
                    //1console.log(r);
                    setLoading('Winners set successfully.')
                    getWinners()
                }).catch(e=>{
                    setLoading('');
                    setError('Error occurred while adding winners. Please try again later.')
                    //1console.log(e);
                })
            }).catch(e=>{
                setLoading('');
                setError('Error occurred while adding winners. Please try again later.')
                //1console.log(e);
            })
            getWinners()
        }).catch(e=>{
            setLoading('');
            setError('Error occurred while clearing winners. Please try again later.')
            //1console.log(e);
        })
    }
    useEffect(() => {
        getWinners()
        
    }, [])
    function getWinners(){
        axios.get('https://api.acdexpo.com/api/winners').then(r=>{
            //1console.log(r.data)
            setCurrentWinners(r.data.map((w,i)=><li key={i} className=''>{w.name}, </li>))
        }).catch(e=>{
            setError('Error while loading current winners')
        })
    }
    return (
        <div style={{padding: '5%'}}>
            <div style={b1Style}>
                <h1>Current Winners</h1>
                <ul style={{display: 'flex'}}>
                    {currentWinners}
                </ul>
            </div>
            <h1 style={{textAlign: 'center'}}>Manage Winners</h1>
            <div className='form' style={b2Style} >
                
                
                <div className='text-field'>
                    <span className='label'>Winner 1</span>
                    <input type='text' value={w1} onChange={e=>setW1(e.target.value)} />
                    
                </div>
                
                
                <div className='text-field'>
                    <span className='label'>Winner 2</span>
                    <input type='text' value={w2} onChange={e=>setW2(e.target.value)} />
                    
                </div>
                
                
                <div className='text-field'>
                    <span className='label'>Winner 3</span>
                    <input type='text' value={w3} onChange={e=>setW3(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 4</span>
                    <input type='text' value={w4} onChange={e=>setW4(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 5</span>
                    <input type='text' value={w5} onChange={e=>setW5(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 6</span>
                    <input type='text' value={w6} onChange={e=>setW6(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 7</span>
                    <input type='text' value={w7} onChange={e=>setW7(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 8</span>
                    <input type='text' value={w8} onChange={e=>setW8(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 9</span>
                    <input type='text' value={w9} onChange={e=>setW9(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 10</span>
                    <input type='text' value={w10} onChange={e=>setW10(e.target.value)} />
                    
                </div>
                <div className='text-field'>
                    <span className='label'>Winner 11</span>
                    <input type='text' value={w11} onChange={e=>setW11(e.target.value)} />
                    
                </div>
                
                
                <div className='text-field'>
                    <span className='label'>Winner 12</span>
                    <input type='text' value={w12} onChange={e=>setW12(e.target.value)} />
                    
                </div>
                
                
                <div className='text-field'>
                    <span className='label'>Winner 13</span>
                    <input type='text' value={w13} onChange={e=>setW13(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 14</span>
                    <input type='text' value={w14} onChange={e=>setW14(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 15</span>
                    <input type='text' value={w15} onChange={e=>setW15(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 16</span>
                    <input type='text' value={w16} onChange={e=>setW16(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 17</span>
                    <input type='text' value={w17} onChange={e=>setW17(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 18</span>
                    <input type='text' value={w18} onChange={e=>setW18(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 19</span>
                    <input type='text' value={w19} onChange={e=>setW19(e.target.value)} />
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Winner 20</span>
                    <input type='text' value={w20} onChange={e=>setW20(e.target.value)} />
                    
                </div>
                <p className='error' style={{width: '80%', gridColumn: '1/3'}}>{error}</p>
                <p className='loading' style={{gridColumn: '1/3'}}>{loading}</p>
                <p style={{gridColumn: '1/3', width: '80%', margin: 0}} className='m0'>Submitting winners overwrites old winners.</p>
                <button className='cta-btn-blue' onClick={onSubmit} style={{fontSize: '16px'}}>Submit Winners</button>
                <button className='cta-btn-blue'  onClick={onClearWinners}style={{fontSize: '16px'}}>Clear Winners</button>
                
                
            </div>
            
            
        </div>
    )
}

export default ManageWinners
