import React, { useEffect, useState, useRef } from 'react'
import downIcon from '../img-src/icons/down-arrow.png';
import rightIcon from '../img-src/icons/right.png';
import infoIcon from '../img-src/icons/info.png';
import homeIcon from '../img-src/icons/home.png';
import micIcon from '../img-src/icons/mic.png';
import giftIcon from '../img-src/icons/gift.png';
import handoutIcon from '../img-src/icons/handout.png';
import logoutIcon from '../img-src/icons/logout.png';
import progressioIcon from '../img-src/icons/progressio.png';
import closeIcon from '../img-src/icons/close.png';
import menuIcon from '../img-src/icons/menu-icon.png';
import chatIcon from '../img-src/icons/right-arrow.png';
import chatIcon1 from '../img-src/icons/chat-icon.png';
import winnersIcon from '../img-src/icons/trophy.png';
import bgStage from '../img-src/Auditorium-bg.jpg';
import bgStageFar from '../img-src/Auditorium1.jpg';
import sendBtn from '../img-src/icons/send-btn.png';
import ACDExpoIcon from '../img-src/loreal-expo-logo.png';
import edermsLogo from '../img-src/EDerm/EDerm Logo.png';
import logo from '../img-src/loreal-expo-logo.png';
import DDL from './Fields/DDL';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import ReactGA from 'react-ga';
import {getDummyId, getToken, getUser, removeUserSession, setUserSession} from '../Utils/Common';
import axios from 'axios';
import { v4 } from 'uuid';

import Speakers from './landing/Speakers';
import placeholderImg from '../img-src/EDerm/stars.png';

import { func } from 'prop-types';
import Handouts from './landing/Handouts';
import Agenda from './landing/Agenda';
import Giveaways from './landing/Giveaways';
import AuditoriumFeedbackForm from './AuditoriumFeedbackForm';
import Input from './Fields/Input';
import { baseAPI } from '../config';
function Archive(props) {
    const [streamLive, setstreamLive] = useState(true);
    const [mobileNavClosed, setMobileNavClosed] = useState(false);
    const [chatExpanded, setChatExpanded] = useState(true);
    const [contestExpanded, setContestExpanded] = useState(false);
    const [streamLink, setStreamLink] = useState('')
    const [winners, setWinners] = useState('');
    const [hideNav, setHideNav] = useState(true)
    const [showPopUpForm, setShowPopUpForm] = useState(false);
    const [mobileVersion, setMobileVersion] = useState(false);
    const [showMobileWinners, setshowMobileWinners] = useState(false);
    const [timeWatched, setTimeWatched] = useState(0);
    const refreshWinnersDelta = 120000;
    const streamLinkRefreshDelta = 60000;
    const hideWinners = false;
    const [hideOverlay, setHideOverlay] = useState(true);
    const [showInfo, setShowInfo] = useState(false)
    const [showSpeakers, setShowSpeakers] = useState(false)
    const [showGiveaways, setShowGiveaways] = useState(false)
    const [showHandouts, setShowHandouts] = useState(false)
    const [showAgenda, setShowAgenda] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState('');


    const selectedEventRef = useRef(selectedEvent)
    //archive
    const [retrievedArchive, setRetrievedArchive] = useState([''])
    const [eventList, setEventList] = useState([])
    const [videoType, setVideoType] = useState('')
    const engTimeDelta = 120000
    const [counter, setCounter] = useState(0)


    useEffect(() => {
        
        if(getDummyId()){
            

        }
        else{
            props.history.push('/login')
        }
    }, [])



    useEffect(() => {
        //1console.log('selectedEventRef',selectedEventRef);
        if(selectedEvent!==''){
            saveClickAnalytics('View Event - Archive')
            //1console.log(selectedEvent);
            var selected = retrievedArchive.find(x=>x.display_name===selectedEvent).link

            //1console.log(selected);
            //1console.log(selected.includes('youtube'));
            if(selected.includes('youtube')){
                
                setStreamLink('https://www.youtube.com/embed/'+ getYoutubeVideoId(selected) +'?modestbranding=1&showinfo=0&rel=0&autoplay=1&playsinline=1')
                setVideoType('youtube')
            }
                
            if(selected.includes('vimeo')){
                //1console.log(getVimeoVideoId(selected));
                
                setStreamLink('https://player.vimeo.com/video/'+ getVimeoVideoId(selected) +'?autoplay=1&title=0&byline=0&portrait=0')
                setVideoType('vimeo')
            }

        }
            
    }, [selectedEvent])
    async function saveClickAnalytics(btn, eventId){
        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn})
            
        }
            

        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId})
    }





    function handleExpandChat(){
        setChatExpanded(!chatExpanded);
    }
    function handleExpandContest(){
        setContestExpanded(!contestExpanded);
    }
    function handleExpandMenu(e){
        e.preventDefault();
        setMobileNavClosed(!mobileNavClosed);
    }


    function initGA(){
        
        ReactGA.initialize('UA-183483789-2');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
    useEffect(() => {
        initGA();
        //getWinners()
        getStreamLink()
        if(document.body.scrollWidth <= 500){
            setMobileVersion(true)
        }
        if(document.body.scrollHeight <= 500)
            setMobileVersion(false);

        //get winners every n seconds
        // var interval = setInterval(() => {
        //     getWinners();
        // }, refreshWinnersDelta);

        //check if link changed
        // var linkCheckInterval = setInterval(() => {
        //     checkStreamLinkChanged()
        // }, streamLinkRefreshDelta);


        //add engagementTime
        // //1console.log(retrievedArchive);
        
// 120000
        // var addEngagementTimeInterval = setInterval(() => {
        //     //1console.log(selectedEvent);
        //     // axios.post(baseAPI+'/api/analysis/archived/addEngagementTime', {
        //     //     "virtual_user_id": Math.floor((Math.random() * 10000000) + 1),
        //     //     "event_id": retrievedArchive.find(x=>x.display_name===selectedEvent)?.id,
        //     //     "time": 120
            
        //     // }).then(r=>//1console.log(r)).catch(e=>{
        //     //     //1console.log(e.response);
        //     // })
        // }, 1000);
              
        return () =>{
            //clearInterval(interval);
            // clearInterval(linkCheckInterval);
            // clearInterval(addEngagementTimeInterval)
            // postTimeWatched()
            
        }
    }, [])
    useEffect(() => {
        // //1console.log(counter);
        setTimeout(() => {
            setCounter(!counter)
            // //1console.log(selectedEvent);
            //1console.log(retrievedArchive.find(x=>x.display_name===selectedEvent)?.id);
            axios.post(baseAPI+'/api/analysis/archived/addEngagementTime', {
                "virtual_user_id": getDummyId(),
                "event_id": retrievedArchive.find(x=>x.display_name===selectedEvent)?.id,
                "time": engTimeDelta/1000
            
            }).then(r=>{
                //1console.log(r)
            }).catch(e=>{
                //1console.log(e.response);
            })
        }, engTimeDelta);
    }, [counter])
    
    useEffect(() => {
        renderChatMessages();
        document.title = "L'Oréal Expo - Archive"
        

        //get all archived events
        axios.get(baseAPI+'/api/archivedEvent/getAll').then(r=>{
            //1console.log(r.data);
            setRetrievedArchive(r.data)
            setEventList(r.data?.map(ev=>ev.display_name))
        }).catch(e=>{
            //1console.log(e);
        })

    }, [])

    useEffect(() => {
        setTimeout(() => {
            setTimeWatched(timeWatched+1)
            
        }, 1000);
    }, [timeWatched])

    
    function postTimeWatched(){
        // axios.post(baseAPI+'/api/videos/average', {time: timeWatched}).then(r=>{
        //     //1console.log(r);
        // }).catch(e=>{
        //     //1console.log(e);
        // })

    }
    
    function incrementViews(){
        axios.post(baseAPI+'/api/videos/view').then(r=>{}).catch(e=>{
            //1console.log('Error while adding view');
        })
    }
    function getStreamLink(){
        // axios.get(baseAPI+'/api/videos').then(r=>{
            
        //     var x = r.data.url 
            
        //     setStreamLink(x.substring(x.indexOf('watch?v=')+8));
        // }).catch(e=>{
        //     //1console.log('Error while getting stream link');
        // })
    }
   

    function hidePreStream(){
        document.body.style.overflow = 'hidden';
        document.querySelector('.aud-far').classList.add('fadeOut');
        incrementViews();
        setTimeout(() => {
            document.querySelector('.aud-far').style.display = 'none';
            document.body.style.overflow = 'unset';
        }, 1900);
    }
    function showPopUpFormFn(e){
        // e.preventDefault()
        postTimeWatched()
        // document.querySelector('.iframe-form').classList.add('fadeIn');
        // document.querySelector('.iframe-form').style.display = 'block';
        setShowPopUpForm(true)
    }

    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;
    const renderTime = (dimension, time) => {
        return (
          <div className="time-wrapper">
            <div className="time">{time}</div>
            <div>{dimension}</div>
          </div>
        );
      };
    const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6
    };
    const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time) => (time / daySeconds) | 0;

    
    const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = 1626116400; // use UNIX timestamp in seconds

    const remainingTime = endTime - stratTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;
    const [chatMsg, setChatMsg] = useState('');
    const [chatContent, setChatContent] = useState();
    
    function onSendChat(e){
        if(chatMsg==='')
            return
        setChatMsg('');
        _MOCKSENDNEWCHAT_(chatMsg)
        
        
    }
    function getInitials(fullName) {
        
        var firstInitial = fullName.substr(0,1).toUpperCase();
        var secondInitial = fullName.split(' ', 2)[1]?fullName.split(' ', 2)[1].substr(0,1).toUpperCase():'';

        return firstInitial+secondInitial;
    }
    const [mockChat, setMockChat] = useState([{access: 'Chat Moderator', name: 'Dolor cillum', message: 'Ad commodo aute fugiat in eiusmod minim nostrud sunt esse adipisicing laboris consectetur ut.'}, {access: 'Chat Admin', name: 'Ad commodo', message: 'Ad commodo aute fugiat in eiusmod minim nostrud sunt esse adipisicing laboris consectetur ut.'}, {access: '', name: 'Dolor cillum', message: 'Ad commodo aute fugiat in eiusmod minim nostrud sunt esse adipisicing laboris consectetur ut.'}])

    function renderChatMessages(){
        

        let chatMsgs = mockChat.map((m,i)=>
        <div className='msg-item' key={i}>
            <div className='icon'>{getInitials(m.name)}</div>
            <div className='msg-content'>
                <p className='modifier'>{m.access}</p>
                <div className='msg-text-wrapper'>
                    <p className='sender-name'>{m.name}</p>
                    <p className='msg-text'>{m.message}</p>
            
                </div>
            </div>
        
        </div>)

        setChatContent(chatMsgs);
        
    }

    function renderChat(msgs){
        let chatMsgs = msgs.map((m,i)=>
        <div className={'msg-item'+(m.self?' my-msg':'')} key={i}>
            <div className='icon'>{getInitials(m.name)}</div>
            <div className='msg-content'>
                <p className='modifier'>{m.access}</p>
                <div className='msg-text-wrapper'>
                    <p className='sender-name'>{m.name}</p>
                    <p className='msg-text'>{m.message}</p>
            
                </div>
            </div>
        
        </div>)

        setChatContent(chatMsgs);
        
    }
    function _MOCKSENDNEWCHAT_(cm){
        let temp = mockChat;
        temp.push({access: 'Chat Admin', name: 'My Name', message: cm, self: true})
        setMockChat(temp)
        
        renderChat(mockChat)
        

    }
    function hideAllSpotlightSections(){
        setShowInfo(false);
        setShowSpeakers(false);
        setShowGiveaways(false);
        setShowHandouts(false);
        setShowAgenda(false);
        setShowPopUpForm(false);
    }

    function getYoutubeVideoId(link){
        return link?.substring(link?.indexOf('watch?v=')+8)
    }
    function getVimeoVideoId(link){
        return link?.substring(link?.indexOf('.com/')+5)
    }
    return (
        <div className='auditorium'>
            
            
            <img src={bgStage} className='stage-bg'/>
            

            {/*
            
            
            
            
            ---------------------------------------STREAM CONTENT---------------------------------------
            
            
            
            */}
            <div className='stream-content' style={{width:'72%', left: '11.5%'}}>
                
                    {/* <button className="expand-btn" onClick={handleExpandChat} style={{position: 'absolute',right: !chatExpanded?'28%':'7%',top: 0,height: chatExpanded?'15%':'15%',background: chatExpanded?'transparent':'white', zIndex: '0', paddingLeft: chatExpanded?0:'1%', marginRight: chatExpanded?'0.5%':'3.5%'}}><img src={chatExpanded?chatIcon:chatIcon1} style={{transform: chatExpanded?'rotate(180deg)':'rotate(0)'}} height='100%' />
                    </button> */}
                <div className='player-container' >
                    <h1 className='stream-title'style={{left:0}}>{selectedEvent}</h1>
                    
                        {
                            selectedEvent===''?
                            <div className='row' style={{flexDirection:'column', justifyContent: 'center', height: '100%'}}>
                                <img src={ACDExpoIcon} width='50%' /> 
                                <p className='p-title' style={{textAlign: 'center'}}>Please select an event to view.</p>
                            </div>
                            
                            :
                                videoType==='youtube'?
                                <iframe src={streamLink}  frameBorder="0" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" allowFullScreen></iframe>
                                :
                                <iframe src={streamLink} style={{width:'100%', height: '100%'}} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>

                        }
                        
                        {//1366 x 768

                        }
                </div>
                    
            </div>
            


            <DDL name='Event' value={selectedEvent} ddlItems={eventList} onChange={e=>setSelectedEvent(e.target.value)} style={{position: 'absolute', bottom: '5vh', left: '21%'}}/>
            

            <div className='nav-overlay-wrapper' style={{display: hideOverlay?'none':'flex'}}>
                <div className='nav-spotlight'>
                    <button className='close-nav-overlay'  onClick={()=>{setHideOverlay(true); hideAllSpotlightSections()}}><img src={closeIcon}/></button>
                    <div className='spotlight-info' style={{display: showInfo?'flex':'none'}}>
                        <h2>Description</h2>
                        
                    </div>

                    {/* <Speakers speakers={speakers} style={{display: showSpeakers?'block':'none'}} showTitle={false} />
                    <Handouts handouts={handouts} style={{display: showHandouts?'block':'none'}} showTitle={false}/> */}
                    {/* 
                        <Giveaways giveaways={giveaways} style={{display: showGiveaways?'block':'none'}} showTitle={false}/>
                        <Agenda agendaItems={agendaItems} style={{display: showAgenda?'block':'none'}} showTitle={false}/>
                        <AuditoriumFeedbackForm style={{display: showPopUpForm?'flex':'none'}} /> 
                    */}

                </div>
                
            </div>
            <div className='auditorium-nav'>
                <nav>
                    
                    <div >
                        {/* <img src={infoIcon} onClick={()=>{setHideOverlay(false); setShowInfo(true)}}/> */}
                    </div>
                    <div>
                        <img src={homeIcon} onClick={()=>{saveClickAnalytics('Nav Home - Archive').then(()=>{props.history.push('/tour')})}}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{saveClickAnalytics('Nav Home - Archive').then(()=>{props.history.push('/tour')})}}>Home</p>
                    
                    </div>
                    {/* <div>
                        <img src={micIcon} onClick={()=>{setHideOverlay(false); setShowSpeakers(true)}}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{setHideOverlay(false); setShowSpeakers(true)}}>Speakers</p>
                    </div> */}
                    
                    {/* <div>
                        <img src={logoutIcon} onClick={showPopUpFormFn}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={showPopUpFormFn}>Exit Event</p>
                    </div> */}
                    
                    <div><p style={{fontSize: '0.8rem', marginLeft: '-50%'}} >Powered By</p><img src={progressioIcon} /></div>
                    
                    <img src={menuIcon} className='burger-icon' onClick={handleExpandMenu}/>
                </nav>
            </div>
            
            <div className='nav-expanded' style={{display: mobileNavClosed?'flex':'none'}}>
                <ul>
                    <a href='' onClick={handleExpandMenu}><li><img src={closeIcon} style={{filter: 'invert(1)', width: '30%'}}/></li></a>
                    {/* <a href='' onClick={(e)=>{setHideOverlay(false); setShowInfo(true); e.preventDefault();}}><li><img src={infoIcon}/><span>Info</span></li></a> */}
                    <a href='' onClick={(e)=>{e.preventDefault(); saveClickAnalytics('Nav Home - Archive').then(()=>{props.history.push('/tour')})}}><li><img src={homeIcon}/><span>Home</span></li></a>
                    {/* <a href='' onClick={(e)=>{setHideOverlay(false); setShowSpeakers(true); e.preventDefault()}}><li><img src={micIcon}/><span>Speakers</span></li></a> */}
                    {/* <a href='' onClick={(e)=>{setHideOverlay(false); setShowGiveaways(true); e.preventDefault()}}><li><img src={giftIcon}/><span>Giveaways</span></li></a> */}
                    {/* <a href='' onClick={(e)=>{setHideOverlay(false); setShowHandouts(true); e.preventDefault()}}><li><img src={handoutIcon}/><span>Handouts</span></li></a> */}
                    {/* <a href='' onClick={(e)=>{e.preventDefault(); handleExpandContest()}} style={{display: 'none'}}><li><img src={winnersIcon} style={{filter: 'invert(1)', width: '70%'}}/><span>Winners</span></li></a> */}
                    {/* <a href='' onClick={showPopUpFormFn} style={{display: 'none'}}><li><img src={logoutIcon}/><span>Exit Event</span></li></a> */}
                    <a href=''><li><span>Powered By</span><img src={progressioIcon}/></li></a>
                </ul>
            </div>
        </div>
    )
}

export default Archive
