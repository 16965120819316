import React, { useState, useEffect, useCallback  } from 'react'
import Input from '../Fields/Input'
import Upload from '../Fields/Upload'
import { useSelector, useDispatch } from 'react-redux'
import { updateGiveaways } from "../slices/adminSlice";
import { getToken } from '../../Utils/Common'
import axios from 'axios'
import { baseAPI } from '../../config';
function Giveaways(props) {
    const [GiveawaysLocal, setGiveawaysLocal] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
      //1console.log(GiveawaysLocal);
      var x = GiveawaysLocal.map(g=>{return{title: g.Name, description: g.Description,picture: g.Picture}})
        dispatch(updateGiveaways(x))
    }, [GiveawaysLocal])
    const onChange = (index, event) => {
        if(event.target.name!=='Picture'){
            event.preventDefault();
            event.persist();
        }
        
    
        setGiveawaysLocal((prev) => {
          return prev.map((item, i) => {
            if (i !== index) {
              return item;
            }
    
            return {
              ...item,
              [event.target.name]: event.target.value
            };
          });
        });
      };

    const handleAdd = (e) => {
    e.preventDefault();
    const inputState = {
      Name: "",
      Giveaway: "",
      Description:''
        

        
    };

   
    setGiveawaysLocal((prev) => [...prev, inputState]);
    
    };
    function handleRemove(e, i){
        
        e.preventDefault()
        
        setGiveawaysLocal((prev) => prev.filter((item) => item !== prev[i]));
    }

    useEffect(() => {
      
      if(props.initValues!==null){
        var x = props.initValues.map(s=>{return{Name: s.title, Picture: s.picture, Description: s.description}})
        setGiveawaysLocal(x)
      }
    }, [])

    const onDropFile = useCallback((acceptedFiles, i) => {
      setIsUploading(true)
      //1console.log(acceptedFiles[0]);
      var formData = new FormData()
      formData.append('file', acceptedFiles[0])

      axios.post(baseAPI+'/api/files/image/upload',  formData, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
          //1console.log(r.data.url);
          onChange(i, {target: {value: r.data.url, name: 'Picture'}})
          setIsUploading(false)
      }).catch(e=>{
          //1console.log(e);
          setIsUploading(false)
      })

  }, [])
    return (
        <div className='form-wrapper' style={{filter: props.active?'none':'grayscale(100)', opacity: props.active?'1':'0.4'}}>
           
            {/* {JSON.stringify(GiveawaysLocal)} */}
            {GiveawaysLocal.map((s,i)=><div  style={{width: '100%'}} key={'handounts-a-'+i}>
                                    <p className='speaker-num'>Giveaway {i+1}</p>
                                    <button className='warning-btn' onClick={(e)=>handleRemove(e, i)} style={{marginLeft: '7%', padding: '0.3rem 1rem', marginBottom: '5%'}}>Remove Giveaway</button>
                                    <div style={{display: 'flex', flexWrap: 'wrap', padding: '0 5%'}}>
                                        
                                        <Input type='text' name='Name' value={s.Name}  onChange={(e)=>onChange(i,e)} />
                                        <Upload name='Picture' onDrop={(acceptedFiles) => {onDropFile(acceptedFiles, i)}} fileName={s.Picture?.split('/')[4]?.slice(13)} compress={true} accept="image/png, image/gif, image/jpeg" isLoading={isUploading}/>
                                        <Input type='text' name='Description' value={s.Description} onChange={e=>onChange(i,e)} />
                                    </div>

                                </div>)}
            
            <button className='a-link' onClick={handleAdd} style={{margin:'2% 0'}}>+ Add Giveaway</button>
        </div>
    )
}

export default Giveaways
