import { configureStore } from '@reduxjs/toolkit'
import speakerSlice from './components/slices/speakerSlice'
import adminSlice from './components/slices/adminSlice'
import globalSlice from './components/slices/globalSlice'
export default configureStore({
  reducer: {
      speaker: speakerSlice,
      admin: adminSlice,
      global: globalSlice
  },
})