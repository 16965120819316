
import Hero from './Hero';
import Speakers from './Speakers';
import Agenda from './Agenda';
import Handouts from './Handouts';
import Giveaways from './Giveaways';
import Footer from './Footer';
import Slider from './Slider';
import logo from '../../img-src/loreal-expo-logo.png';
import placeholderImg from '../../img-src/EDerm/stars.png';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { getUser, getToken, removeUserSession } from '../../Utils/Common';
import store from '../../store';
import { baseAPI } from '../../config';
function AdminLanding(props) {
    // let speakers = [{name: 'Professor Thomas Luger', imgUrl: placeholderImg, desc: 'Ad aliquip duis irure fugiat fugiat enim adipisicing veniam et.'},{name: 'Dr Mai El Samahy', imgUrl: placeholderImg, desc: 'Ad aliquip duis irure fugiat fugiat enim adipisicing veniam et.'}]
    // let agendaItems = [{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'}, {speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'}];
    // let handouts = [{title: 'Handout Title', imgUrl: placeholderImg, desc: 'Irure laborum cillum aliquip elit laboris. Amet nisi occaecat laborum ut ipsum magna minim ex do. Dolore aute dolore pariatur tempor amet eiusmod ipsum proident. Quis proident ullamco reprehenderit nulla consequat amet mollit esse aute amet Lorem cillum. Mollit pariatur reprehenderit id voluptate sint.'},{title: 'Handout Title', imgUrl: placeholderImg, desc: 'Irure laborum cillum aliquip elit laboris. Amet nisi occaecat laborum ut ipsum magna minim ex do. Dolore aute dolore pariatur tempor amet eiusmod ipsum proident. Quis proident ullamco reprehenderit nulla consequat amet mollit esse aute amet Lorem cillum. Mollit pariatur reprehenderit id voluptate sint.'}]
    const [currentEventId, setCurrentEventId] = useState('')
    const [speakers, setSpeakers] = useState([''])
    const [agendaItems, setAgendaItems] = useState([''])
    const [handouts, setHandouts] = useState([''])
    const [hero, setHero] = useState({name: '', desc: '', startDate: '2030/1/1', logo: ''})
    const [giveaways, setGiveaways] = useState([''])
    const [noEventRunning, setNoEventRunning] = useState(false)
    const [retrievedAddress, setRetrievedAddress] = useState('')
    const [retrievedCity, setRetrievedCity] = useState('')
    const [retrievedArea, setRetrievedArea] = useState('')
    
    const [eventOpened, setEventOpened] = useState(false)
    const [loggedInUser, setLoggedInUser] = useState('')
    const [closed, setClosed] = useState(true)
    const [eventDate, setEventDate] = useState('')
    const [openDate, setOpenDate] = useState('')
    const [showSpeakers, setShowSpeakers] = useState(false)
    const [showAgenda, setshowAgenda] = useState(false)
    const [showGiveaways, setShowGiveaways] = useState(false)
    const [showHandouts, setShowHandouts] = useState(false)
    const [country, setCountry] = useState('')

    const [showEventName, setShowEventName] = useState(false)
    const [activateAnalysis, setActivateAnalysis] = useState(true)
    
    useEffect(() => {

        
     
            
       
        
            if(props.event){
                
                
            
                
                //1console.log(props.event.event);
                setshowAgenda(props.event.event.activate_agenda)
                setShowGiveaways(props.event.event.activate_giveaway)
                setShowHandouts(props.event.event.activate_handout)
                setShowSpeakers(props.event.event.activate_speaker)
                setShowEventName(props.event.event.title_display_choice==='Everywhere'||props.event.event.title_display_choice==='Landing Page Only')
                setHero({name: props.event.event.title, desc: props.event.event.description, startDate: (new Date().toLocaleString('fr-CA').split(',')[0]+' '+new Date().toLocaleString('en-GB').split(' ')[1].slice(0,-3))||(new Date(props.event.event.open_datetime).toLocaleString('fr-CA').split(',')[0]+' '+new Date(props.event.event.open_datetime).toLocaleString('en-GB').split(' ')[1].slice(0,-3)),  logo: props.event.event.logo})
                if(Array.isArray(props.event.event.speakers))
                    setSpeakers(props.event.event.speakers.map((s)=>{return{name: s.name, imgUrl: s.picture, desc: s.bio}}))
                if(Array.isArray(props.event.event.agendas))
                    setAgendaItems(props.event.event.agendas.map((s)=>{console.log('AGENDA START AND END TIMES',s.start_time,s.end_time,s.start_time&&s.end_time&&(s.start_time!==''&&s.end_time!=='')); return{speakerName: s.speaker_name, title: s.topic, 
                        time: 
                            s.start_time&&s.end_time&&(s.start_time!==' '&&s.end_time!==' ')?
                            new Date(s.start_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})+'-'+new Date(s.end_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                            :''
                    }}))
                
                    
                // //1console.log(r.data[0].handouts);
                if(Array.isArray(props.event.event.handouts))
                    setHandouts(props.event.event.handouts.map(h=>{return{title: h.title, imgUrl: h.picture, file:h.file,  desc:h.description}}))
                if(Array.isArray(props.event.event.giveaways))
                    setGiveaways(props.event.event.giveaways)
            }
            
            
            
            


             
        
    }, [])

    useEffect(() => {
        
        
            if(props.event){
                
                
                
                
                setshowAgenda(props.event.event.activate_agenda)
                setShowGiveaways(props.event.event.activate_giveaway)
                setShowHandouts(props.event.event.activate_handout)
                setShowSpeakers(props.event.event.activate_speaker)
                setShowEventName(props.event.event.title_display_choice==='Everywhere'||props.event.event.title_display_choice==='Landing Page Only')
                                                                                                        //yyyy-mm-dd hh:mm
                setHero({name: props.event.event.title, desc: props.event.event.description, startDate: (new Date().toLocaleString('fr-CA').split(',')[0]+' '+new Date().toLocaleString('en-GB').split(' ')[1].slice(0,-3))||(new Date(props.event.event.open_datetime).toLocaleString('fr-CA').split(',')[0]+' '+new Date(props.event.event.open_datetime).toLocaleString('en-GB').split(' ')[1].slice(0,-3)),  logo: props.event.event.logo})
                if(Array.isArray(props.event.event.speakers))
                    setSpeakers(props.event.event.speakers.map((s)=>{return{name: s.name, imgUrl: s.picture, desc: s.bio}}))
                if(Array.isArray(props.event.event.agendas))
                    setAgendaItems(props.event.event.agendas.map((s)=>{console.log('AGENDA START AND END TIMES',s.start_time,s.end_time,s.start_time&&s.end_time&&(s.start_time!==''&&s.end_time!=='')); return{speakerName: s.speaker_name, title: s.topic, 
                        time: 
                            s.start_time&&s.end_time&&(s.start_time!==' '&&s.end_time!==' ')?
                            new Date(s.start_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})+'-'+new Date(s.end_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                            :''
                    }}))
                    
                // //1console.log(r.data[0].handouts);
                if(Array.isArray(props.event.event.handouts))
                    setHandouts(props.event.event.handouts.map(h=>{return{title: h.title, imgUrl: h.picture, file:h.file,  desc:h.description}}))
                if(Array.isArray(props.event.event.giveaways))
                    setGiveaways(props.event.event.giveaways)
            }
    }, [props])
    
    const [splashText, setSplashText] = useState('')
    function getUpcomingEventText(){
        axios.get(baseAPI+'/api/event/checkUpcoming').then(r=>{
            setSplashText('Stay tuned for the upcoming event: ' + r.data.title + '. On ' + new Date(r.data.event_datetime).toLocaleDateString('en-GB')) 
        }).catch(e=>{
            setSplashText('No event running. Please come back later.') 
        })
    }

    useEffect(() => {
        
        getUpcomingEventText()
    }, [])

    
    return (
        <div className='a-landing-container' style={props.style}>
            {/* <div className='aud-no-event' style={{display: closed?'flex':'none', height:'100%'}}>
                <img src={logo} />
                <p>{splashText}</p>
                    
            </div> */}
            <header className='landing-c-header' style={{background: 'rgba(255 0 0 , 50%)'}}>
                <p>Admin Preview</p>
            </header>
            <Hero eventName={showEventName?hero.name:''} eventDescription={hero.desc} eventStartDate={hero.startDate} logoUrl={hero.logo}/>
        
            <Speakers speakers={speakers} showTitle={true} style={{display: showSpeakers?'block':'none'}}/>
            <Agenda agendaItems={agendaItems} showTitle={true} style={{display: showAgenda?'block':'none'}}/>
            <Handouts handouts={handouts} showTitle={true} style={{display: showHandouts?'block':'none'}}/>
            <Giveaways address={retrievedAddress} city={retrievedCity} area={retrievedArea} giveaways={giveaways} showTitle={true} style={{display: showGiveaways?'block':'none'}} country={country}/>

            <div className='row' style={{padding: '3% 0', background: '#F7F2EC'}}>
                <button className='landing-c-btn' style={{width: '300px', height:'80px', fontSize: '2rem'}} >Join Now</button>
            </div>
            <Footer />
            {/* <Slider /> */}

        </div>
    )
}

export default AdminLanding
