import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useState } from "react";

const AudioDialog = (props) => {
  const [showDialog, setShowDialog] = useState(props.show);
  const handleAcceptAudio = () => {
    props.setAcceptAudio(true);
    setShowDialog(false);
  };
  const handleDeclineAudio = () => {
    props.setAcceptAudio(false);
    setShowDialog(false);
  };
  return (
    <Dialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false);
      }}
      PaperProps={{ sx: { padding: "20px 50px" } }}
      maxWidth="md"
    >
      <DialogTitle>Enable Audio?</DialogTitle>
      <Divider variant="middle" />
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={handleAcceptAudio} size="large" variant="contained">
          Yes
        </Button>
        <Button onClick={handleDeclineAudio} size="large" variant="contained">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudioDialog;
