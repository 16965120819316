import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";

function ConsentForm({ onAgree, show, onClose }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(800));
  useEffect(() => {
    if (show) {
      const handleTouchMove = (e) => {
        e.preventDefault();
      };

      // Add event listener to the document
      document.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });

      // Clean up by removing the event listener when the component unmounts
      return () => {
        document.removeEventListener("touchmove", handleTouchMove);
      };
    }
  }, [show]);
  return (
    <Dialog
      onClose={onClose}
      open={show}
      maxWidth="sm"
      fullScreen={fullScreen}
      sx={{
        height: fullScreen ? "65vh" : "100vh",
        marginTop: fullScreen ? "auto" : "",
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>
          I acknowledge that all my registration info will be collected and then
          deleted upon the ending of the streamed event
        </Typography>
        <DialogActions>
          <button className="cta-btn-blue next1" onClick={onAgree}>
            Agree
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ConsentForm;
