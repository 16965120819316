import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone';
import uploadIcon from '../../img-src/icons/upload.png'
import loadingIcon from '../../img-src/loader.gif'
function Upload(a) {
    const [isDropping, setIsDropping] = useState(false)
    return (
        <Dropzone onDrop={(acceptedFiles)=>{a.onDrop(acceptedFiles); setIsDropping(false)}} onDragEnter={()=>setIsDropping(true)} onDragLeave={()=>setIsDropping(false)} maxFiles={1}  accept={a.accept}>
            {({getRootProps, getInputProps}) => (
                <section className='file-upload-wrapper' style={{...a.style,marginBottom: '2%', flexGrow: a.compress?'1':0}}>
                    <span className='label'>{a.name}</span>
                    <div {...getRootProps()} className={a.compress?'file-drop compressed':'file-drop'} style={{background: isDropping?'lightblue':'white', overflow:'hidden'}}>
                        <span className='info'>{a.note||'*Recommended size is 500x500 px (1:1 aspect ratio)'}</span>
                        <input {...getInputProps()} />
                        
                        <img src={a.isLoading?loadingIcon:uploadIcon} className='upload-icon' style={{marginBottom: '3%', display:a.fileName===''||!a.fileName||a.isLoading?'block':'none'}}/>
                        <p style={{margin:'3%', display:a.fileName===''||!a.fileName||a.isLoading?'none':'block'}}>{a.fileName}</p>
                        <p style={{marginBottom: 0, display: a.compress?'none':'block'}}>Drag and drop a file here</p>
                        
                        <p style={{marginTop:0, display: a.compress?'none':'block'}}>Or</p>
                        <button className='primary-btn' style={{marginBottom:a.compress?'4%':0}}>Choose File</button> 
                        
                        
                        
                    </div>
                </section>
            )}
        </Dropzone>
    )
}

export default Upload
