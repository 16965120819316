import React, { useState, useEffect } from 'react'
import Input from '../Fields/Input'
import Upload from '../Fields/Upload'
import DDL from '../Fields/DDL'
import { useSelector, useDispatch } from 'react-redux'
import { updateQuestions } from "../slices/adminSlice";
import closeIcon from '../../img-src/icons/close.png'
import { v4 } from 'uuid'
function FeedbackForm(props) {
    const [QuestionsLocal, setQuestionsLocal] = useState([{Question: '', 'Question Type': '', Required: false, 'Min Characters': '', 'Max Characters': '', Choices:[''], 'Select Multiple':'', 'Write In Choice':'', useWriteInChoice: false, 'Min Rating':'', 'Max Rating':''}])
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const [showPreview, setShowPreview] = useState(false)
    const [previewAnswer, setPreviewAnswer] = useState('');
    const [previewError, setPreviewError] = useState('')
    const [previewInfo, setPreviewInfo] = useState('')


    //rating question options
    const [minRating, setMinRating] = useState(0)
    const [maxRating, setMaxRating] = useState(5)
    const [ratingPreviewSelectedValue, setRatingPreviewSelectedValue] = useState(0)

    useEffect(() => {

      var x = QuestionsLocal?.map(q=>{

        //handle essay questions
        if(q['Question Type']==='Essay'){
          return{
            type: 'open',
            question: q.Question,
            required: q.Required
          }
        }
        else{
            //handle rating questions
            if(q['Question Type']==='Rating'){

              var ratingChoices = []
              for(let i=q['Min Rating']; i<=q['Max Rating']; i++){
                ratingChoices.push(i)
              }
              //1console.log('rating choices', ratingChoices);
              return{
                type: 'rating',
                question: q.Question,
                required: q.Required,


                choices:    
                        ratingChoices.map((c,i)=>{
                          return{
                            content: c.toString(),
                            comment: false
                          }
                        })  
              }
            }
            else{
              //check if includes a write in choice to push it at the end of choices array
              if(q.useWriteInChoice){
                var choicesInclusive = q.Choices.map((c,i)=>{
                                                              return{
                                                                content: c,
                                                                comment: false
                                                              }
                                        })
                choicesInclusive.push({content: q['Write In Choice'], comment: true})


                return{
                  type: 'mcq',
                  question: q.Question,
                  required: q.Required,
                  choices: choicesInclusive   
                }
              }
              else{
                return{
                  type: 'mcq',
                  question: q.Question,
                  required: q.Required,
                  choices:    
                          q.Choices.map((c,i)=>{
                            return{
                              content: c,
                              comment: false
                            }
                          })  
                }
              }
            }
            
          
        }
          
        
      })
      
      dispatch(updateQuestions(x))


      //1console.log('qs local', x);
    }, [QuestionsLocal])
    const onChange = (index, event) => {
        if(event.target.type!=='checkbox'){
            event.preventDefault();
            event.persist();
        }
        
    
        setQuestionsLocal((prev) => {
          return prev.map((item, i) => {
            if (i !== index) {
              return item;
            }
    
            return {
              ...item,
              [event.target.name]: event.target.type==='checkbox'? event.target.checked:event.target.value
            };
          });
        });
      };

    const handleAdd = (e) => {
    e.preventDefault();
    const inputState = {
      Question: '', 'Question Type': '', Required: false, 'Min Characters': '', 'Max Characters': '', Choices:['',''], 'Select Multiple': '', 'Write In Choice':'', 'Min Rating': '', 'Max Rating':''
        //////////////////////////////////////////////////////////////////////////////////////////update

        
    };

   
    setQuestionsLocal((prev) => [...prev, inputState]);
    
    };
    function handleRemove(e, i){
        
        e.preventDefault()
        
        setQuestionsLocal((prev) => prev.filter((item) => item !== prev[i]));
    }
    function handleEssayPreview(s){
      setPreviewError('')
      setPreviewInfo('')
      if((previewAnswer.length<s['Min Characters']||previewAnswer.length>s['Max Characters'])||previewAnswer===''){
        previewAnswer===''?setPreviewError('Please enter an answer.'):setPreviewError(s['Min Characters']===''||s['Max Characters']===''?'Min and/or max characters have not been specified':'Answer must be between '+s['Min Characters']+' and '+s['Max Characters']+ ' characters.')
      }
      else
        setPreviewInfo('Submitted successfully.')
      
    }
    function onChoiceChange(index, cIndex,event){
      setQuestionsLocal((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          var x = item.Choices.slice();
          x[cIndex]=event.target.value
          return {
            ...item,
            Choices: x
          };
        });
      });
    }
    function onChoiceRemove(index, cIndex,event){
      setQuestionsLocal((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          var x = item.Choices.slice();
          x.splice(cIndex,1)
          return {
            ...item,
            Choices: x
          };
        });
      });
    }
    function onAddChoice(index,event){
      setQuestionsLocal((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          var x = item.Choices.slice();
          x.push('');
          return {
            ...item,
            Choices: x
          };
        });
      });
    }
    function shuffle(array) {
      var currentIndex = array.length,  randomIndex;
    
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
    
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
    
      return array;
    }
    function onShuffleChoices(index,event){
      
      setQuestionsLocal((prev) => {
        return prev.map((item, i) => {
          if (i !== index) {
            return item;
          }
          var x;
          // if(event.target.checked){
          //   x = item.Choices.slice();
          //   shuffle(x)
          // }
          // else
          //   x = item.Choices;

            x = item.Choices.slice();
            shuffle(x)
          return {
            ...item,
            Choices: x
          };
        });
      });
    }
    useEffect(() => {
      
      //initialize with saved data if available
      //1console.log(props.initValues);
      if(props.initValues!==null && props.initValues){
        // var qTypes = {'mcq':'Multiple Choice', 'open'}
        
        var x = props.initValues?.map(s=>{return{
          Question: s.question, 
          Required: s.required, 
          'Question Type': s.type==='mcq'?'Multiple Choice':s.type==='rating'?'Rating':'Essay', 
          Choices: s.choices.filter(c=>!c.comment).map(c=>c.content), 
          useWriteInChoice: s.choices.find(c=>c.comment)?true:false, 
          'Write In Choice': s.choices.find(c=>c.comment)?s.choices[s.choices.length-1]?.content:'',
          'Min Rating': s.type==='rating'?s.choices[0].content.toString():'',
          'Max Rating': s.type==='rating'?s.choices[s.choices.length-1].content.toString():''
        }})
        setQuestionsLocal(x)
        //1console.log(x);
    }
    else{
      setQuestionsLocal([])
    }
    }, [])
    return (
        <div className='form-wrapper' style={{filter: props.active?'none':'grayscale(100)', opacity: props.active?'1':'0.4', }}>
           <p className='error'><b>{props.dim?'*Feedback forms for running events are not editable. No changes will be saved.':''}</b></p>
            {/* {JSON.stringify(QuestionsLocal)} */}
            {!props.dim&&QuestionsLocal?.map((s,i)=><div  style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}} key={'questions-a-'+i}>
                                    <p className='speaker-num' style={{textAlign: 'center', marginLeft:0}}>Question {i+1}</p>
                                    <button className='warning-btn' onClick={(e)=>handleRemove(e, i)} style={{padding: '0.3rem 1rem', marginBottom: '5%'}}>Remove Question</button>
                                    
                                    <Input type='text area' name='Question' value={s.Question}  onChange={(e)=>onChange(i,e)}  compress={true} style={{marginBottom: '1%'}}/>
                                    <div className='f-w-h-include-section' style={{width: '60%', textAlign: 'right', marginRight: '10%'}}>
                                        <input type='checkbox' name='Required' checked={s.Required} onChange={e=>{onChange(i,e)}} />
                                        <span>Required</span>
                                    </div>
                                    <hr width='60%'/>
                                    <DDL name='Question Type' value={s['Question Type']} ddlItems={['Essay', 'Multiple Choice', 'Rating']} onChange={e=>onChange(i,e)} />

                                    {/* Essay question options */}
                                    {/* <div className={s['Question Type']==='Essay'?'q-essay-options ':'q-essay-options'} style={{display: s['Question Type']==='Essay'?'flex':'none'}}>
                                      <Input type='number' name='Min Characters' value={s['Min Characters']} max={s['Max Characters']}  onChange={(e)=>onChange(i,e)} compress2={true} style={{marginBottom:'2%'}} />
                                      <Input type='number' name='Max Characters' min={s['Min Characters']} value={s['Max Characters']}  onChange={(e)=>onChange(i,e)} compress2={true} />
                                      <p className='error'>{error}</p>
                                    </div> */}

                                    {/* Multiple Choice choices */}
                                    <div className={s['Question Type']==='Multiple Choice'?'q-essay-options ':'q-essay-options'} style={{display: s['Question Type']==='Multiple Choice'?'flex':'none'}}>
                                      {s.Choices.map((c,choiceNumber)=>{
                                        return <div className='choice-container' key={'Choice '+choiceNumber.toString()+' '+s.toString()}>
                                              <Input type='text' name='Choice' value={c}  onChange={(e)=>onChoiceChange(i,choiceNumber,e)} compress2={true} style={{marginBottom:'0%', alignItems: 'center'}} />
                                              <button className='symbol-btn' onClick={(e)=>{onChoiceRemove(i,choiceNumber,e)}}>-</button>
                                              </div>
                                      })}
                                      <Input type='text' name='Write In Choice' value={s['Write In Choice']} onChange={(e)=>onChange(i,e)}  compress2={true} style={{marginBottom:'2%', alignItems: 'center', justifyContent: 'center', visibility: s.useWriteInChoice?'visible':'hidden', marginLeft: '10%', width:'51%'}} />
                                      <button className='symbol-btn-blue' onClick={(e)=>{onAddChoice(i,e)}}></button>
                                      <div className='f-w-h-include-section' style={{display: 'flex', alignItems: 'center', flexBasis: '30%', justifyContent: 'center', width:'100%', margin: '2% 0'}}>
                                        {/* <input type='checkbox' name='Select Multiple' checked={s['Select Multiple']} onChange={e=>{onChange(i,e)}} />
                                        <span style={{marginRight: '5%'}}>Select Multiple</span> */}
                                        
                                        
                                        <input type='checkbox' name='useWriteInChoice' checked={s.useWriteInChoice} onChange={e=>{onChange(i,e)}} />
                                        <span style={{marginRight: '5%'}}>Write In Choice</span> 

                                        <button className='border-btn-blue'  onClick={e=>{onShuffleChoices(i,e)}}>Randomize Order</button>
                                    </div>


                                      
                                      <p className='error'>{error}</p>
                                    </div>
                                    
                                    {/* Rating options */}
                                    <div className={'q-essay-options '} style={{display: s['Question Type']==='Rating'?'flex':'none'}}>
                                      
                                      <DDL name='Min Rating' ddlItems={[0,1]} value={s['Min Rating']} onChange={(e)=>onChange(i,e)} compress={true}/>
                                      <DDL name='Max Rating' ddlItems={[5,10]} value={s['Max Rating']} onChange={(e)=>onChange(i,e)} compress={true}/>
                                      
                                      {/* <Input type='text' name='Write In Choice' value={s['Write In Choice']} onChange={(e)=>onChange(i,e)}  compress2={true} style={{marginBottom:'2%', alignItems: 'center', justifyContent: 'flex-start', visibility: s.useWriteInChoice?'visible':'hidden', marginLeft: '3%', width:'45%'}} /> */}
                                      {/* <button className='symbol-btn-blue' onClick={(e)=>{onAddChoice(i,e)}}></button> */}
                                      {/* <div className='f-w-h-include-section' style={{display: 'flex', alignItems: 'center', flexBasis: '30%', justifyContent: 'center', width:'100%', margin: '2% 0'}}> */}
                                        {/* <input type='checkbox' name='Select Multiple' checked={s['Select Multiple']} onChange={e=>{onChange(i,e)}} />
                                        <span style={{marginRight: '5%'}}>Select Multiple</span> */}
                                        
                                        
                                        {/* <input type='checkbox' name='useWriteInChoice' checked={s.useWriteInChoice} onChange={e=>{onChange(i,e)}} />
                                        <span style={{marginRight: '5%'}}>Write In Choice</span>  */}

                                        {/* <button className='border-btn-blue'  onClick={e=>{onShuffleChoices(i,e)}}>Randomize Order</button> */}
                                    </div>

                                    {/* form preview */}
                                    <div className='form-preview' style={{display: showPreview?'flex':'none'}}>
                                      <p className='p-title' style={{display: s['Question Type']===''?'block':'none', color: 'white'}}>Select a question type.</p>
                                      {i===0&&<img src={closeIcon} className='close-preview' onClick={()=>setShowPreview(false)}/>}


                                      <div className='inner-card' style={{display: s['Question Type']==='Essay'?'flex':'none'}}>
                                        <p className='question-preview'>Q{i+1}- {s.Question}</p>
                                        <textarea className='' value={previewAnswer} onChange={e=>setPreviewAnswer(e.target.value)} placeholder={'Enter your answer'} style={{height: '100px', marginBottom: '5%'}}/>
                                        <button className='primary-btn' onClick={()=>handleEssayPreview(s)}>Submit</button>
                                        <p className='error'>{previewError}</p>
                                        <p className='loading'>{previewInfo}</p>
                                      </div>


                                      <div className='inner-card' style={{display: s['Question Type']==='Rating'?'flex':'none'}}>
                                        <p className='question-preview'>Q{i+1}- {s.Question}</p>
                                        {/* <textarea className='' value={previewAnswer} onChange={e=>setPreviewAnswer(e.target.value)} placeholder={'Enter your answer'} style={{height: '100px', marginBottom: '5%'}}/> */}
                                        <div style={{width:'80%'}}>
                                          <div className='row' style={{width: '100%'}}>

                                              {/* <input type='range' min={q.choices[0].content} max={q.choices[q.choices.length-1].content} name={'Answer Choice Q'+(i+1)} value={c.content}  className='rating-option' onChange={(e)=>onTextChange(e)}/> */}
                                              {
                                                  Array.from({length:parseInt(s['Min Rating'])===0?parseInt(s['Max Rating'])+1:parseInt(s['Max Rating'])},(v,k)=>(k)+ parseInt(s['Min Rating']))?.map((c,ci)=>

                                                  <li style={{width: '100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', background:'white', border: '4px solid rgba(21, 84, 115, 0.6)', marginRight:'2%', position:'relative', borderRadius:'50%', width:'2rem', height:'2.2rem', width:'2.2rem'}} key={ci} className='rating-ans-container'>
                                                      <input type={'radio'} name={'Answer Choice Q'+(i+1)} value={c}  className='rating-option' onChange={e=>{
                                                        e.target.parentNode?.parentNode?.childNodes?.forEach(c=>{
                                                          c.style.background = 'white'
                                                        })
                                                        if(e.target.checked){
                                                          e.target.parentNode.style.background='rgba(21, 84, 115, 0.6)'
                                                        }
                                                        
                                                        
                                                        }}  style={{position:'absolute', opacity:0, pointerEvents:'', width:'100%', height:'100%'}} />
                                                      <label style={{fontSize: '1.5em', textAlign:'center'}} >{c}</label>
                                                      
                                                  </li>
                                              )
                                              }

                                              
                                          </div>  
                                          <div className='row' style={{width:'100%', justifyContent:'space-between'}}>
                                                  <p>Very Bad</p>

                                                  <p>Excellent</p>
                                          </div>  
                                      </div>
                                        <button className='primary-btn' >Submit</button>
                                        <p className='error'>{previewError}</p>
                                        <p className='loading'>{previewInfo}</p>
                                      </div>

                                      <div className='inner-card' style={{display: s['Question Type']==='Multiple Choice'?'flex':'none'}}>
                                        <p className='question-preview'>Q{i+1}- {s.Question}</p>
                                        <ul>
                                          {s.Choices.map((c, choiceNumber)=><li key={'Choice Preview '+choiceNumber.toString()+' '+s.toString()}><input type={s['Select Multiple']?'checkbox':'radio'} name={'Choices Q'+(i+1)} value={c}/><label style={{fontSize: '1.5em'}} htmlFor={c}>{c}</label></li>)}
                                          <li style={{display: s.useWriteInChoice?'block':'none', marginBottom: '5%'}}>
                                            <input type={s['Select Multiple']?'checkbox':'radio'} name={'Choices Q'+(i+1)} />
                                            <label style={{fontSize: '1.5em'}} htmlFor={s['Write In Choice']}>{s['Write In Choice']}</label>
                                            <input style={{display: 'block', marginLeft: '20%', padding: '10px', border: '1px solid #155473', fontFamily:'LL2'}} placeholder={s['Write In Choice']}/>
                                          </li>
                                          
                                        </ul>
                                        <button className='primary-btn'>Submit</button>
                                        <p className='error'>{previewError}</p>
                                        <p className='loading'>{previewInfo}</p>
                                      </div>
                                    </div>
                                    <button className='primary-btn' onClick={()=>setShowPreview(true)} style={{marginBottom: '5%'}}>Preview</button>
                                </div>)}
            
            <button className='a-link' onClick={handleAdd} style={{display: props.dim?'none':'block'}}>+ Add Question</button>
        </div>
    )
}

export default FeedbackForm
