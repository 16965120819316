import React, { useState, useEffect, useCallback } from 'react'
import Dropzone, {useDropzone} from 'react-dropzone'
import uploadIcon from '../../img-src/icons/upload.png'
import DDL from '../Fields/DDL'
import Input from '../Fields/Input'
import DatePicker from 'react-datepicker'
import DateInput from '../Fields/DateInput'
import Upload from '../Fields/Upload'
import "react-datepicker/dist/react-datepicker.css";
import Speakers from './Speakers'
import Handouts from './Handouts'
import Giveaways from './Giveaways'
import Agenda from './Agenda'
import Chat from './Chat'
import FeedbackForm from './FeedbackForm'

import AdminCreateEditEvents from './AdminCreateEditEvents'
import store from '../../store'
import { useSelector } from 'react-redux'

import { useDispatch } from 'react-redux';
import {updateBasicInfo, updateCreateOrEdit} from '../slices/adminSlice'
import axios from 'axios'
import { getToken,removeUserSession, removeAdminId } from '../../Utils/Common'
import downloadIcon from '../../img-src/icons/download.png'
import AdminLanding from '../landing/AdminLanding'
import closeImg from '../../img-src/icons/close.png'
import { baseAPI } from '../../config'
function ManageEvent(props) {
    const [loading, setLoading] = useState('')

    const [eventType, setEventType] = useState('Public Event')
    const [eventName, setEventName] = useState('')
    const [eventNameDisplayOption, setEventNameDisplayOption] = useState('')
    const [requiredWarning, setRequiredWarning] = useState('')
    const [fileUploadName, setFileUploadName] = useState('')
    
    const [eventDescription, setEventDescription] = useState('')
    const [eventDate, setEventDate] = useState('')
    
    const [openDate, setOpenDate] = useState('')
    const [eventTime, setEventTime] = useState('')
    const [openTime, setOpenTime] = useState('')
    const dispatch = useDispatch();
    
    //include sections?
    const [includeSpeakers, setIncludeSpeakers] = useState(true)
    const [includeHandouts, setIncludeHandouts] = useState(true)
    const [includeGiveaways, setIncludeGiveaways] = useState(true)
    const [includeAgenda, setIncludeAgenda] = useState(true)
    const [includeChat, setIncludeChat] = useState(true)
    const [includeFeedbackForm, setIncludeFeedbackForm] = useState(true)

    //invitee section
    const [inviteeCSV, setInviteeCSV] = useState()
    const [inviteeListText, setinviteeListText] = useState('')
    const [csvPreview, setCsvPreview] = useState('')
    //event link
    const [eventLink, setEventLink] = useState('');

    //chat activation
    const [chatActivationTime, setChatActivationTime] = useState('')

    //create/edit event submit btn status
    const [loadingSubmitEvent, setLoadingSubmitEvent] = useState('')
    const [errorSubmitEvent, setErrorSubmitEvent] = useState('')
    
    const managemodestate = useSelector(state => state.admin.createOrEdit)
    const [manageMode, setManageMode] = useState(managemodestate)


    //flag to render speakers, handouts,... components if edit and saved date is retrieved
    const [retrievedSavedData, setRetrievedSavedData] = useState(false)


    //set init values for other sections
    const [speakersInitValues, setSpeakersInitValues] = useState(null)
    const [handoutsInitValues, setHandoutsInitValues] = useState(null)
    const [giveawaysInitValues, setGiveawaysInitValues] = useState(null)
    const [agendaInitValues, setAgendaInitValues] = useState(null)
    const [chatModeratorsInitValues, setChatModeratorsInitValues] = useState(null)
    const [feedbackformInitValues, setFeedbackformInitValues] = useState(null)


    const [eventIdEditted, setEventIdEditted] = useState(null)

    const [landingPreviewPayload, setLandingPreviewPayload] = useState(null)
    const [showLandingPreview, setShowLandingPreview] = useState(false)
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    
    useEffect(() => {
        
        //1console.log(eventName);
    }, [eventName])

    
    useEffect(() => {
        //1console.log('managemodestate');
        //1console.log(managemodestate);
        setManageMode(managemodestate)

        setRetrievedSavedData(false)

        if(managemodestate.split(' ')[0]==='edit'){
            axios.get(baseAPI+'/api/event/get/'+managemodestate.split(' ')[1], {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
                //1console.log('GET EVENT');
                //1console.log(r.data[0]);
                var a = r.data[0]
                setEventName(a.title)
                setEventDescription(a.description)
                setEventType(a.private?'Private Event':'Public Event')
                setEventNameDisplayOption(a.title_display_choice)
                //1console.log('edit open time: ',a.open_datetime);
                var evD = new Date(a.event_datetime)
                var opD = new Date(a.open_datetime)
                //1console.log('edit open time: ',opD.toLocaleString('en-GB'));
                //yyyy-mm-dd
                setEventDate(evD.toLocaleString('fr-CA').split(',')[0])
                
                setOpenDate(opD.toLocaleString('fr-CA').split(',')[0])
                setEventTime(evD.toLocaleString('en-GB').split(' ')[1])
                setOpenTime(opD.toLocaleString('en-GB').split(' ')[1])


                setEventIdEditted(a.id)
                
                setSpeakersInitValues(a.speakers)
                setHandoutsInitValues(a.handouts)
                setAgendaInitValues(a.agendas)
                setGiveawaysInitValues(a.giveaways)
                setChatModeratorsInitValues(a.moderators)
                
                setinviteeListText(a.invitees.map(inv=>{return inv.phone+'\n'}).join().replace(new RegExp(",", "g"),''))


                setEventLink(a.link)

                setChatActivationTime(a.chat_activation_time?.split('T')[1]?.split('.')[0]||'')

                setFeedbackformInitValues(a.form?.feedback_questions)


                //activations
                setIncludeChat(a.activate_chat)
                setIncludeFeedbackForm(a.activate_feedback_form)
                setIncludeAgenda(a.activate_agenda)
                setIncludeGiveaways(a.activate_giveaway)
                setIncludeHandouts(a.activate_handout)
                setIncludeSpeakers(a.activate_speaker)
                
                setRetrievedSavedData(true)
            })
            
        }
        else
            if(managemodestate==='create'){
                setRetrievedSavedData(true)
            }

    }, [managemodestate])
    const [showToolTip, setShowToolTip] = useState({'speakers':false, 'handouts': false, 'giveaways':false, 'chat': false, 'feedback':false, 'chatActivationTime':false})

    //update store with basic info
    useEffect(() => {
        
        dispatch(updateBasicInfo({title: eventName, private: eventType==='Private Event', logo: fileUploadName, description: eventDescription, event_datetime: eventDate+' '+eventTime+':00', open_datetime: openDate+' '+openTime+':00'}))
        
    }, [eventName, eventType, fileUploadName,eventDescription,eventDate,openDate,eventTime,openTime])
    
    function csvToJSON(bufferString){
        var arr = bufferString.split('\n');     

        var jsonObj = [];
        var headers = arr[0].split(',');
        headers = headers.map(h=>{return h.toLowerCase()})
        for(var i = 1; i < arr.length; i++) {
            var data = arr[i].split(',');
            var obj = {};
            for(var j = 0; j < data.length; j++) {
                obj[headers[j].trim()] = data[j].trim();
            }
            jsonObj.push(obj);
        }
        jsonObj.pop()
        return jsonObj
    }
    function onLandingPreview(){
        

        var inviteesForPayload;
        if(inviteeListText===''){
            inviteesForPayload = []
        }
        else{
            inviteesForPayload = inviteeListText.split('\n')
            inviteesForPayload = inviteesForPayload.map(inv=>{return{phone: inv}})
            
        }
        inviteesForPayload = inviteesForPayload.filter(inv=>inv.phone!==''&&inv.phone!==' ')
        //1console.log(inviteesForPayload);
        

        let evTimePayload = Math.abs(eventTime.split(':')[0]) 
        evTimePayload = evTimePayload + ':' + eventTime.split(':')[1]
        
        let opTimePayload = Math.abs(openTime.split(':')[0]) 
        opTimePayload = opTimePayload + ':' + openTime.split(':')[1]

        let chatTimePayload = Math.abs(chatActivationTime?.split(':')[0]) 
        chatTimePayload = chatTimePayload + ':' + chatActivationTime?.split(':')[1]

        let payload = {}
        if(eventType==='Private Event'){

            //dont send inviteesFile if array is empty because it overrides invitees
            if(csvToJSON(csvPreview).length===0){
                payload = {
                    event:{
                        title: eventName,
                        description: eventDescription,
                        logo: eventLogo,
                        link: eventLink,
                        event_datetime: eventDate + ' ' + eventTime,
                        open_datetime: openDate + ' ' + openTime,
                        private: eventType==='Private Event',
                        activate_chat: includeChat,
                        activate_giveaway: includeGiveaways,
                        activate_handout: includeHandouts,
                        activate_speaker: includeSpeakers,
                        activate_feedback_form: includeFeedbackForm,
                        activate_agenda: includeAgenda,
                        title_display_choice: eventNameDisplayOption,
                        chat_activation_time: chatActivationTime===''||chatActivationTime?openDate + ' ' + openTime:openDate + ' '+ chatActivationTime,
                        status: 'pending',
                        handouts: store.getState().admin.handouts,
                        speakers: store.getState().speaker.speakers,
                        giveaways: store.getState().admin.giveaways,
                        agendas: store.getState().admin.agenda,
                        questions: store.getState().admin.feedbackQuestions,
                        invitees: inviteesForPayload,
                        // "inviteesFile": csvToJSON(csvPreview),
                        moderators: store.getState().admin.chat,
        
        
                        id: eventIdEditted
        
        
                    }
                }
            }
            else{
                payload = {
                    event:{
                        title: eventName,
                        description: eventDescription,
                        logo: eventLogo,
                        link: eventLink,
                        event_datetime: eventDate + ' ' + eventTime,
                        open_datetime: openDate + ' ' + openTime,
                        private: eventType==='Private Event',
                        activate_chat: includeChat,
                        activate_giveaway: includeGiveaways,
                        activate_handout: includeHandouts,
                        activate_speaker: includeSpeakers,
                        activate_feedback_form: includeFeedbackForm,
                        activate_agenda: includeAgenda,
                        title_display_choice: eventNameDisplayOption,
                        chat_activation_time: chatActivationTime===''||chatActivationTime?openDate + ' ' + openTime:openDate + ' '+ chatActivationTime,
                        status: 'pending',
                        handouts: store.getState().admin.handouts,
                        speakers: store.getState().speaker.speakers,
                        giveaways: store.getState().admin.giveaways,
                        agendas: store.getState().admin.agenda,
                        questions: store.getState().admin.feedbackQuestions,
                        invitees: inviteesForPayload,
                        "inviteesFile": csvToJSON(csvPreview),
                        moderators: store.getState().admin.chat,
        
        
                        id: eventIdEditted
        
        
                    }
                }
            }
            
        }
        else{
            payload = {
                event:{
                    title: eventName,
                    description: eventDescription,
                    logo: eventLogo,
                    link: eventLink,
                    event_datetime: eventDate + ' ' + eventTime,
                    open_datetime: openDate + ' ' + openTime,
                    private: eventType==='Private Event',
                    activate_chat: includeChat,
                    activate_giveaway: includeGiveaways,
                    activate_handout: includeHandouts,
                    activate_speaker: includeSpeakers,
                    activate_feedback_form: includeFeedbackForm,
                    activate_agenda: includeAgenda,
                    title_display_choice: eventNameDisplayOption,
                    chat_activation_time: chatActivationTime===''||chatActivationTime?openDate + ' ' + openTime:openDate + ' '+ chatActivationTime,
                    status: 'pending',
                    handouts: store.getState().admin.handouts,
                    speakers: store.getState().speaker.speakers,
                    giveaways: store.getState().admin.giveaways,
                    agendas: store.getState().admin.agenda,
                    questions: store.getState().admin.feedbackQuestions,
                    
                    moderators: store.getState().admin.chat,
    
    
                    id: eventIdEditted
    
    
                }
            }
        }


        //1console.log('payload',payload);
        setLandingPreviewPayload(payload)
        setShowLandingPreview(true)
    }

    
    function onSubmit(){
        setLoadingSubmitEvent('Loading...')
        setErrorSubmitEvent('')
        setLoading('')

        var inviteesForPayload;
        if(inviteeListText===''){
            inviteesForPayload = []
        }
        else{
            inviteesForPayload = inviteeListText.split('\n')
            inviteesForPayload = inviteesForPayload.map(inv=>{return{phone: inv}})
            
        }
        inviteesForPayload = inviteesForPayload.filter(inv=>inv.phone!==''&&inv.phone!==' ')
        //1console.log(inviteesForPayload);
        

        let evTimePayload = Math.abs(eventTime.split(':')[0]) 
        evTimePayload = evTimePayload + ':' + eventTime.split(':')[1]
        
        let opTimePayload = Math.abs(openTime.split(':')[0]) 
        opTimePayload = opTimePayload + ':' + openTime.split(':')[1]

        let chatTimePayload = Math.abs(chatActivationTime?.split(':')[0]) 
        chatTimePayload = chatTimePayload + ':' + chatActivationTime?.split(':')[1]

        let payload = {}
        //1console.log(store.getState().admin.agenda);
        if(eventType==='Private Event'){

            //dont send inviteesFile if array is empty because it overrides invitees
            if(csvToJSON(csvPreview).length===0){
                payload = {
                    event:{
                        title: eventName,
                        description: eventDescription,
                        logo: eventLogo,
                        link: eventLink,
                        event_datetime: eventDate===''||eventTime===''?'':eventDate + ' ' + eventTime,
                        open_datetime:  openDate===''||openTime===''?'':openDate + ' ' + openTime,
                        private: eventType==='Private Event',
                        activate_chat: includeChat,
                        activate_giveaway: includeGiveaways,
                        activate_handout: includeHandouts,
                        activate_speaker: includeSpeakers,
                        activate_feedback_form: includeFeedbackForm,
                        activate_agenda: includeAgenda,
                        title_display_choice: eventNameDisplayOption,
                        chat_activation_time: chatActivationTime===''||chatActivationTime?openDate + ' ' + openTime:openDate + ' '+ chatActivationTime,
                        status: 'pending',
                        handouts: store.getState().admin.handouts,
                        speakers: store.getState().speaker.speakers,
                        giveaways: store.getState().admin.giveaways,
                        agendas: store.getState().admin.agenda,
                        questions: store.getState().admin.feedbackQuestions,
                        invitees: inviteesForPayload,
                        // "inviteesFile": csvToJSON(csvPreview),
                        moderators: store.getState().admin.chat,
        
        
                        id: eventIdEditted
        
        
                    }
                }
            }
            else{
                payload = {
                    event:{
                        title: eventName,
                        description: eventDescription,
                        logo: eventLogo,
                        link: eventLink,
                        event_datetime: eventDate===''||eventTime===''?'':eventDate + ' ' + eventTime,
                        open_datetime: openDate===''||openTime===''?'':openDate + ' ' + openTime,
                        private: eventType==='Private Event',
                        activate_chat: includeChat,
                        activate_giveaway: includeGiveaways,
                        activate_handout: includeHandouts,
                        activate_speaker: includeSpeakers,
                        activate_feedback_form: includeFeedbackForm,
                        activate_agenda: includeAgenda,
                        title_display_choice: eventNameDisplayOption,
                        chat_activation_time: chatActivationTime===''||chatActivationTime?openDate + ' ' + openTime:openDate + ' '+ chatActivationTime,
                        status: 'pending',
                        handouts: store.getState().admin.handouts,
                        speakers: store.getState().speaker.speakers,
                        giveaways: store.getState().admin.giveaways,
                        agendas: store.getState().admin.agenda,
                        questions: store.getState().admin.feedbackQuestions,
                        invitees: inviteesForPayload,
                        "inviteesFile": csvToJSON(csvPreview),
                        moderators: store.getState().admin.chat,
        
        
                        id: eventIdEditted
        
        
                    }
                }
            }
            
        }
        else{
            payload = {
                event:{
                    title: eventName,
                    description: eventDescription,
                    logo: eventLogo,
                    link: eventLink,
                    event_datetime: eventDate===''||eventTime===''?'':eventDate + ' ' + eventTime,
                    open_datetime: openDate===''||openTime===''?'':openDate + ' ' + openTime,
                    private: eventType==='Private Event',
                    activate_chat: includeChat,
                    activate_giveaway: includeGiveaways,
                    activate_handout: includeHandouts,
                    activate_speaker: includeSpeakers,
                    activate_feedback_form: includeFeedbackForm,
                    activate_agenda: includeAgenda,
                    title_display_choice: eventNameDisplayOption,
                    chat_activation_time: chatActivationTime===''||chatActivationTime?openDate + ' ' + openTime:openDate + ' '+ chatActivationTime,
                    status: 'pending',
                    handouts: store.getState().admin.handouts,
                    speakers: store.getState().speaker.speakers,
                    giveaways: store.getState().admin.giveaways,
                    agendas: store.getState().admin.agenda,
                    questions: store.getState().admin.feedbackQuestions,
                    
                    moderators: store.getState().admin.chat,
    
    
                    id: eventIdEditted
    
    
                }
            }
        }


        //1console.log(payload);


        //editing or creating?
        if(managemodestate==='create'){
            axios.post(baseAPI+'/api/event/create', payload, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                //1console.log(r);
                setLoadingSubmitEvent('')
                setLoading('Event created successfully')
                window.location.href='/admin'
            }).catch(e=>{
                setLoadingSubmitEvent('')
                //1console.log(e.response.data.errors);
                let procErrors = '';
                let procErrorsArr = []
                e.response.data.errors?.forEach(err => {
                    procErrors=''
                    procErrors+= err.message.includes('event.link')?err.message.replace('event.link', 'event link'):''
                    procErrors+= err.message.includes('The event.event_datetime is required to create a new event')?err.message.replace('The event.event_datetime is required to create a new event', 'Event date and/or time required '):''
                    procErrors+= err.message.includes('The event.open_datetime is required to create a new event')?err.message.replace('The event.open_datetime is required to create a new event', 'Open date and/or time required '):''
                    procErrors+= err.message.includes('date.format validation error on event.chat_activation_time')?err.message.replace('date.format validation error on event.chat_activation_time', 'Chat activation time is required '):''
                    procErrors+= err.message.includes('date.format validation error on event.agendas.0.start_time')?err.message.replace('date.format validation error on event.agendas.0.start_time', 'Agenda start date and/or time required'):''
                    procErrors+= err.message.includes('date.format validation error on event.agendas.0.end_time')?err.message.replace('date.format validation error on event.agendas.0.end_time', 'Agenda end date and/or time required'):''
                    procErrors+= err.message.includes('The event.moderators.')?err.message.replace('The event.moderators.', 'Event moderator '):''
                    
                    procErrorsArr.push(procErrors)
                });
                setErrorSubmitEvent(procErrorsArr)
                //1console.log(e.response);
                if(e.response.status===401){
                    removeUserSession()
                    props.history.push('/')
                }
            })
        }
        else{
            if(managemodestate.split(' ')[0]==='edit'){
                //check if editing running event and set status to running
                //1console.log(payload);
                
                if(managemodestate.split(' ')[managemodestate.split(' ').length-1]==='running'){
                    payload.event.status = 'running'
                    
                }
                axios.put(baseAPI+'/api/event/update', payload,{headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
                    //1console.log(r);
                    setLoadingSubmitEvent('Event updated successfully')
                    window.location.href='/admin'
                }).catch(e=>{
                    setLoadingSubmitEvent('')
                    if(e?.response?.status===401){
                        removeUserSession()
                        removeAdminId()
                        props.history.push('/')
                    }
                    //1console.log(e.response)
                    setErrorSubmitEvent(e.response.data.errors)
                })
            }
        }
        
        
    }


    const onDrop = useCallback((acceptedFiles) => {
        
        setInviteeCSV(acceptedFiles[0].name)
        const reader = new FileReader()

        reader.onabort = () => //1console.log('file reading was aborted')
        reader.onerror = () => //1console.log('file reading has failed')
        reader.onload = () => {
            // Do whatever you want with the file contents
            const binaryStr = reader.result
            // const binaryStr = reader.readAsText(file);
            //1console.log(binaryStr.split('\r\n'))
            setCsvPreview(binaryStr.split('\r\n'))
            
        }
        reader.readAsText(acceptedFiles[0])
        
        
    }, [])
    const onDropFile = useCallback((acceptedFiles, e) => {
        //1console.log(e);
        //1console.log(acceptedFiles[0]);
        var formData = new FormData()
        formData.append('file', acceptedFiles[0])
        setFileUploadName(acceptedFiles[0].name)
        onFileUpload(formData, e)

    }, [])

    const [eventLogo, setEventLogo] = useState('');
    function onFileUpload(formData, e){
        axios.post(baseAPI+'/api/files/image/upload',  formData, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data.url);
            if(e==='Event Logo')
                setEventLogo(r.data.url)
            
        }).catch(e=>{
            if(e.response.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
            //1console.log(e);
        })
    }


    useEffect(()=>{
        //1console.log(eventLogo);
    }, [eventLogo])


    function generateTemplateCSV(){
        let csv = ''
        var items = [{Name: '', Phone: ''}].slice();
        // Loop the array of objects
        for(let row = 0; row < items.length; row++){
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if(row === 0){

            // Loop each property of the object
                for(let key in items[row]){
                    
                                    // This is to not add a comma at the last cell
                                    // The '\r\n' adds a new line
                    csv += key + (keysCounter+1 < keysAmount ? ',' : '\r\n' ) 
                    //1console.log(csv);
                    keysCounter++
                }
            }
            else{
                for(let key in items[row]){
                    csv += items[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                    //1console.log(csv);
                    keysCounter++
                }
            }

            keysCounter = 0
        }

        //  download the .csv by creating a link
        let link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', 'Invitees Template.csv');
        document.body.appendChild(link)
        document.querySelector('#download-csv').click()
    }
    return (
        manageMode===''?
        <AdminCreateEditEvents style={props.style}/>
        :
        <div style={props.style}>
            {/* <AdminCreateEditEvents /> */}
            {/* <button className='cta-btn-blue' onClick={()=>setManageMode('')}>Back</button> */}
            <div className='a-landing-preview'style={{display: showLandingPreview?'flex':'none'}}>
                <button className='close' onClick={()=>setShowLandingPreview(false)}><img src={closeImg} /></button>
                <AdminLanding event={landingPreviewPayload} />
            </div>
            <h2 className='a-h2'>Create/Edit Event</h2>
            <h3 className='a-h3' id='basic-info'>Basic Information</h3>
            <div className='form-wrapper'>
                
                    <div className='form-wrapper1'>
                    <Input name='Event Name' type='text' value={eventName} onChange={e=>setEventName(e.target.value)} placeholder='Enter Event Name' />
                    
                    <DDL name='Privacy' value={eventType} onChange={e=>setEventType(e.target.value)} ddlItems={['Public Event', 'Private Event']} />

                    <Upload name='Event Logo' onDrop={(a)=>onDropFile(a,'Event Logo')} fileName={fileUploadName} accept="image/png, image/gif, image/jpeg" note={' '} />
                    {/* <input type='file' onChange={onFileUpload}/> */}
                    <Input name='Description' value={eventDescription} onChange={e=>setEventDescription(e.target.value)} type='text area' placeholder='Event Description'/>
                    <Input type='date' name='Event Date' value={eventDate} onChange={e=>setEventDate(e.target.value)} />
                    <Input type='date' name='Open Date' value={openDate} onChange={e=>setOpenDate(e.target.value)} />
                    <Input name='Event Time' value={eventTime} type={'time'} onChange={e=>setEventTime(e.target.value)} placeholder='00:00' />
                    <Input name='Open Time' value={openTime} type={'time'} onChange={e=>setOpenTime(e.target.value)} placeholder='00:00' />
                    {/* <button className='warning-btn'>End Event</button> */}
                    </div>
                    {/* <p className='error' style={{marginTop:0}}>{requiredWarning}</p> */}
                    
                    <DDL name='Show Event Name In' ddlItems={['Event Login Only', 'Landing Page Only', 'Everywhere', 'Nowhere']} value={eventNameDisplayOption} onChange={e=>setEventNameDisplayOption(e.target.value)} labelStyle={{width:'150px'}}/>
                
            </div>
            
            <h3 className='a-h3'>Additional Information</h3>
            <div className='form-wrapper-header' id='speakers'>
                <div className='f-w-h-include-section'></div>
                <p>Speakers</p>
                <div className='f-w-h-include-section' onMouseEnter={()=>{setShowToolTip(prev=>({...prev,'speakers':true}))}} onMouseLeave={()=>{setShowToolTip(prev=>({...prev,'speakers':false}))}}> 
                    <div className='tooltip-bottom' style={{display: showToolTip['speakers']?'block':'none'}}>
                       Checking this will hide this section from users in the landing page. All entered changes will still be saved if submitted.
                    </div>
                    <input type='checkbox' checked={includeSpeakers} onChange={e=>setIncludeSpeakers(e.target.checked)}></input>
                    <span>Activate Speakers</span>
                </div>
            </div>
            {retrievedSavedData?<Speakers active={includeSpeakers} initValues={speakersInitValues}/>:''}


            <div className='form-wrapper-header' id='handouts'>
                <div className='f-w-h-include-section'></div>
                <p>Handouts</p>
                <div className='f-w-h-include-section' onMouseEnter={()=>{setShowToolTip(prev=>({...prev,'handouts':true}))}} onMouseLeave={()=>{setShowToolTip(prev=>({...prev,'handouts':false}))}}>
                    <div className='tooltip-bottom' style={{display: showToolTip['handouts']?'block':'none'}}>
                       Checking this will hide this section from users in the landing page. All entered changes will still be saved if submitted.
                    </div>
                    <input type='checkbox' checked={includeHandouts} onChange={e=>setIncludeHandouts(e.target.checked)}></input>
                    <span>Activate Handouts</span>
                </div>
            </div>
            {retrievedSavedData?<Handouts active={includeHandouts} initValues={handoutsInitValues}/>:''}


            <div className='form-wrapper-header' id='giveaways'>
                <div className='f-w-h-include-section'></div>
                <p>Giveaways</p>
                <div className='f-w-h-include-section' onMouseEnter={()=>{setShowToolTip(prev=>({...prev,'giveaways':true}))}} onMouseLeave={()=>{setShowToolTip(prev=>({...prev,'giveaways':false}))}}>
                <div className='tooltip-bottom' style={{display: showToolTip['giveaways']?'block':'none'}}>
                       Checking this will hide this section from users in the landing page. All entered changes will still be saved if submitted.
                    </div>
                    <input type='checkbox' checked={includeGiveaways} onChange={e=>setIncludeGiveaways(e.target.checked)}></input>
                    <span>Activate Giveaways</span>
                </div>
            </div>
            {retrievedSavedData?<Giveaways active={includeGiveaways} initValues={giveawaysInitValues}/>:''}


            <div className='form-wrapper-header' id='agenda'>
                <div className='f-w-h-include-section'></div>
                <p>Agenda</p>
                <div className='f-w-h-include-section' onMouseEnter={()=>{setShowToolTip(prev=>({...prev,'agenda':true}))}} onMouseLeave={()=>{setShowToolTip(prev=>({...prev,'agenda':false}))}}>
                    <div className='tooltip-bottom' style={{display: showToolTip['agenda']?'block':'none'}}>
                       Checking this will hide this section from users in the landing page. All entered changes will still be saved if submitted.
                    </div>
                    <input type='checkbox' checked={includeAgenda} onChange={e=>setIncludeAgenda(e.target.checked)}></input>
                    <span>Activate Agenda</span>
                </div>
            </div>
            {retrievedSavedData?<Agenda active={includeAgenda} initValues={agendaInitValues}/>:''}


            <div className='form-wrapper-header' id='invitee-list' style={{display: eventType==='Public Event'?'none':'flex'}}>
                <p>Invitee List</p>
            </div>
            <div className='form-wrapper' style={{marginTop: '5%', marginBottom: '5%', display: eventType==='Public Event'?'none':'flex'}}>
                <Input type='text area' name='Invitee List' value={inviteeListText} onChange={e=>setinviteeListText(e.target.value)} placeholder={'Enter each mobile number on a separate line. \nEx: \n01234567890 \n01234567891 \n01234567892 \n01234567893'} />
                {/* <Upload name='Upload CSV' compress={true} onDrop={acceptedFiles=>{setInviteeCSV(acceptedFiles[0]?.name)}} fileName={inviteeCSV}/> */}

                <button className='cta-btn-blue' style={{fontSize: '1rem', display: 'flex', alignItems: 'center', marginTop: '0%'}} onClick={generateTemplateCSV}><img src={downloadIcon} width={'30px'} style={{marginRight:'5px'}}/> Download Template</button>
                <p>*Uploaded CSV files must abide by the provided template. Please download the template file and fill in the data.</p>

                <Upload name='Upload CSV' compress={true} onDrop={onDrop} fileName={inviteeCSV} accept=".csv" note={' '}/>
                
                <p className='p-title'>CSV Preview</p>
                <ul style={{height: '200px', overflowY: 'auto', width: '80%'}}>{csvPreview===''?'Upload a file to preview':csvPreview.map(x=><li>{x.replace(',', '\t')}</li>)}</ul>
                
                    
                
            </div>
            

            <div className='form-wrapper-header' id='event-link'>
                <p>Event Display Link</p>
            </div>
            <div className='form-wrapper' style={{marginTop: '5%', marginBottom: '5%'}}>
                <Input type='text' name='Event Link' value={eventLink} onChange={e=>setEventLink(e.target.value)} style={{marginBottom: 0}}/>
            </div>

            <div className='form-wrapper-header' id='chat-module'>
                
                
                <div className='f-w-h-include-section'></div>
                <p>Chat Module</p>
                <div className='f-w-h-include-section' onMouseEnter={()=>{setShowToolTip(prev=>({...prev,'chat':true}))}} onMouseLeave={()=>{setShowToolTip(prev=>({...prev,'chat':false}))}} >
                    <div className='tooltip-bottom' style={{display: showToolTip['chat']?'block':'none'}}>
                       Checking this will hide this section. All entered changes will still be saved if submitted.
                    </div>
                    <input type='checkbox' checked={includeChat} onChange={e=>setIncludeChat(e.target.checked)}></input>
                    <span>Activate Chat Module</span>
                </div>
            </div>
            <div className='form-wrapper' style={{marginTop: '5%',filter: includeChat?'none':'grayscale(100)'}}>
                <p style={{opacity:0.75}}>*Chat activation date will be set as the event date.</p>
                <p style={{marginTop:0,opacity:0.75}}>Chat will be open on <b>{(new Date(openDate).toLocaleDateString('en-GB')&&openDate) + ' at ' + chatActivationTime}</b></p>
                <Input type='time' name='Activation Time' value={chatActivationTime} onChange={e=>setChatActivationTime(e.target.value)} />
                <p className='section-sub-title'>Chat Moderators</p>
                {retrievedSavedData?<Chat active={includeChat} initValues={chatModeratorsInitValues}/>:''}

            </div>
            
            <div className='form-wrapper-header' id='chat-module'>
                
                
                <div className='f-w-h-include-section'></div>
                <p>Feedback Form</p>
                <div className='f-w-h-include-section' onMouseEnter={()=>{setShowToolTip(prev=>({...prev,'feedback':true}))}} onMouseLeave={()=>{setShowToolTip(prev=>({...prev,'feedback':false}))}}>
                    <div className='tooltip-bottom' style={{display: showToolTip['feedback']?'block':'none'}}>
                       Checking this will hide this section from users. All entered changes will still be saved if submitted.
                    </div>
                    <input type='checkbox' checked={includeFeedbackForm} onChange={e=>setIncludeFeedbackForm(e.target.checked)}></input>
                    <span>Activate Feedback Form</span>
                </div>
            </div>
            {retrievedSavedData?<FeedbackForm active={includeFeedbackForm} initValues={feedbackformInitValues} dim={managemodestate.split(' ')[managemodestate.split(' ').length-1]==='running'}/>:''}
            
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5%', flexDirection: 'column', alignItems: 'center'}}>
            <button className='primary-btn' style={{width: '30%', height: '60px', fontSize: '2rem', marginBottom:'3%'}} onClick={onLandingPreview}>Preview</button>
            <button className='primary-btn' disabled={loadingSubmitEvent!==''} style={{width: '30%', height: '60px', fontSize: '2rem'}} onClick={onSubmit}>Submit</button>
            
            <p className='loading'>{loadingSubmitEvent}</p>
            {errorSubmitEvent==='' || errorSubmitEvent?.map(x=><p className='error' style={{width: '80%'}}>{JSON.stringify(x)}</p>)}
            {/* <p className='error'>{errorSubmitEvent!=='' && JSON.stringify(errorSubmitEvent)}</p> */}
            
            
            </div>
            <hr style={{width: '80%', opacity: 0.5, marginTop: '5%'}}/>

            
        
        </div>
    )
}

export default ManageEvent
