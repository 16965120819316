import React, {useState, useEffect, useCallback } from 'react';
import logo from '../../img-src/loreal-expo-logo.png';

import {setUserSession, getToken, removeUserSession, setAdminId} from '../../Utils/Common';
import axios from 'axios';
import { baseAPI } from '../../config';
function AdminLogin(props) {
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    
    const enterFunction = useCallback((event) => {
        
        if(event.keyCode === 13 ) {
           
          
            document.querySelector('.next').click()
          
          
          
          
        //   document.querySelectorAll('.next').forEach(i=>i.click())
        }
      }, []);
    useEffect(() => {
        document.title = "L'Oréal Expo - Admin Login"
        window.addEventListener("keyup", enterFunction);
    }, [])

    function onSubmit(){
       
        var isValidEmail = validateEmail(email);
        if(!isValidEmail || password==='' || email===''){
            
            var errormsg = !isValidEmail||email===''?'Please enter a valid email. \n': '';
            errormsg += password===''?'Please enter your password.':''
            setError(errormsg);
            return;
        }
        

        setLoading('Loading...');

        
        axios.post(baseAPI+'/api/admin/login', {email: email, password: password}).then(r=>{
            setLoading('');
            //1console.log(r.data);
            setUserSession('token','user')
            setUserSession(r.data.token.token, email);
            setAdminId(r.data.admin.id)
            props.history.push('/admin');
        }).catch(e=>{
            setLoading('');
            if(e?.response?.status===400)
                setError('The email or password you entered is incorrect, please try again.')
            else
                setError('An error has occurred while logging in. Please check your internet connection and try again.')
            //1console.log(e);
        })
        
        

        setError('');
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    return (
        <div className='split-form'>
            <div className='splash'>
                <img src={logo} className='logo' alt='loreal expo'/>
                <div className='sponsors-container'>
                <p className='p-title' style={{fontSize: '3rem'}}>ADMIN LOGIN</p>
                </div>
            </div>


            <div className='form' style={{justifyContent: 'space-around'}}>
            <img src={logo} className='logo' alt='loreal expo'/>
                
                <div style={{textAlign: 'center'}}>
                    <h1>LOGIN</h1>
                    <p className='subtitle'>Please enter your email and password to login.</p>
                </div>
                
                <div style={{width: '80%'}}>
                    <div className='text-field' style={{width: '100%'}}>
                    <span className='label'>Email</span>
                    <input type='email' value={email} onChange={e=>setEmail(e.target.value)} placeholder='Enter Your Email' />
                    
                </div>
                <div className='text-field' style={{width: '100%'}}>
                    <span className='label'>Password</span>
                    <input type='password' value={password} onChange={e=>setPassword(e.target.value)} placeholder='Enter Your Password' />
                    
                </div>
                </div>
                
                
                <button className='cta-btn-blue next' onClick={onSubmit}>Login</button>
                <p className='error' style={{width: '80%'}}>{error}</p>
                <p className='loading'>{loading}</p>
                
            </div>
            
            
        </div>
    )
}

export default AdminLogin

