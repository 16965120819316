import React, { useState, useEffect } from 'react';
import axios from 'axios';
import loader from '../img-src/loader.gif';
import {setUserSession, getToken, getUser, removeUserSession} from '../Utils/Common';
function validateMobile(num){
    const re = /^01[0-9]\d{8}$/;
    return re.test(num)
}
function LandingEDerms(props) {
    const [street, setStreet] = useState('');
    const [mobile, setMobile] = useState('');
    const [loading, setLoading] = useState('');
    const [error, setError] = useState('')
    const [isEventActive, setIsEventActive] = useState(false);
    const [alreadyRegistered, setAlreadyRegistered] = useState(false);
    function checkIfEventActive(){
        var today = new Date();
        // if(today>= new Date("2021/7/12 00:19:00"))
        if(today>= new Date("2021/7/12 19:00:00"))
            setIsEventActive(true);
    }
    useEffect(() => {
        document.body.style.backgroundColor = "#0591cd";
        //1console.log(getToken());
        setAlreadyRegistered(false)
        ////1console.log(getToken().length);
        if(getToken()!==null){
            if(getToken().length > 20)
                setAlreadyRegistered(true);
        }
        var checkDateInterval = setInterval(checkIfEventActive, 1000)
        return () => {
            clearInterval(checkDateInterval)
        }
    }, [])
    function onSubmit(){
       
        
        if(!street || !validateMobile(mobile)){
            
            var errormsg=!street?'Please enter a valid address. ':''
            errormsg+=!validateMobile(mobile)?'Please enter a valid mobile number. ':''
            setError(errormsg)
            return;
        }
        
        setLoading('Loading...');

        //1console.log({building: 'building',floor: 'floor',apartment: 'NEW ADDRESS FIELDS',street: street,  governorate: 'governorate', city: 'city',country: 'country',postal_code: 'postalCode', phone: mobile })
        axios.post('https://api.acdexpo.com/api/profile/address', {building: 'building',floor: 'floor',apartment: 'apartment',street: street,  governorate: 'governorate', city: 'city',country: 'country',postal_code: 'postalCode', phone: mobile }).then(r=>{
            setLoading('');
            setLoading('Submitted successfully!')
            //setUserSession(r.data.token.token,mobile);
            //1console.log(r);
            if(isEventActive)
            props.history.push('/auditorium');
        }).catch(e=>{
            setLoading('');
            if(e?.response?.status===500)
                setError('You are already registered.')
            else
                setError('Error occurred while registering. Please try again later.')
            //1console.log(e);
        })
        
        

        setError('');
    }
    return (
        <div>
            
            <div className='hero-splash'>
                <div className='hero-content'>
                    <img className='logo mb5' />
                    <h1 className='m0'>E-DERMS MASTERCLASS</h1>
                    <h1 className='m0'>By <img className='loreal-logo' /></h1>
                </div>
                
            </div>
           <div className='date'>
                    <p>Date: July 14<sup>th</sup></p>
                    <p>Time: 9 PM</p>
                </div>
            <div className='agenda'>
                <div className='header'>
                    <h2 className='title m0'>Agenda</h2>
                </div>
                <div className='swimlane'>
                    <p className='label'>introduction</p>
                    <div className='details'>
                        <div className='agenda-item-title'>
                            <div className='number'><p>1</p></div>
                            <div className='agenda-item-text'>
                                <p>introduction</p>
                                <p>Speaker: Zahra and Haitham</p>
                            </div>
                            
                        </div>
                        <p className='agenda-item-time'>
                            Time: 9:00 to 9:15
                        </p>
                    </div>
                </div>
                <div className='swimlane'>
                    <p className='label'>digital media landscape</p>
                    <div className='details'>
                        <div className='agenda-item-title'>
                        <div className='number'><p>2</p></div>
                            <div className='agenda-item-text'>
                                <p>digital media landscape</p>
                                <p>Speaker: Shimaa ismail</p>
                            </div>
                            
                        </div>
                        <p className='agenda-item-time'>
                            Time: 9:15 to 9:30
                        </p>
                    </div>
                </div>
                <div className='swimlane'>
                    <p className='label'>content creation</p>
                    <div className='details'>
                        <div className='agenda-item-title'>
                        <div className='number'><p>3</p></div>
                            <div className='agenda-item-text'>
                                <p>content creation</p>
                                <p>Speaker: Engy Youssef</p>
                            </div>
                            
                        </div>
                        <p className='agenda-item-time'>
                            Time: 9:30 to 9:45
                        </p>
                    </div>
                </div>
                <div className='swimlane'>
                    <p className='label'>how to reach consumers </p>
                    <div className='details'>
                        <div className='agenda-item-title'>
                        <div className='number'><p>4</p></div>
                            <div className='agenda-item-text'>
                                <p>how to reach consumers </p>
                                <p>Speaker: Eman mashaly</p>
                            </div>
                            
                        </div>
                        <p className='agenda-item-time'>
                            Time: 9:45 to 10:00
                        </p>
                    </div>
                </div>
                <div className='swimlane'>
                    <p className='label'>instagram tactics</p>
                    <div className='details'>
                        <div className='agenda-item-title'>
                        <div className='number'><p>5</p></div>
                            <div className='agenda-item-text'>
                                <p>instagram tactics</p>
                                <p> Speaker: dr. razan kadry</p>
                            </div>
                            
                        </div>
                        <p className='agenda-item-time'>
                            Time: 10:00 to 10:15
                        </p>
                    </div>
                </div>
            
            
            </div>
        
        
            <div className='giveaway'>
                <h2>a valuable giveaway kit</h2>
                <h3>for every dermatologist</h3>
                <p>Upon your appreciated engagement and interaction you will be receiving a gift box from L’Oreal active cosmetic products.</p>

                <img className='card' />
            </div>
        
            <div className='register'>
                {/* <div className='overlay' style={{display:(alreadyRegistered&&!isEventActive)?'block':'none'}}>
                    <h2 >See you at 9PM</h2>
                </div> */}
                <div className='overlay' style={{display: loading?'flex':'none'}}>
                    
                    <img src={loader} width='100px' style={{display: loading !== 'Loading...'?'none':'block'}} />
                    <h2 style={{marginBottom: 0, fontSize: '40px'}}>{loading}</h2>
                    <h2 style={{display: loading==='Submitted successfully!'?'block':'none', fontSize: '40px'}}>See you at 9PM</h2>
                </div>
                
                <div className='text-field mb5'>
                    <span className='label'>Address</span>
                    <input type='text' className='f1' value={street} onChange={e=>setStreet(e.target.value)} placeholder='Enter Your Address' />
                    
                </div>
                
                <div className='text-field' >
                    <span className='label'>Number</span>
                    <input type='text' name="phone" value={mobile} onChange={e=>setMobile(e.target.value)} placeholder='Enter Your Mobile Number' />
                    
                </div>
                
                <button className='cta-btn-blue f1'  onClick={onSubmit} disabled={loading==='Loading...'?'disabled':''} >{isEventActive?'Join Now':'Submit'}</button>
                <p style={{color: 'red'}}>{error}</p>
                {/* <p style={{color: 'green'}}>{loading}</p> */}
                {/* <button className='cta-btn-img f1' onClick={()=>{props.history.push('/auditorium')}}>Join Now</button> */}
                
            </div>
        </div>
    )
}

export default LandingEDerms
