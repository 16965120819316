import React, {useState, useEffect, useCallback } from 'react';
import logo from '../img-src/loreal-expo-logo.png';
import dercosLogo from '../img-src/dercos-logo.png';
import larocheLogo from '../img-src/laroche-logo.png';
import vichyLogo from '../img-src/vichy-logo.png';
import ceraveLogo from '../img-src/cerave-logo.jpg'
import { getToken, setdummyId, setUserSessionOnly} from '../Utils/Common';
import axios from 'axios';
import Input from './Fields/Input';
import DDL from './Fields/DDL';
import { regions } from './content';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'; 

import { useDispatch } from 'react-redux';
import { setDummyIdGlobal } from './slices/globalSlice';
import { baseAPI, enableConsentForm } from "../config";
import ConsentForm from './Util/ConsentForm';
function Login(props) {
    const dispatch = useDispatch()
    const [mobile, setMobile] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');

    const [fullName, setFullName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('Egypt')
    const [clinicalRegion, setClinicalRegion] = useState('')
    const [city, setCity] = useState('')
    const [area, setArea] = useState('')
    const [address, setAddress] = useState('')
    const [dummyId, setDummyId] = useState('')
    const [userId, setUserId] = useState('')

    const [state, setState] = useState(0)
    const [isReturningUser, setIsReturningUser] = useState(false)

    const tourLink = '/tour';

    const enterFunction = useCallback((event) => {
        
        if(event.keyCode === 13 ) {
           
          
          if(document.querySelector('.part1').style.display==='none'){
            document.querySelector('.next1').click()
          }
          
          if(document.querySelector('.part1').style.display==='flex'){
            document.querySelector('.next2').click()
          }
        //   document.querySelectorAll('.next').forEach(i=>i.click())
        }
      }, []);
    useEffect(() => {
        document.title = "L'Oréal Expo - Login"
        setIsReturningUser()
        window.addEventListener("keyup", enterFunction);
    }, [])
    
    async function saveClickAnalytics(btn, eventId){
        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn})          
        }
        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId})
    }

    function saveClickAnalyticsThenRedirectToTour(btn){
        
        axios.post(baseAPI+'/api/button/click/add', {label: btn}).then(r=>{

            axios.post(baseAPI+'/api/user/setEntry', {dummy_id: dummyId}).then(r=>{
                //1console.log(r);
                dispatch(setDummyIdGlobal(dummyId))
                //set dummy id in local storage
                setdummyId(dummyId)
                //set phone number in local storage
                setUserSessionOnly(mobile)
                props.history.push(tourLink)
            }).catch(e=>{
                //1console.log(e);
                //1console.log(dummyId);
                setError('An error occurred. Please try again.')
            })
            
        }).catch(e=>{
            axios.post(baseAPI+'/api/user/setEntry', {dummy_id: dummyId}).then(r=>{
                //1console.log(r);
                dispatch(setDummyIdGlobal(dummyId))
                props.history.push(tourLink)
            }).catch(e=>{
                //1console.log(e);
                setError('An error occurred. Please try again.')
            })
            // window.location.href = tourLink
        })
        
    }

    function saveClickAnalyticsThenRedirectToAuditorium(btn){
        
        axios.post(baseAPI+'/api/button/click/add', {label: btn}).then(r=>{

            axios.post(baseAPI+'/api/user/setEntry', {dummy_id: dummyId}).then(r=>{
                //1console.log(r);
                dispatch(setDummyIdGlobal(dummyId))
                //set dummy id in local storage
                setdummyId(dummyId)
                //set phone number in local storage
                setUserSessionOnly(mobile)
                props.history.push('/auditorium')
            }).catch(e=>{
                //1console.log(e);
                //1console.log(dummyId);
                setError('An error occurred. Please try again.')
            })
            
        }).catch(e=>{
            axios.post(baseAPI+'/api/user/setEntry', {dummy_id: dummyId}).then(r=>{
                //1console.log(r);
                dispatch(setDummyIdGlobal(dummyId))
                props.history.push('/auditorium')
            }).catch(e=>{
                //1console.log(e);
                setError('An error occurred. Please try again.')
            })
            // window.location.href = tourLink
        })
        
    }
    function onSubmit(){
       
        var isValidMobile = validateMobile(mobile);
        if(!isValidMobile || mobile===''){
            
            var errormsg = 'Please enter a valid mobile number. \n';
        
            setError(errormsg);
            return;
        }
        

        setLoading('Loading...');

        
        axios.post(baseAPI+'/api/user/virtualLogin', {phone: mobile}).then(r=>{
            setLoading('');
            //1console.log(r);
            setFullName(r.data.name)
            setPhone(r.data.phone)
            setEmail(r.data.email)
            setCountry(r.data.country)
            setClinicalRegion(r.data.clinical_region)
            setUserId(r.data.id)
            saveClickAnalytics('Next - Login',null)
            setDummyId(r.data.dummy_id)
            axios.get(baseAPI+'/api/user/address/get/'+r.data.id, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                //1console.log(r);
                // var x = r.data.address?.split('%')
                // if(Array.isArray(x)){
                //     setCity(x[0])
                //     setArea(x[1])
                //     setAddress(x[2])
                // }
                setAddress(r.data.address)
                setCity(r.data.city)
                setArea(r.data.area)
                
            }).catch(e=>{
                //1console.log(e.response);
            })
            //setUserSession('token','user')
            // setUserSession(r.data.token.token, mobile);
            // window.location.href = 'https://tour.acdexpo.com/';
            setState(1)
        }).catch(e=>{
            setLoading('');
            if(e?.response?.status===404)
                setError('The mobile number you entered is incorrect, please login again or register.')
            else
                setError('An error has occurred. Please try again.')
            //1console.log(e.response);
        })
        
        

        setError('');
    }

    function onUpdateDetails(){
        setLoading('Loading...')
        setError('')
        saveClickAnalytics('Save Changes (Edit Info) - Login',null)
        axios.put(baseAPI+'/api/user/'+userId, {email, country, clinical_region: clinicalRegion, name: fullName}).then(r=>{
            

            axios.put(baseAPI+'/api/user/address/update/'+userId, {address: address, city:city, area:area}).then(r=>{
                setLoading('Saved!')
                setTimeout(() => {
                    setLoading('')
                }, 1000);
                setState(1)
                // window.location.href=tourLink
            }).catch(e=>{
                setError('An error occurred while updating your info, please try again')
            })
        }).catch(e=>{
            
            setError('An error occurred while updating your info, please try again')
        })
    }

    function validateMobile(num){
        // const re = /^01[0-9]\d{8}$/;
        // return re.test(num)

       const re = /^(?:(\+))?(?:[0-9]{0,3}[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{4}\)))?(\d{4})(?:(?!\(\d{4})\))?[\s.\/-]?)?$/
       return re.test(num)
    }
    const [showConsentForm, setShowConsentForm] = useState(false)
    const [destination, setDestination] = useState('acd')
    function acceptConsentForm(){
        //api call then onsubmit
            axios.post(baseAPI+'/api/user/consent', {'phone':phone}).then(r=>{
                if(destination==='acd'){
                    saveClickAnalyticsThenRedirectToTour('Continue To Tour - Login')
                }
                else{
                    saveClickAnalyticsThenRedirectToAuditorium('Continue To Auditorium - Login')
                }
            }).catch(e=>{
                setError('An error occurred while updating your info, please try again')
            })
    }
    return (
        <div className='split-form'>
            <div className='splash'>
                <img src={logo} className='logo' alt='loreal expo'/>
                <div className='sponsors-container'>
                <img src={dercosLogo}  alt='loreal expo' height='100%'/>
                <img src={larocheLogo}  alt='loreal expo' height='100%'/>
                <img src={vichyLogo}  alt='loreal expo' height='100%'/>
                <img src={ceraveLogo}  alt='loreal expo' height='100%'/>
                </div>
            </div>
            {enableConsentForm && <ConsentForm 
                show={showConsentForm}
                onClose={()=>setShowConsentForm(false)}
                onAgree={()=>{saveClickAnalytics('Accept consent form - Login',null).then(acceptConsentForm()); }}
            />}

            <div className='form' style={{justifyContent: 'flex-start'}}>
                <img src={logo} className='logo' alt='loreal expo'/>
                
                <div style={{textAlign: 'center', marginTop: '10%'}}>
                    <h1>{state===2?'ACCOUNT INFO':'LOGIN'}</h1>
                    
                </div>
                

                <div style={{display: state===0?'inherit':'none', flexDirection: 'column', width:'100%', alignItems: 'center'}}>
                    <p className='subtitle' style={{padding: '0 10%'}}>Please enter your mobile number to login.</p>
                    <div className='text-field'>
                        <span className='label'>Number</span>
                        <input type='text' value={mobile} onChange={e=>setMobile(e.target.value)} placeholder='Enter Your Mobile Number' />
                        
                    </div>
                    
                    <button className='cta-btn-blue next1' onClick={onSubmit}>Next</button>
                    <a href='/registration' style={{marginTop: '5%'}}>New user? Click here to register.</a>
                    <p className='error' style={{width: '80%'}}>{error}</p>
                    <p className='loading'>{loading}</p>
                </div>
                
                

                <div style={{textAlign: 'center', display: state===1?'block':'none'}}>
                    <p className='subtitle' style={{padding: '0 10%'}}>If you would like to edit your previously entered info, please click the Edit Info button below. </p>
                    <button className='cta-btn-blue' onClick={()=>{setState(2); saveClickAnalytics('Edit Info - Login', null)}}>Edit Info</button>
                    <p style={{fontSize: '2rem', fontWeight: 'bold'}}>Or</p>
                    <button className='cta-btn-blue' style={{marginTop:0}} onClick={()=>{setShowConsentForm(true); setDestination('acd')}}>Go to Expo</button>
                    <p style={{fontSize: '2rem', fontWeight: 'bold'}}>Or</p>
                    <button className='cta-btn-blue' style={{marginTop:0}} onClick={()=>{setShowConsentForm(true); setDestination('event')}}>Go to Event</button>
                </div>

                <div className='form part1' style={{display: state===2?'flex':'none', width: '100%'}}>
                    <p className='subtitle' style={{padding: '0 10%'}}>Edit your account info.</p>
                    <Input name='Full Name' value={fullName} onChange={e=>setFullName(e.target.value)} />
                    <Input name='Contact Number' value={phone} disabled={true} onChange={e=>setPhone(e.target.value)} />
                    <Input name='E-Mail' value={email} onChange={e=>setEmail(e.target.value)} />
                    <div className='text-field'>
                        <span className='label'>Country</span>
                        <CountryDropdown
                            value={country}
                            onChange={(val) => setCountry(val)} />
                        
                    </div>
                    <DDL name='Clinical Region' value={clinicalRegion} onChange={e=>setClinicalRegion(e.target.value)} ddlItems={country!=='Egypt'?['Other']:regions} />
                    <p className='p-title'>Address</p>
                    <div className='text-field'>
                        <span className='label'>City</span>
                        <RegionDropdown
                        country={country}
                        value={city}
                        onChange={(val) => setCity(val)} />
                        
                    </div>
                    <Input name='Area' value={area} onChange={e=>setArea(e.target.value)} />
                    <Input name='Address' value={address} onChange={e=>setAddress(e.target.value)} />
                    
                    <button className='cta-btn-blue next2' onClick={onUpdateDetails}>Save Changes</button>
                    <p className='loading'>{loading}</p>
                    <p className='error'>{error}</p>
                </div>
            </div>
            
            
            
        </div>
    )
}

export default Login

