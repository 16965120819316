
import speakersIcon from '../../img-src/icons/handout.png'
import handoutImg from '../../img-src/EDerm/stars.png';
import downloadIcon from '../../img-src/icons/download.png';
import closeIcon from '../../img-src/icons/close-c.png';
import React, { useState, useEffect, useRef } from 'react'
import testPdf from '../../img-src/Admin ACD.pdf'
import { v4 } from 'uuid';
import defaultImg from '../../img-src/icons/handout-placeholder.png'
function Handouts(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [pdfToPreview, setPdfToPreview] = useState('')
    const expandPreview = useRef()
    var handoutItems = [];
    const [hI, setHI] = useState()
    useEffect(() => {
        // //1console.log('handout props', props);
        handoutItems = props.handouts.map((h,i)=>
        <div className='handout-wrapper mb' key={'auditorium-handouts-item'+i}>
        <div className='handout-card'>
            <p className='handout-name'>{h.title}</p>

            <div className='handout-img' style={{backgroundImage: 'url('+(h.imgUrl||defaultImg)+')'}}>

            </div>
            {/* <img src={h.imgUrl||defaultImg} /> */}
            
            <button className='landing-c-btn' onClick={()=>{setIsExpanded(true); setPdfToPreview(h.file)}}>Preview</button>
        </div>
        <p className='handout-text'>{h.desc}</p>
        </div>
            )

            setHI(handoutItems)

    }, [props])

    const [pdfPreviewEl, setPdfPreviewEl] = useState('')
    useEffect(() => {
        
        setPdfPreviewEl(<embed src={'https://docs.google.com/viewer?embedded=true&url='+pdfToPreview+''} style={{width:'100%', height:'100%'}}/>)
    }, [pdfToPreview])
    useEffect(() => {
        // //1console.log(window.scrollY- expandPreview.current.scrollHeight);
        
        if(isExpanded) {
            document.body.style.overflow = 'hidden'
            //!props.showTitle is just checking if we are in the landing page and not in the auditorium
            if(!props.showTitle){
                document.querySelector('.nav-spotlight').style.overflow = 'hidden'
                document.querySelector('.nav-spotlight').scrollTop = 0
            }
            
            //1console.log(window.pageYOffset);
            expandPreview.current.style.top =  window.pageYOffset+'px'
            //1console.log(expandPreview);
        }
        else{
            document.body.style.overflow = 'unset'
            if(!props.showTitle)
                document.querySelector('.nav-spotlight').style.overflow = 'auto'
        }
    }, [isExpanded])
    return (
        <div className='handouts-c' style={props.style}>
            <div className='title' style={{visibility: props.showTitle?'visible':'hidden'}}>
                <img src={speakersIcon} />
                
                <h2>Handouts</h2>
                
            </div>
            <h2 className='alt-title' style={{display: !props.showTitle?'block':'none'}}>Handouts</h2>
            {hI}
            {/* <div className='handout-wrapper mb'>
            <div className='handout-card'>
                <p className='handout-name'>{'Handout Title'}</p>

                <embed src={testPdf} />
                <button className='landing-c-btn' onClick={()=>setIsExpanded(!isExpanded)}>Preview</button>
            </div>
            <p className='handout-text'>{'Handout description'}</p>
            </div> */}
            <div className='handout-expand' style={{display: isExpanded?'block':'none'}} ref={expandPreview}>
                <div className='header' style={{padding:'1% 0'}}>
                    {/* <p>File Name</p> */}
                    <img src={downloadIcon} onClick={()=>{window.open(pdfToPreview); setIsExpanded(false)}} />
                    <img src={closeIcon} onClick={()=>setIsExpanded(!isExpanded)}/>
                </div>
                
                <div className='content-wrapper'>
                    <div className='content' style={{backgroundImage: 'url('+defaultImg+')', backgroundSize:'contain', backgroundPosition:'center', backgroundRepeat:'no-repeat'}}>
                        {pdfPreviewEl}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Handouts
