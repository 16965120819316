import React, { useState, useEffect } from 'react'
import Input from '../Fields/Input'
import Upload from '../Fields/Upload'
import DDL from '../Fields/DDL'
import DateInput from '../Fields/DateInput'
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import { updateAgenda } from "../slices/adminSlice";

function Agenda(props) {
    const [AgendaLocal, setAgendaLocal] = useState([])
    const speaker = useSelector(state=>state.speaker.speakers)
    const [speakersLocal, setSpeakersLocal] = useState([])
    const [agendaElements, setAgendaElements] = useState()
    
    useEffect(() => {
      //1console.log(speakersLocal);
      var temp = speakersLocal.map(item=>item.Name)
      
     
    }, [speakersLocal])
    const dispatch = useDispatch()


    //save local version to store
    useEffect(() => {

      
      dispatch(updateAgenda(AgendaLocal.map(a=>{
        if(a['Start Date']===''||a['Start Time']===''||a['End Date']===''||a['End Time']===''||!a['Start Date']||!a['Start Time']||!a['End Date']||!a['End Time']){
          return{
            topic: a.Topic,
            speaker_name: a['Speaker Name']
            
          }
        }else{
          return{
            topic: a.Topic,
            speaker_name: a['Speaker Name'],
            start_time: a['Start Date'] + ' ' + a['Start Time'],
            end_time: a['End Date'] + ' ' + a['End Time']
          }
        }
        
      })))
    }, [AgendaLocal])



    const onChange = (index, event) => {
        if(event.target.name!=='Picture' && event.target.name!=='Start Date' && event.target.name!=='End Date'){
            event.preventDefault();
            event.persist();
        }
        
    
        setAgendaLocal((prev) => {
          return prev.map((item, i) => {
            if (i !== index) {
              return item;
            }
    
            return {
              ...item,
              [event.target.name]: event.target.value
            };
          });
        });
      };
    useEffect(() => {
    
    
      setAgendaElements(AgendaLocal.map((s,i)=><div  style={{width: '100%'}} key={'handounts-a-'+i}>
                        <p className='speaker-num'>Section {i+1}</p>
                        <button className='warning-btn' onClick={(e)=>handleRemove(e, i)} style={{marginLeft: '7%', padding: '0.3rem 1rem', marginBottom: '5%'}}>Remove Section</button>
                        <div style={{display: 'flex', flexWrap: 'wrap', padding: '0 5%'}}>
                            
                            <Input type='text' name='Speaker Name' value={s['Speaker Name']}  onChange={(e)=>onChange(i,e)} style={{flex: '1 1 45%'}}/>
                            {/* <DDL name='Speaker Name' value={s['Speaker Name']} ddlItems={speaker.map(item=>item.name)}  onChange={(e)=>onChange(i,e)} style={{flex: '1 1 45%'}}/> */}
                            {/* <Upload name='Picture' onDrop={(acceptedFiles) => {onChange(i,{target: {value:acceptedFiles[0].name, name: 'Picture'}})}} fileName={s.Picture} compress={true}/> */}
                            <Input type='text' name='Topic' value={s.Topic}  onChange={(e)=>onChange(i,e)} style={{flex: '1 1 45%'}}/>
                            
                            <Input type='time' name='Start Time' value={s['Start Time']}  onChange={(e)=>onChange(i,e)} style={{flex: '1 1 45%'}}/>
                            <Input type='date' name='Start Date' value={s['Start Date']}  onChange={(e)=>onChange(i,e)} style={{flex: '1 1 45%'}}/>
                            <Input type='time' name='End Time' value={s['End Time']}  onChange={(e)=>onChange(i,e)} style={{flex: '1 1 45%'}}/>
                            <Input type='date' name='End Date' value={s['End Date']}  onChange={(e)=>onChange(i,e)} style={{flex: '1 1 45%'}}/>
                            
                        </div>

                    </div>))
    }, [speaker, AgendaLocal])
    const handleAdd = (e) => {
    e.preventDefault();
    const inputState = {
      'Speaker Name': '', Picture: '', Topic: '', 'Start Time':'', 'Start Date': '', 'End Time':'', 'End Date': ''
        

        
    };

   
    setAgendaLocal((prev) => [...prev, inputState]);
    
    };
    function handleRemove(e, i){
        
        e.preventDefault()
        
        setAgendaLocal((prev) => prev.filter((item) => item !== prev[i]));
    }

    useEffect(() => {
      //1console.log(props.initValues);
      //initialize with saved data if available
      if(props.initValues!==null){
        
        var x = props.initValues.map(s=>{return{'Speaker Name': s.speaker_name, Topic: s.topic, 'Start Time': s.start_time?.split('T')[1].split('.')[0], 'Start Date': s.start_time?.split('T')[0], 'End Time': s.end_time?.split('T')[1].split('.')[0], 'End Date': s.end_time?.split('T')[0]}})
        setAgendaLocal(x)
    }
    }, [])
    return (
        <div className='form-wrapper' style={{filter: props.active?'none':'grayscale(100)', opacity: props.active?'1':'0.4'}}>
           
            {/* {JSON.stringify(AgendaLocal)} */}
            {agendaElements}
            
            <button className='a-link' onClick={handleAdd} style={{margin:'2% 0'}}>+ Add Section</button>
        </div>
    )
}

export default Agenda
