import "./App.css";
import Register from "./components/Register";
import Auditorium from "./components/Auditorium";
import Login from "./components/Login";
import Landing from "./components/landing/Landing";
import LandingEDerms from "./components/LandingEDerms";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import UserPrivateRoute from "./Utils/UserPrivateRoute";
import UserPublicRoute from "./Utils/UserPublicRoute";
import ManageWinners from "./components/ManageWinners";
import AdminMain from "./components/admin/AdminMain";
import AdminLogin from "./components/admin/AdminLogin";
import Archive from "./components/Archive";
import LoginACD from "./components/LoginACD";
import test from "./components/test";
import AdminGeneralPlatformAnalytics from "./components/admin/AdminGeneralPlatformAnalytics";
import Tour from "./components/Tour";
import Walkthrough from "./components/Walkthrough/Walkthrough";
import AdminAnalysis from "./components/admin/AdminAnalysis";
import AdminArchive from "./components/admin/AdminArchive";
import AdminAccess from "./components/admin/AdminAccess";
import AdminRegistrationForms from "./components/admin/AdminRegistrationForms";
import AdminDashboard from "./components/admin/AdminDashboard";
// import { socketObj } from './components/service/socket';
import { useEffect } from "react";
import AdminFeedbackForms from "./components/admin/AdminFeedbackForms";
import axios from "axios";
import AdminLanding from "./components/landing/AdminLanding";
import store from "./store";
import { getDummyId } from "./Utils/Common";
import AdminDocumentation from "./components/admin/AdminDocumentation";
import { baseAPI } from "./config";
import Count from "./components/admin/Count";
function App() {
  useEffect(() => {
    var blob = new Blob([JSON.stringify({ dummy_id: getDummyId() })], {
      type: "application/json; charset=UTF-8",
    }); // the blob

    // window.addEventListener('beforeunload', e=>{
    //     // e.preventDefault();

    //     navigator.sendBeacon('https://api.acdexpo.com/api/user/setExit', blob);
    //     return e.returnValue = 'Are you sure?'
    // })

    window.addEventListener(
      "beforeunload",
      function (e) {
        navigator.sendBeacon(baseAPI + "/api/user/setExit", blob);
      },
      false
    );
    return () => {};
  }, []);
  useEffect(() => {
    //1//1console.log(store.getState());
  }, [store]);
  return (
    <BrowserRouter>
      <div>
        <div className="content">
          <Switch>
            <Route exact path="/" component={Walkthrough} />

            <Route exact path="/registration" component={Register} />

            <Route exact path="/login" component={Login} />
            <UserPublicRoute exact path="/login-acd" component={LoginACD} />
            <UserPrivateRoute exact path="/landing" component={Landing} />
            <UserPrivateRoute exact path="/auditorium" component={Auditorium} />

            <Route exact path="/auditorium" component={Auditorium} />

            <PrivateRoute exact path="/admin" component={AdminDashboard} />
            <PrivateRoute exact path="/admin/prg/count" component={Count} />
            <PrivateRoute exact path="/admin/manage" component={AdminMain} />
            <PublicRoute exact path="/admin-login" component={AdminLogin} />
            <Route exact path="/archive" component={Archive} />
            <Route exact path="/test" component={test} />
            <PrivateRoute
              exact
              path="/admin/dashboard"
              component={AdminDashboard}
            />
            <PrivateRoute
              exact
              path="/admin/general"
              component={AdminGeneralPlatformAnalytics}
            />
            <PrivateRoute exact path="/admin/past" component={AdminAnalysis} />
            <PrivateRoute
              exact
              path="/admin/archive"
              component={AdminArchive}
            />
            <PrivateRoute
              exact
              path="/admin/doc"
              component={AdminDocumentation}
            />
            {/* <PrivateRoute exact path="/admin/landing"  component={AdminLanding} /> */}

            <PrivateRoute exact path="/admin/access" component={AdminAccess} />
            <PrivateRoute
              exact
              path="/admin/reg-forms"
              component={AdminRegistrationForms}
            />
            <PrivateRoute
              exact
              path="/admin/feedback-forms"
              component={AdminFeedbackForms}
            />
            <Route exact path="/tour" component={Tour} />
            <Route exact path="/walkthrough" component={Walkthrough} />
            <Redirect from="*" to="/" />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
