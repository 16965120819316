import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { getToken } from "../../Utils/Common";

const Count = () => {
  const [onlineUsersCount, setOnlineUsersCount] = useState(0);
  useEffect(() => {
    axios
      .post(baseAPI + "/api/analysis/running", "", {
        headers: { Authorization: "bearer " + getToken() },
      })
      .then((response) => {
        setOnlineUsersCount(response.data.online_users);
      })
      .catch((e) => {
        console.log(e);
      });
  },[]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <p>{onlineUsersCount}</p>
    </div>
  );
};

export default Count;
