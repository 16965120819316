import React, { useState, useEffect } from 'react'
import calendarIcon from '../../img-src/icons/calendar.png'
import clockIcon from '../../img-src/icons/clock.png'
function FilterBar(props) {
    return (
        <div className='filter-bar' style={props.style}>
            <div style={{width:'50%'}}>
                {/* <input type='checkbox' value={useDayFilter} onChange={e=>setUseDayFilter(e.target.checked)}/> */}
                <img src={calendarIcon} style={{height:'41%'}}/>
                <p>From</p>
                <input type='date' value={props.FromDay} onChange={props.onChangeFromDay}  style={{width:'130px'}}/>
                <p style={{marginLeft: '5%'}}>To</p>
                <input type='date' value={props.ToDay} onChange={props.onChangeToDay}  style={{width:'130px'}}/>
            </div>
            <div style={{width:'45%'}}>
                {/* <input type='checkbox' value={useTimeFilter} onChange={e=>setUseTimeFilter(e.target.checked)}/> */}
                <img src={clockIcon} style={{height:'50%'}} />
                <p>From</p>
                <input type='time' value={props.FromTime} onChange={props.onChangeFromTime} style={{width:'100px'}}/>
                <p>To</p>
                <input type='time' value={props.ToTime} onChange={props.onChangeToTime} style={{width:'100px'}}/>
            </div>

            {/* <button style={{width:'10%', padding:0, margin: '0 0'}} className='warning-btn'>Clear</button> */}
        </div>
    )
}

export default FilterBar
