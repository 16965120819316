
import FlipCountdown from '@rumess/react-flip-countdown';
import logo from '../../img-src/loreal-expo-logo.png';
import downIcon from '../../img-src/icons/down.png';
import React, { useState, useEffect } from 'react'

function Hero(props) {
    //1console.log(props);
    const [localCountdownDate, setLocalCountdownDate] = useState(props.eventStartDate)
    const [delay, setDelay] = useState(false)
    useEffect(() => {
        
        setLocalCountdownDate(props.eventStartDate)

    }, [props])

    useEffect(() => {
        
        setTimeout(() => {
            setDelay(true)
        }, 2000);
    }, [])
    return (
        <div className='hero-c'>
            <img src={props.logoUrl} className='logo' />
            <h1 className='title'>{props.eventName}</h1>
            <p className='desc'>{props.eventDescription}</p>

            {delay&&<div className='countdown-c' style={{display: props.eventStartDate===null?'none':'block'}}>
                
                
                <FlipCountdown
                hideYear
                hideMonth
                titlePosition='bottom'
                size='large'
                
                endAt={localCountdownDate} 
                />
            </div>}
            <div className='info'>
                <p>Scroll to see more</p>
                <img src={downIcon} />
            </div>
        </div>
    )
}

export default Hero
