
import Hero from './Hero';
import Speakers from './Speakers';
import Agenda from './Agenda';
import Handouts from './Handouts';
import Giveaways from './Giveaways';
import Footer from './Footer';
import Slider from './Slider';
import logo from '../../img-src/loreal-expo-logo.png';
import placeholderImg from '../../img-src/EDerm/stars.png';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import loader from '../../img-src/icons/loader.gif'
import { getUser, getToken, removeUserSession } from '../../Utils/Common';
import { baseAPI } from '../../config';
function Landing(props) {
    // let speakers = [{name: 'Professor Thomas Luger', imgUrl: placeholderImg, desc: 'Ad aliquip duis irure fugiat fugiat enim adipisicing veniam et.'},{name: 'Dr Mai El Samahy', imgUrl: placeholderImg, desc: 'Ad aliquip duis irure fugiat fugiat enim adipisicing veniam et.'}]
    // let agendaItems = [{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'},{speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'}, {speakerName: 'Speaker Name', title: 'Section Title', time: '12:00-1:00'}];
    // let handouts = [{title: 'Handout Title', imgUrl: placeholderImg, desc: 'Irure laborum cillum aliquip elit laboris. Amet nisi occaecat laborum ut ipsum magna minim ex do. Dolore aute dolore pariatur tempor amet eiusmod ipsum proident. Quis proident ullamco reprehenderit nulla consequat amet mollit esse aute amet Lorem cillum. Mollit pariatur reprehenderit id voluptate sint.'},{title: 'Handout Title', imgUrl: placeholderImg, desc: 'Irure laborum cillum aliquip elit laboris. Amet nisi occaecat laborum ut ipsum magna minim ex do. Dolore aute dolore pariatur tempor amet eiusmod ipsum proident. Quis proident ullamco reprehenderit nulla consequat amet mollit esse aute amet Lorem cillum. Mollit pariatur reprehenderit id voluptate sint.'}]
    const [currentEventId, setCurrentEventId] = useState('')
    const [speakers, setSpeakers] = useState([''])
    const [agendaItems, setAgendaItems] = useState([''])
    const [handouts, setHandouts] = useState([''])
    const [hero, setHero] = useState({name: '', desc: '', startDate: '2030/1/1', logo: ''})
    const [giveaways, setGiveaways] = useState([''])
    const [noEventRunning, setNoEventRunning] = useState(false)
    const [retrievedAddress, setRetrievedAddress] = useState('')
    const [retrievedCity, setRetrievedCity] = useState('')
    const [retrievedArea, setRetrievedArea] = useState('')
    
    const [eventOpened, setEventOpened] = useState(false)
    const [loggedInUser, setLoggedInUser] = useState('')
    const [closed, setClosed] = useState(true)
    const [eventDate, setEventDate] = useState('')
    const [openDate, setOpenDate] = useState('')
    const [showSpeakers, setShowSpeakers] = useState(false)
    const [showAgenda, setshowAgenda] = useState(false)
    const [showGiveaways, setShowGiveaways] = useState(false)
    const [showHandouts, setShowHandouts] = useState(false)
    const [country, setCountry] = useState('')

    const [showEventName, setShowEventName] = useState(false)
    const [activateAnalysis, setActivateAnalysis] = useState(true)
    
    useEffect(() => {
        
        setSplashText('Loading...')
        axios.get(baseAPI+'/api/app/control').then(r=>{
      
            if(r.data){
                props.history.push('/archive')
            }
        }).catch(e=>{
            props.history.push('/archive')
        })
        //check if event running to block page
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            setSplashText('')
            setCurrentEventId(r.data.id)
            setActivateAnalysis(r.data.activate_analysis)
            setShowEventName(r.data.title_display_choice==='Everywhere'||r.data.title_display_choice==='Landing Page Only')
            //1console.log(r);
            
            var now = new Date()
            // now.setHours(now.getHours()+2)
            //1console.log(r.data.event_datetime);
            var evdate = new Date(Date.parse(r.data.event_datetime))
            
            evdate.setHours(evdate.getHours())
            //1console.log(now);
            //1console.log((r.data.event_datetime));
            //1console.log(evdate);
            
            //1console.log(now < evdate);
            //1console.log('open date');
            setEventDate(eventDate)
            var opDate = new Date(r.data.open_datetime)
            opDate.setHours(opDate.getHours())
            //1console.log(opDate);
            setOpenDate(opDate)
            //open landing if we are past the event start date
            now < evdate? setEventOpened(false):setEventOpened(true)
            
            //if there is an event running, get event
            axios.get(baseAPI+'/api/event/get/'+r.data.id).then(r=>{
                //1console.log(r.data);
                //1console.log(r.data[0].handouts);

                setshowAgenda(r.data[0].activate_agenda)
                setShowGiveaways(r.data[0].activate_giveaway)
                setShowHandouts(r.data[0].activate_handout)
                setShowSpeakers(r.data[0].activate_speaker)

                setHero({name: r.data[0].title, desc: r.data[0].description, startDate: opDate.toISOString(),  logo: r.data[0].logo})
                if(Array.isArray(r.data[0].speakers))
                    setSpeakers(r.data[0].speakers.map((s)=>{return{name: s.name, imgUrl: s.picture, desc: s.bio}}))
                if(Array.isArray(r.data[0].agendas))
                    setAgendaItems(r.data[0].agendas.map((s)=>{console.log('AGENDA START AND END TIMES',s.start_time,s.end_time,s.start_time&&s.end_time&&(s.start_time!==''&&s.end_time!=='')); return{speakerName: s.speaker_name, title: s.topic, 
                        time: 
                            s.start_time&&s.end_time&&(s.start_time!==' '&&s.end_time!==' ')?
                            new Date(s.start_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})+'-'+new Date(s.end_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                            :''
                    }}))
                    
                // //1console.log(r.data[0].handouts);
                if(Array.isArray(r.data[0].handouts))
                    setHandouts(r.data[0].handouts.map(h=>{return{title: h.title, imgUrl: h.picture, file:h.file,  desc:h.description}}))
                if(Array.isArray(r.data[0].giveaways))
                    setGiveaways(r.data[0].giveaways)
            }).catch(e=>{
                //error
                //1console.log(e);
            })
            
            //get user id
            axios.post(baseAPI+'/api/user/virtualLogin', {phone: getUser()}).then(r=>{
                
                //1console.log(r.data);
                //get user address to be prefilled
                setCountry(r.data.country)
                setLoggedInUser(r.data.name)
                axios.get(baseAPI+'/api/user/address/get/'+r.data.id, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                    //1console.log(r);
                    setRetrievedAddress(r.data.address)
                    setRetrievedArea(r.data.area)
                    setRetrievedCity(r.data.city)
                })
            }).catch(e=>{
                //1console.log(e.response);
                if(e?.response?.status===401){
                    removeUserSession()
                    props.history.push('/login-acd')
                }
            })


        }).catch(e=>{
            //no event running
            //1console.log(e);
            if(e.response?.status===404){
                setNoEventRunning(true)
                // axios.get(baseAPI+'/api/event/checkUpcoming').then(r=>{
                //     setSplashText('Stay tuned for the upcoming event: ' + r.data.title + '. On ' + new Date(r.data.event_datetime).toLocaleDateString('en-GB'))
                // })
                
            }
                
        })
    }, [])
    useEffect(() => {
        
        setClosed(noEventRunning||!eventOpened)
    }, [noEventRunning,eventOpened])
    useEffect(() => {

        if(closed){
            document.body.style.overflow='hidden'
        }
        else{
            document.body.style.overflow='unset'
        }
        
        
    }, [closed])
    const [splashText, setSplashText] = useState('')
    function getUpcomingEventText(){
        axios.get(baseAPI+'/api/event/checkUpcoming').then(r=>{
            setSplashText('Stay tuned for the upcoming event: ' + r.data.title + '. On ' + new Date(r.data.event_datetime).toLocaleDateString('en-GB')) 
        }).catch(e=>{
            setSplashText('No event running. Please come back later.') 
        })
    }

    useEffect(() => {
        document.title = "L'Oréal Expo - Landing"
        getUpcomingEventText()
    }, [])

    async function saveClickAnalytics(btn, eventId){
        if(!activateAnalysis){
            props.history.push('/auditorium')
            return
        }
        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn}).then(r=>{
                props.history.push('/auditorium')
            }).catch(e=>{
                //1console.log(e);
                props.history.push('/auditorium')
            })
            
        }
            

        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId}).then(r=>{
                props.history.push('/auditorium')
            }).catch(e=>{
                //1console.log(e);
                props.history.push('/auditorium')
            })
    }
    return (
        <div>
            <div className='aud-no-event' style={{display: closed?'flex':'none', height:'100vh'}}>
                <img src={logo} />
                <p style={{display: splashText==='Loading...'?'none':'block'}}>{splashText}</p>
                <img src={loader} style={{display: splashText==='Loading...'?'block':'none', width: 'auto'}} />
                    
            </div>
            <header className='landing-c-header'>
                {loggedInUser===''?'':<p>Hi, {loggedInUser}</p>}
            </header>
            <Hero eventName={showEventName?hero.name:''} eventDescription={hero.desc} eventStartDate={hero.startDate} logoUrl={hero.logo}/>
        
            <Speakers speakers={speakers} showTitle={true} style={{display: showSpeakers?'flex':'none'}} />
            <Agenda agendaItems={agendaItems} showTitle={true} style={{display: showAgenda?'block':'none'}}/>
            <Handouts handouts={handouts} showTitle={true} style={{display: showHandouts?'block':'none'}}/>
            <Giveaways address={retrievedAddress} city={retrievedCity} area={retrievedArea} giveaways={giveaways} showTitle={true} style={{display: showGiveaways?'block':'none'}} country={country}/>

            <div className='row' style={{padding: '3% 0', background: '#F7F2EC'}}>
                <button className='landing-c-btn' style={{width: '300px', height:'80px', fontSize: '2rem'}} onClick={()=>{saveClickAnalytics('Join Auditorium - Landing', currentEventId)}}>Join Now</button>
            </div>
            <Footer />
            {/* <Slider /> */}

        </div>
    )
}

export default Landing
