
import React, { useState, useEffect } from 'react'
import { v4 } from 'uuid'
function DDL(props) {
    const [ddlItems, setDdlItems] = useState()
    useEffect(() => {
        setDdlItems(props.ddlItems?.map((item,i)=><option value={item} key={v4()} >{item}</option>))
        
    }, [props])
    
    return (
        <div className='text-field' style={props.style}>   
            <span className='label'style={props.labelStyle}>{props.name}</span>
            
            <select name={props.name} value={props.value} onChange={props.onChange} >
                <option value='' >Please Select</option>
                {ddlItems}
                
                
            </select>
        </div>
    )
}

export default DDL
