import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { getToken, removeUserSession,removeAdminId } from '../../Utils/Common'

import lorealLogo from '../../img-src/loreal logo.png';
import burgerIcon from '../../img-src/icons/menu-icon.png';
import AdminNav from './AdminNav';
import { fixfontsize } from '../helpers';
import PanelTable from '../Util/PanelTable';
import { adminMinWidth } from '../content';
import FilterBar from '../Fields/FilterBar'
import { baseAPI } from '../../config';
import questionIcon from '../../img-src/icons/question.png'
function AdminArchive(props) {
    const _MOCK_ARCHIVE_ = [{name: 'Annual Competition', link: 'https://www.youtube.com/'},{name: 'Annual Competition', link: 'https://www.youtube.com/'},{name: 'Annual Competition', link: 'https://www.youtube.com/'},{name: 'Annual Competition', link: 'https://www.youtube.com/'}]
    const [newEventName, setNewEventName] = useState('')
    const [newEventLink, setNewEventLink] = useState('')
    const [retrievedArchivedEvents, setRetrievedArchivedEvents] = useState()
    const [navExpanded, setNavExpanded] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const [totalVisits, setTotalVisits] = useState(0)
    const [avgEngagementTime, setAvgEngagementTime] = useState(0)
    const [eventViews, setEventViews] = useState(null)
    const [searchPhrase, setSearchPhrase] = useState('')
    const [archiveEvents, setArchiveEvents] = useState([])
    const [fromTime, setFromTime] = useState('')
    const [toTime, setToTime] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [dayFilter, setDayFilter] = useState('')
    const [pageLoad, setPageLoad] = useState(false)
    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }

    useEffect(() => {
        
        if(searchPhrase===''){
            setRetrievedArchivedEvents(archiveEvents)
        }
        else{
            
            // //1console.log(archiveEvents.filter(a=>a?.display_name?.toUpperCase().includes(searchPhrase.toUpperCase())));
            setRetrievedArchivedEvents(archiveEvents.filter(a=>a?.display_name?.toUpperCase().includes(searchPhrase.toUpperCase())))
        }
    }, [searchPhrase])
    function getArchivedEvents(){
        axios.get(baseAPI+'/api/archivedEvent/getAll', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            // //1console.log(r);
            if(!Array.isArray(r.data)){
                setRetrievedArchivedEvents([r.data])
                setArchiveEvents([r.data])
            }
                
            else{
                setRetrievedArchivedEvents(r.data)
                setArchiveEvents(r.data)
            }
                
        }).catch(e=>{
            //1console.log(e);
        })
    }
    function onDelete(id){
        axios.delete(baseAPI+'/api/archivedEvent/delete/'+id,{headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            // //1console.log(r);
            getArchivedEvents()
        }).catch(e=>{
            //1console.log(e);
        })
    }
    function onSubmit(){
        
        setNewEventLink('')
        setNewEventName('')
        axios.post(baseAPI+'/api/archivedEvent/create', {display_name: newEventName, link: newEventLink}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            // //1console.log(r);
            getArchivedEvents()
        }).catch(e=>{
            //1console.log(e);
        })
    }
    
    function getAnalysis(){
        axios.post(baseAPI+'/api/analysis/archived/getAnalysis', '', {headers: {'Authorization': 'bearer '+ getToken()}}).then(r=>{
            // //1console.log(r);
            setTotalVisits(r.data?.total_visits)
            setAvgEngagementTime(r.data?.average_engagement_time?r.data?.average_engagement_time:0)
            setEventViews(r.data?.events)
        }).catch(e=>{
            //1console.log(e);
        })
    }

    useEffect(() => {
        document.title = "L'Oréal Expo - Archive Analytics"
        getArchivedEvents()
        getAnalysis()
        setPageLoad(true)
    }, [])
    const rootStyles={
        display: 'flex', flexDirection:'column', 
        justifyContent:'center', 
        alignItems: 'center',
        margin: '5% 0'
    }
    useEffect(() => {

        

        //1console.log(dayFilter);
        let d = dayFilter
        let todayDate = new Date().toLocaleString('fr-CA').split(',')[0]

        let fTime = ''
        if(fromTime==='')
            fTime = '00:00'
        else
            fTime = fromTime

        let tTime = ''
        if(toTime==='')
            tTime = '23:59'
        else
            tTime = toTime

        let fromday = ''
        let today = ''
        if(fromDate==='')
            fromday = new Date(null).toLocaleString('fr-CA').split(',')[0]
        else
            fromday = fromDate
        if(todayDate==='')
            today = new Date().toLocaleString('fr-CA').split(',')[0]
        else
            today = fromDate
        



       

        let filterPayload = {
            "start_date": fromday + ' '+ fTime,
            "end_date": today + ' '+ tTime
        }

        // let dateObjFrom = new Date(fromday + ' '+ fTime)
        // let dateObjTo = new Date(fromday + ' '+ fTime)
        if(pageLoad){
            axios.get(baseAPI+'/api/archivedEvent/getAll',filterPayload, {headers:{'Authorization': 'bearer '+getToken()}}).then(r=>{
                //1console.log(r);
                if(!Array.isArray(r.data)){
                    setRetrievedArchivedEvents([r.data])
                    setArchiveEvents([r.data])
                }
                    
                else{
                    setRetrievedArchivedEvents(r.data)
                    setArchiveEvents(r.data)
                }

                }).catch(e=>{
                    // //1console.log('hhhh');
                    //1console.log(e);
                    
                   
                })
        }
        
    }, [fromDate,toDate,fromTime,toTime])
    return (


        <div style={{minWidth: adminMinWidth+'px'}}>
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                    <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%'}}>
                <AdminNav style={{width: navExpanded?'16%':0}} active={2}/>

                <div style={{width: navExpanded?'84%':'100%', transition: '0.3s'}}>
                    
                    <div style={rootStyles} id='archive'>
                        <h3 className='p-title' style={{textAlign: 'center'}}>Archive</h3>

                                        {/* search bar */}
                                        <FilterBar fromDay={fromDate} onChangeFromDay={e=>setFromDate(e.target.value)} ToDay={toDate} onChangeToDay={e=>setToDate(e.target.value)} FromTime={fromTime} onChangeFromTime={e=>setFromTime(e.target.value)} toTime={toTime} onChangeToTime={e=>setToTime(e.target.value)} />
                        <div className='row' style={{marginTop:'2%', width:'50%'}}>
                            <p style={{marginRight:'5%'}}>Search for event:</p>
                            <input type='text' value={searchPhrase} onChange={e=>setSearchPhrase(e.target.value)} placeholder='Search by event name' style={{padding:'10px'}} />
                        </div>
                        <div className='a-table' style={{height: '100%', overflow: 'hidden'}}>
                                            
                                <div className='a-t-tb' >
                                <ul className='a-t-th' style={{backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 0.5fr'}}>
                                    <li style={{color: '#155473'}}>Event Display Name</li>
                                    <li style={{color: '#155473'}}>Event Link</li>
                                    <li></li>
                                    
                                    
                                </ul>
                                    {
                                        
                                        retrievedArchivedEvents?.map((s,i)=>
                                            <ul className='a-t-tr' key={'a-t-cell-'+i} style={{gridTemplateColumns: '1fr 1fr 0.5fr', gridColumnGap: 0}}>
                                                <li>{s.display_name}</li>
                                                <li>{s.link}</li>
                                                <li><button className='warning-btn' style={{padding:0}} onClick={()=>onDelete(s.id)}>Remove</button>
                                                </li>
                                                
                                            </ul>  
                                        )
                                        
                                    }
                                </div>
                                <div style={{display: 'grid',gridTemplateColumns: '1fr 1fr 0.5fr', padding: '15px'}}>
                                    <input style={{width: '80%'}} value={newEventName} onChange={e=>setNewEventName(e.target.value)} placeholder='Event Name'/>
                                    <input style={{width: '80%'}} value={newEventLink} onChange={e=>setNewEventLink(e.target.value)} placeholder='Event Link'/>
                                    <button className='primary-btn' style={{padding:0, width: '60%'}} onClick={onSubmit}>Add Event</button>
                                </div>
                            </div>
                    
                        
                        
                        <div className='row' style={{marginBottom: '5%', width: '100%', height: '400px', padding: '2% 10%', boxSizing: 'border-box', justifyContent: 'flex-start'}}>
                            <div className='panel-style' style={{margin:0, width: '70%', height: '100%', backgroundColor: '#F7F2EC', justifyContent: 'flex-start'}}>
                                <h4 className='panel-title' style={{marginBottom:0}}>Archiving Room Analytics</h4>

                                <div className='a-analysis' style={{width: '100%', display: 'flex', justifyContent: 'center', height: '70%'}}>
                                    <div className='row' style={{width: '100%', display: 'flex', justifyContent: 'center', height: '100%'}}>
                                    <div className='panel-row' style={{display: 'flex',justifyContent: 'space-around', height: '100%', width: '100%'}}>
                                        <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', height: '100%', marginBottom:'5%'}}>
                                            <p>Total Visits</p>
                                            <div className='circle-num-data' style={{fontSize: fixfontsize(95,totalVisits), width: '8rem', height: '8rem'}}>{totalVisits}</div>
                                        </div>
                                        <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', height: '100%', marginBottom:'5%'}}>
                                            <p>Average Engagement Time</p>
                                            <div className='circle-num-text' style={{height: '8rem', width: '8rem'}}><p style={{fontSize: fixfontsize(85, 100)}}>{avgEngagementTime===null?0:parseInt(avgEngagementTime)/60>=60?(parseInt(avgEngagementTime)/3600).toFixed(2):(parseInt(avgEngagementTime)/60).toFixed(2) }</p><p>{parseInt(avgEngagementTime)/60>=60?'Hour(s)':'Minute(s)'}</p></div>
                
                                        </div>
                                        
                                    </div>
                                    </div>
                                </div>
                            
                            
                            </div>
                            {/* PAST EVENTS BUTTON CLICKS */}
                            <PanelTable title='Most Viewed Events' headers={['Event', 'Views']} data={eventViews?.map(b=>{return{d1:b.display_name,d2:b.views}})} style={{width: '30%', height: '100%', padding: '15px', justifyContent: 'flex-start'}}/> 
                            
                        </div>
                    
                    
                    
                    </div>
                </div>

            </div>
            
        </div>
        
    
    
    )
}

export default AdminArchive
