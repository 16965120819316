import React, {useState, useEffect, useCallback } from 'react';
import logo from '../img-src/loreal-expo-logo.png';
import dercosLogo from '../img-src/dercos-logo.png';
import larocheLogo from '../img-src/laroche-logo.png';
import vichyLogo from '../img-src/vichy-logo.png';
import ceraveLogo from '../img-src/cerave-logo.jpg'
import {countryList} from './countries';
import {regions, egyptCities} from './content';
import DDL from './Fields/DDL';
import Input from './Fields/Input';
import {setUserSession, getToken, setdummyId} from '../Utils/Common';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'; 
import axios from 'axios';
import { baseAPI } from '../config';
import ConsentForm from './Util/ConsentForm';
function Register(props) {
    const [countries, setCountries] = useState('');
    const [fullName, setFullName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const [regionsDDL, setRegionsDDL] = useState([]);
    const [isEgypt, setIsEgypt] = useState(true);
    const [first, setFirst] = useState(true);
    const [user, setUser] = useState('')
    const [city, setCity] = useState('')
    const [area, setArea] = useState('')
    const [address, setAddress] = useState('')
    const [dummyId, setDummyId] = useState('')
    const [switchPage, setSwitchPage] = useState(false)

    const enterFunction = useCallback((event) => {
        
        if(event.keyCode === 13 ) {
           
          
          if(document.querySelector('.part1').style.display==='none'){
            document.querySelector('.next2').click()
          }
          
          if(document.querySelector('.part2').style.display==='none'){
            document.querySelector('.next1').click()
          }
        //   document.querySelectorAll('.next').forEach(i=>i.click())
        }
      }, []);

    
    useEffect(() => {
        //set countries to drop down
        
        setCountries(countryList.map((c,i)=><option value={c} key={i}>{c}</option>))
        setRegionsDDL(regions.map((c,i)=><option value={c} key={i}>{c}</option>))
        
        
        window.addEventListener("keyup", enterFunction);

        
        
    }, []);
    
    useEffect(() => {
        
       ////1console.log(fullName);
    }, [fullName])
   
    useEffect(() => {
        checkIfEgypt()
    }, [country])
    
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function validateMobile(num){
        // const re = /^01[0-9]\d{8}$/;
        // return re.test(num)
        const re = /^(?:(\+))?(?:[0-9]{0,3}[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{4}\)))?(\d{4})(?:(?!\(\d{4})\))?[\s.\/-]?)?$/
        return re.test(num)
    }
    
    function checkIfEgypt(){
        
        if(country === 'Egypt' || first){
            setRegionsDDL(regions.map((c,i)=><option value={c} key={i}>{c}</option>))
            setFirst(false)

        }
        else{
            setRegionsDDL(<option value='Other' >Other</option>)
            setFirst(false)
        }
    }



    function validateOnNext(){
        setError('')
        if(fullName===''||mobile===''||email===''||region===''||country===''||!validateEmail(email)){
            let err = 'Please enter your '
            if(fullName==='')err+='full name, '
            // if(mobile===''||!validateMobile(mobile))err+='mobile, '
            if(mobile==='')err+='mobile, '
            if(email===''||!validateEmail(email))err+='email, '
            if(region==='')err+='clinical region, '
            if(country==='')err+='country, '
            
            err = err.slice(0,err.length-2)
            err+='.'
            setError(err)
            
            return false
        }
        else{
            setSwitchPage(2)
            return true
        }
    }
    const [showConsentForm, setShowConsentForm] = useState(false)
    function acceptConsentForm(){
        //onsubmit to create user first then call api for consent
            // ... call api with number
            onSubmit()
            axios.post(baseAPI+'/api/user/consent', {'phone':mobile},{headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            }).catch(e=>{
                setError('An error occurred while updating your info, please try again')
            })
    }
    function onSubmit(){
        setShowConsentForm(false)
        setLoading('Loading...')
        setError('')
        //check if user exists
        if(fullName===''||mobile===''||email===''||region===''||country===''||city===''||area===''||address===''){
            setLoading('')
            let err = 'Please enter your '
            if(fullName==='')err+='full name, '
            if(mobile==='')err+='mobile, '
            if(email==='')err+='email, '
            if(region==='')err+='clinical region, '
            if(country==='')err+='country, '
            if(area==='')err+='area, '
            if(city==='')err+='city, '
            if(address==='')err+='address, '
            err = err.slice(0,err.length-2)
            err+='.'
            setError(err)
            
            return
        }
        if(!validateEmail(email)){
            setLoading('')
            setError('Please enter a valid email.')
            

            return
        }
        saveClickAnalytics('Next - Register',null)
        axios.post(baseAPI+'/api/user/virtualLogin', {phone: mobile}).then(r=>{
            setLoading('');
            //1console.log(r);
            setDummyId(r.data.dummy_id)
            //setUserSession('token','user')
            // setUserSession(r.data.token.token, mobile);
            setError('You are already registered, please login.')
        }).catch(e=>{
            setLoading('');
            if(e.response?.status===404){
                //if user doesnt exist create a new user
                axios.post(baseAPI+'/api/user/create', {name: fullName, phone: mobile, clinical_region: region, country: country, email: email}).then(r=>{
                    //1console.log(r);
                    //get user id from newly created user
                    
                        
                        //create address for newly created user
                        axios.post(baseAPI+'/api/user/address/create', {user_id: r.data.id, address: address, city:city, area:area}).then(r=>{
                            //1console.log(r.data);
                            
                            setError('')
                            setUserSession('',mobile)
                            axios.post(baseAPI+'/api/user/virtualLogin', {phone: mobile}).then(r=>{
                                setdummyId(r.data.dummy_id)

                                setTimeout(() => {
                                    axios.post(baseAPI+'/api/user/setEntry', {dummy_id: r.data.dummy_id}).then(r=>{
                                        setLoading('')
                                        //1console.log(r);
                                        
                                        props.history.push('/tour')
                                    }).catch(e=>{
                                        //1console.log(e);
                                        setError('An error occurred. Please try again.')
                                    })
                                }, 1000);

                            }).catch(e=>{
                                setError('Account created. Error occurred while logging in. Please go to login.')
                            })
                            
                            
                        }).catch(e=>{
                            //1console.log(e);
                            setError('An error occurred while registering. Please try again. (3)')

                        })
                    
                    
            
                }).catch(e=>{
                    setLoading('')
                    //1console.log(e.response);
                    setError('An error occurred while registering. Please try again. (2)')

                    if(Array.isArray(e?.response?.data?.errors)){
                        if(e?.response?.data?.errors[0]?.message==='mobile validation failed')
                            setError('Please enter a valid mobile number.')
                    }
                    
                    if(e?.response?.data?.message?.includes('value too long'))
                        setError('Maximum characters allowed are 250.')
                    
                        
                })
            }
            else
                setError('An error occurred while registering. Please try again. (1)')
            
            //1console.log(e.response);
        })
        
    }
    async function saveClickAnalytics(btn, eventId){
        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn})
            
        }
            

        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId})
    }

    function saveClickAnalyticsNA(btn, eventId){
        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn})
            
        }
            

        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId})
    }

    
    return (
        <div className='split-form'>
            
            <div className='splash'>
                <img src={logo} className='logo' alt='loreal expo'/>
                <div className='sponsors-container'>
                <img src={dercosLogo}  alt='loreal expo' height='100%'/>
                <img src={larocheLogo}  alt='loreal expo' height='100%'/>
                <img src={vichyLogo}  alt='loreal expo' height='100%'/>
                <img src={ceraveLogo}  alt='loreal expo' height='100%'/>
                </div>
            </div>

            
            <ConsentForm 
                show={showConsentForm}
                onClose={()=>setShowConsentForm(false)}
                onAgree={()=>{saveClickAnalytics('Accept consent form - Register',null).then(acceptConsentForm()); }}
            />
            <div className='form part1' style={{display: !switchPage?'flex':'none', overflow:'auto'}}>
                <img src={logo} className='logo' alt='loreal expo'/>
                <h1>REGISTRATION</h1>

                {/* <h2 style={{display: 'inline-block', wordBreak: 'normal', textAlign:'center', padding: '10px', marginTop: '200px', marginBottom: '0px' ,color: '#AA0000'}}>Registration is currently unavailable</h2> */}
                {/* <h2 style={{textAlign:'center', wordBreak: 'normal', padding: '10px', marginTop: '0px', color: '#AA0000'}}>Please wait for our next event to register</h2> */}
                
                
                {/* -------------------------------------------------------------------------------------*/}
                 <div className='text-field'>
                    <span className='label'>Full Name</span>
                    <input type='text' value={fullName} onChange={e=>setFullName(e.target.value)} placeholder='Enter Your Full Name' />
                    
                </div>
                <p className='info'>*Required</p>
                {/*-------------------------------------------------------------------------------------*/}
                 <div className='text-field'>
                    <span className='label'>Contact Number</span>
                    <input type='text' name="phone" value={mobile} onChange={e=>setMobile(e.target.value)} placeholder='Enter Your Contact Number' />
                    
                </div>
                <p className='info'>*Required (Using English Numbers)</p> 
                {/*-------------------------------------------------------------------------------------*/}
                 <div className='text-field'>
                    <span className='label'>E-Mail</span>
                    <input type='email' value={email} onChange={e=>setEmail(e.target.value)} placeholder='Enter Your E-Mail' />
                    
                </div>
                <p className='info'>*Required</p> 
                {/*-------------------------------------------------------------------------------------*/}
                 <div className='text-field'>
                    <span className='label'>Country</span>
                    <CountryDropdown
                        value={country}
                        onChange={(val) => setCountry(val)} />
                    
                </div>
                <p className='info'>*Required</p> 
                {/*-------------------------------------------------------------------------------------*/}
                 <div className='text-field'>
                    <span className='label'>Clinic Region</span>
                    
                    <select name='clinical region' value={region} onChange={e=>setRegion(e.target.value)} >
                        <option value='' >Please Select</option>
                        
                        {regionsDDL}
                    </select>
                </div>
                <p className='info'>*Required</p> 
                {/*-------------------------------------------------------------------------------------*/}
                 <a href='/login'>I'm already registered</a>
                <button className='cta-btn-blue next1' onClick={()=>{saveClickAnalytics('Next To Address - Register',null).then(validateOnNext()) }} >Next</button>
                
                <p className='error' style={{width: '80%'}}>{error}</p>
                <p className='loading'>{loading}</p>
                
            </div>
            
            <div className='form part2' style={{display: switchPage?'flex':'none'}}>
                <img src={logo} className='logo' alt='loreal expo'/>
                <h1>REGISTRATION</h1>
                <p className='p-title'>Address</p>
                {/*-------------------------------------------------------------------------------------*/}
                <DDL name='City' value={city} ddlItems={egyptCities} onChange={e=>setCity(e.target.value)} />

                <div className='text-field'>
                    <span className='label'>City</span>
                    <RegionDropdown
                    country={country}
                    value={city}
                    onChange={(val) => setCity(val)} />
                    
                </div>
                
                <p className='info'>*Required</p>
                {/*-------------------------------------------------------------------------------------*/}
                <Input type='text' name='Area' value={area} onChange={e=>setArea(e.target.value)} /> 
                <p className='info'>*Required</p>
                {/*-------------------------------------------------------------------------------------*/}
                <Input type='text' name='Address' value={address} onChange={e=>setAddress(e.target.value)} />
                {/*-------------------------------------------------------------------------------------*/}
                <p className='info'>*Required</p>
                
                <a href='/login'>I'm already registered</a>
                <div className='row' style={{justifyContent: 'space-around', width: '80%'}}>
                    <button className='cta-btn-blue' onClick={()=>setSwitchPage(false)}>Previous</button>
                    <button className='cta-btn-blue next2' onClick={()=>validateOnNext()?setShowConsentForm(true):''}>Next</button>
                </div>
                
                
                <p className='error' style={{width: '80%'}}>{error}</p>
                <p className='loading'>{loading}</p>
                
            </div>
            
        </div>
    )
}

export default Register

