import React from 'react'
import DatePicker from 'react-datepicker'
function DateInput(props) {

    return (
        <div className='text-field'>
            <span className='label'>{props.name}</span>
            <DatePicker selected={props.value} onChange={props.onChange} dateFormat='dd/MMMM/yyyy' placeholderText={'Enter ' + props.name}/>
        </div>
    )
}

export default DateInput
