import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react'
import { getToken, getUser } from '../Utils/Common'
import LoadingBar from './Util/LoadingBar'

import loadingGif from '../img-src/icons/loader.gif'
import { baseAPI } from '../config'
function AuditoriumFeedbackForm(props) {
    // const _MOCK_QUESTIONS_ = [
    // {Question: 'Question 1', 'Question Type': 'Essay', Required: true, 'Min Characters': '5', 'Max Characters': '10', Choices:[''], 'Select Multiple':'', 'Write In Choice':'', useWriteInChoice: false}, 
    // {Question: 'Question 2', 'Question Type': 'Multiple Choice', Required: true, 'Min Characters': '', 'Max Characters': '', Choices:['Occaecat eu reprehenderit eiusmod minim pariatur ad.', 'Consequat eu esse laboris eu id sint aute nisi nulla ad magna amet esse dolor.', 'Choice 3'], 'Select Multiple':'', 'Write In Choice':'Write in Choice 4', useWriteInChoice: true},
    // {Question: 'Question 3', 'Question Type': 'Essay', Required: true, 'Min Characters': '1', 'Max Characters': '3', Choices:[''], 'Select Multiple':'', 'Write In Choice':'', useWriteInChoice: false}, 
    // {Question: 'Question 4', 'Question Type': 'Multiple Choice', Required: false, 'Min Characters': '', 'Max Characters': '', Choices:['Choice 1', 'Choice 2', 'Choice 3'], 'Select Multiple':'', 'Write In Choice':'Write in Choice 4', useWriteInChoice: true}
    // ]
    
    const [currentQ, setCurrentQ] = useState(0)
    const [allQs, setAllQs] = useState('')
    const [answers, setAnswers] = useState([])
    const [currentAnswer, setCurrentAnswer] = useState('')
    const [currentWriteInAnswer, setCurrentWriteInAnswer] = useState('')
    const [userId, setUserId] = useState('')
    const [error, setError] = useState('')
    const [runningEventId, setRunningEventId] = useState('')
    
    //request status
    const [loading, setLoading] = useState('')
    
    const textAreaAnswer = useRef(null)

    const writeInChoice = useRef(null)
    const [_MOCK_QUESTIONS_, set_MOCK_QUESTIONS_] = useState([''])
    useEffect(() => {
        //get current event id
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            setRunningEventId(r.data.id)
            axios.get(baseAPI+'/api/event/get/'+r.data.id).then(r=>{
                //1console.log(r.data[0]?.form?.feedback_questions);

                if(r.data[0]?.form===null){
                    set_MOCK_QUESTIONS_(null)
                }
                else
                    set_MOCK_QUESTIONS_(r.data[0]?.form?.feedback_questions)
            })

            axios.post(baseAPI+'/api/user/virtualLogin', {phone: getUser()}).then(r=>{
                setUserId(r.data.id)
                
            }).catch(e=>{
                //1console.log(e);
            })
        }).catch((error)=>{console.log(error)})
        
    }, [])


    function submitQuestion(){
        

        //if questions completed
        if(currentQ>allQs.length-1)
        {
            setLoading('Loading...')
            setError('')
            var temp=[];
            
            answers.forEach(a => {

                if(a.answer!=='')
                    if(a.comment==='')
                        temp.push({answer: a.answer, question_id: a.question_id, user_id: userId})
                    else
                        temp.push({answer: a.answer, comment: a.comment,question_id: a.question_id, user_id: userId})


            });
            //1console.log(temp);
            setAnswers(temp)

            
            


            axios.post(baseAPI+'/api/event/feedbackForm/createResponses', {event_id: runningEventId, responses: temp}, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
                //1console.log(r);
                setLoading('')
                props.history.push('/tour')
            }).catch(e=>{
                setLoading('')
                //1console.log(e.response);
                setError('An error occurred while submitting your responses. Please try again.')
            })
            return
        }
        //move to next question
        else{
            if(currentAnswer==='' && _MOCK_QUESTIONS_[currentQ]?.required)
            {
                setError('Please provide an answer for this question.')
                return
            }
            else
                setError('')
            

            var x = answers.slice();
            x[currentQ].answer = currentAnswer;
            x[currentQ].comment = currentWriteInAnswer;
            setAnswers(x);
            setCurrentQ(currentQ+1)
            
        }
            
        
        
        //clear selected answer highlight for ratings
        document?.querySelectorAll('.rating-option')?.forEach(i=>i.parentNode.style='width: 5rem;display: flex;flex-direction: column;align-items: center;justify-content: center;background: white;margin-right: 2%;position: relative;border-radius: 50%;')
        //clear multiple choice questions 
        document?.querySelectorAll('input[type=radio]:checked').forEach(i=>i.checked=false)
        //clear text answer textarea
        // if(document.querySelector('textarea'))
        //     document?.querySelector('textarea').value = '';
        if(textAreaAnswer.current?.value)
            textAreaAnswer.current.value=''
        setCurrentAnswer('')
        setCurrentWriteInAnswer('')
    }

    function onSetAnswer(c,i){

        var x = answers.slice()
        x[i] = c;
        setAnswers(x);
    }
    function onSetAnswerText(c,i){

        var x = answers.slice()
        x[i] = c;
        setAnswers(x);
    }
    
    const onTextChange = (e)=>{
        if(e.target.type==='radio' && document.querySelector('.feedback-write-in-choice-textbox')!==null){
            if(e.target.className==='feedback-write-in-choice' &&e.target.checked){
                document.querySelector('.feedback-write-in-choice-textbox').style.visibility = 'visible';
                document.querySelector('.feedback-write-in-choice-textbox').style.animation = 'slideOpenRight 0.3s 1';
            }
            else{
                document.querySelector('.feedback-write-in-choice-textbox').style.animation = 'slideCloseRight 0.3s 1';
                setTimeout(() => {
                    document.querySelector('.feedback-write-in-choice-textbox').style.visibility = 'hidden';
                }, 280);
                
                
                
            }
            
        }


        if(e.target.className==='rating-option'){
            //1console.log(e.target.style);
            document.querySelectorAll('.rating-option').forEach(i=>i.parentNode.style='width: 5rem;display: flex;flex-direction: column;align-items: center;justify-content: center;background: white;margin-right: 2%;position: relative;border-radius: 50%;')
            document.querySelectorAll('.rating-option')
            e.target.parentNode.style='width: 5rem;display: flex;flex-direction: column;align-items: center;justify-content: center;background: rgba(21, 84, 115, 0.6)!important;margin-right: 2%;position: relative;border-radius: 50%;color:white'
        }
       
            
        setCurrentAnswer(e.target.value)
    }
    

    useEffect(() => {
        
        // ratingCurAns.current.innerText=currentAnswer
        //1console.log(currentAnswer);
    }, [currentAnswer])
    useEffect(() => {
        //1console.log('answers array:');
        //1console.log(answers);
    }, [answers])

   useEffect(() => {
       
       //1console.log(_MOCK_QUESTIONS_);
   }, [_MOCK_QUESTIONS_])


   const ratingCurAns = useRef('')
   
    useEffect(() => {
        
        // //init selected answers
        

        if(_MOCK_QUESTIONS_)
            setAllQs(_MOCK_QUESTIONS_?.map((q,i)=>
                <div className='current-question-wrapper'>
                    <p>{'Q'+parseInt(i+1)  + '- ' + q.question}<span>{q.required?'*':''}</span></p>
                    <ul>
                        {q.type==='mcq'?
                            q.choices.map((c,ci)=>
                                c.comment?
                                    <li style={{width: '35%'}} key={ci}>
                                        <input type={'radio'} name={'Answer Choice Q'+(i+1)} value={c.content}  className='feedback-write-in-choice' onChange={(e)=>onTextChange(e)} style={{width: '25px', height: '25px'}} />
                                        <label style={{fontSize: '1.5em'}} >{c.content}</label>
                                        <input type='text' onChange={(e)=>setCurrentWriteInAnswer(e.target.value)} placeholder='Enter your answer.' className='feedback-write-in-choice-textbox' style={{padding: '10px', width: '100%', border: '1px solid #1B5473', visibility: 'hidden', display: 'block'}}/>  
                                    </li>
                                :
                                    
                                    <li key={ci} style={{width: '35%', marginBottom: '2%', display:'flex', alignItems:'center'}}><input type={'radio'} name={'Answer Choice Q'+(i+1)} value={c.content}  onChange={(e)=>onTextChange(e)} style={{width: '25px', height: '25px'}}/><label style={{fontSize: '1.5em'}} >{c.content}</label></li>

                            )
                            :
                            q.type==='rating'?
                                
                                <div style={{width:'100%'}}>
                                    <div className='row' style={{width: '100%'}}>

                                        {/* <input type='range' min={q.choices[0].content} max={q.choices[q.choices.length-1].content} name={'Answer Choice Q'+(i+1)} value={c.content}  className='rating-option' onChange={(e)=>onTextChange(e)}/> */}
                                        {
                                            q.choices.map((c,ci)=>

                                            <li style={{width: '100%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', background:'rgba(21, 84, 115, 0.6)', marginRight:'2%', position:'relative', borderRadius:'50%', width:'2rem', height:'5rem', width:'5rem'}} key={ci} className='rating-ans-container'>
                                                <input type={'radio'} name={'Answer Choice Q'+(i+1)} value={c.content}  className='rating-option' onChange={(e)=>onTextChange(e)} style={{position:'absolute', opacity:0, pointerEvents:'', width:'100%', height:'100%'}} />
                                                <label style={{fontSize: '1.5em', textAlign:'center'}} >{c.content}</label>
                                                
                                            </li>
                                        )
                                        }

                                        
                                    </div>  
                                    <div className='row' style={{width:'100%', justifyContent:'space-between'}}>
                                            <p>Very Bad</p>

                                            <p>Excellent</p>
                                    </div>  
                                </div>
                                 
                                
                                
                            :
                            <textarea ref={textAreaAnswer} placeholder='Enter answer' name={'Answer'}  onChange={(e)=>onTextChange(e)}/>
                        }
                        
                    </ul>
                    {/* <p ref={ratingCurAns}></p> */}
                </div>
            
            ))

        

        setCurrentQ(0)
        


        
    }, [_MOCK_QUESTIONS_])  
    
    useEffect(() => {
        var tempAns = [];
        for (let i = 0; i < allQs.length; i++) {
            tempAns.push({
                "answer":"",
                "comment":"",
                "question_id":_MOCK_QUESTIONS_[i].id,
                "user_id": userId})
            
        }
        setAnswers(tempAns);
        //1console.log(_MOCK_QUESTIONS_);
    }, [allQs])
    useEffect(() => {
        //1console.log(answers);
        
    }, [answers])
    return (
        _MOCK_QUESTIONS_===null?
        <div className='feedbackform-wrapper' style={props.style}>
            <h2 className='alt-title' style={{fontSize: '2rem'}}>No feedback questions found</h2>
            <button className='primary-btn' style={{fontSize: '2rem', padding: '0.8rem 5rem'}} onClick={()=>props.history.push('/tour')}>{ 'Next'}</button>
            
        </div>

        :
        <div className='feedbackform-wrapper' style={props.style}>
            <h2 className='alt-title' style={{fontSize: '2rem'}}>Feedback Form</h2>
            <LoadingBar status={(((currentQ)/allQs.length)*100)+'%'} style={{width: '80%'}} />
            {
            currentQ>allQs.length-1?
                loading==='Loading...'?
                <img src={loadingGif} width='60px'style={{margin: '10% 0'}}/>
                :
                <p className='p-title' style={{height: '60%', display: 'flex', alignItems: 'center', fontSize: '3.5rem', textAlign: 'center'}}>{error===''?'Thank You!':''}</p>
                
            :
            allQs[currentQ]
            }
            <p className='error' style={{backgroundColor: '#D87152', padding: '5px', color: 'white'}}>{error}</p>
            <button className='primary-btn' style={{fontSize: '2rem', padding: '0.8rem 5rem'}} onClick={()=>submitQuestion()}>{ currentQ>allQs.length-1?'Submit':'Next'}</button>
            
        </div>

        // <div className='feedbackform-wrapper' style={{...props.style, height:'100%'}} >
        //     <iframe src='https://form.typeform.com/to/oQoJD0zz' style={{width: '100%', height: '100%', border: 'none'}} />
        // </div>
    )
}

export default AuditoriumFeedbackForm




