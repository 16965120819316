import React, { useState, useEffect } from 'react'
import downloadicon from '../../img-src/icons/download.png'
import calendaricon from '../../img-src/icons/calendar.png'
import formicon from '../../img-src/icons/form.png'
import arrowicon from '../../img-src/icons/right-arrow.png'
import questionIcon from '../../img-src/icons/question.png'
import searchicon from '../../img-src/icons/search.png'
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import axios from 'axios'

import { adminMinWidth, regions } from '../content'

import lorealLogo from '../../img-src/loreal logo.png';
import burgerIcon from '../../img-src/icons/menu-icon.png';
import AdminNav from './AdminNav';

import { getToken, removeAdminId, removeUserSession  } from '../../Utils/Common';
import { fixfontsize } from '../helpers';

import PanelTable from '../Util/PanelTable';

import calendarIcon from '../../img-src/icons/calendar.png'
import clockIcon from '../../img-src/icons/clock.png'
import { v4 } from 'uuid'
import FilterBar from '../Fields/FilterBar'
import DDL from '../Fields/DDL'
import { countryList } from '../countries'
import { baseAPI } from '../../config'
function AdminRegistrationForms(props) {
    
    // const data = [{month: 'January', value: 500},{month: 'Febuary', value: 450},{month: 'March', value: 380},{month: 'April', value: 600},{month: 'May', value: 1200},{month: 'June', value: 300},{month: 'July', value: 200},{month: 'August', value: 50},{month: 'September', value: 150}, {month: 'October', value: 300},{month: 'November', value: 400},{month: 'December', value: 500}];
    const [sortByDate, setSortByDate] = useState(false)
    const [dateFilter, setDateFilter] = useState('')
    const [_MOCK_USERS_, set_MOCK_USERS_] = useState([])
    const [tableElements, setTableElements] = useState()
    
    const [filterTime, setFilterTime] = useState(false)
    const [monthSwitcherIndex, setMonthSwitcherIndex] = useState(0)
    const [playanim, setPlayanim] = useState(false)

    const [navExpanded, setNavExpanded] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const [feedBackFormResponses, setFeedBackFormResponses] = useState(null)
    const [feedBackFormResponsesEls, setFeedBackFormResponsesEls] = useState(null)

    const [totalRegistrants, setTotalRegistrants] = useState(0)
    const [data, setData] = useState([])

    //proccessed form responses into readable format
    const [procFormResponses, setProcFormResponses] = useState(null)
    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }

    //filter selected values
    const [useSelectedTime, setUseSelectedTime] = useState(false)
    const [selectedFromTime, setSelectedFromTime] = useState(null)
    const [selectedToTime, setSelectedToTime] = useState(null)
    const [selectedDay, setSelectedDay] = useState(null)
    const [useSelectedDay, setUseSelectedDay] = useState(false)
    const [useSearch, setUseSearch] = useState(false)
    const [selectedSearch, setSelectedSearch] = useState(null)

    const [fromTime, setFromTime] = useState('')
    const [toTime, setToTime] = useState('')
    const [fromDay, setFromDay] = useState('')
    const [toDay, setToDay] = useState('')

    const [regForms, setRegForms] = useState([])
    const [allRegForms, setallRegForms] = useState([])
    const [timeFilteredRegForms, setTimeFilteredRegForms] = useState([])

    const [pastEvents, setPastEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState('')

    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedClinicalRegion, setSelectedClinicalRegion] = useState('')
    useEffect(() => {
        document.title = "L'Oréal Expo - Registration Forms"
        axios.get(baseAPI+'/api/event/past', {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data);
            setPastEvents(r.data)
        }).catch(e=>{
            //1console.log(e);
        })
    }, [])

    useEffect(() => {
        
        getFeedbackFormResponses()
    }, [selectedEvent])
    function onSubmit(){
        
    
    }
    useEffect(() => {
        
        
        setPlayanim(true)
        setTimeout(() => {
            setPlayanim(false)
        }, 1000);
    }, [monthSwitcherIndex])

    function refreshRegForms(){
        axios.post(baseAPI+'/api/analysis/registration/forms?page=1&limit=1000', {
           
        }, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r);
            setRegForms(r.data)
            setallRegForms(r.data)
            setTimeFilteredRegForms(r.data)
            setTotalRegistrants(r.data.length)

            //generate graph
            setData(
                [
                    {month: 'January', value: r.data.filter(a=>a.created_at.split('-')[1]==='01').length},
                    {month: 'Febuary', value: r.data.filter(a=>a.created_at.split('-')[1]==='02').length},
                    {month: 'March', value: r.data.filter(a=>a.created_at.split('-')[1]==='03').length},
                    {month: 'April', value: r.data.filter(a=>a.created_at.split('-')[1]==='04').length},
                    {month: 'May', value: r.data.filter(a=>a.created_at.split('-')[1]==='05').length},
                    {month: 'June', value: r.data.filter(a=>a.created_at.split('-')[1]==='06').length},
                    {month: 'July', value: r.data.filter(a=>a.created_at.split('-')[1]==='07').length},
                    {month: 'August', value: r.data.filter(a=>a.created_at.split('-')[1]==='08').length},
                    {month: 'September', value: r.data.filter(a=>a.created_at.split('-')[1]==='09').length}, 
                    {month: 'October', value: r.data.filter(a=>a.created_at.split('-')[1]==='10').length},
                    {month: 'November', value: r.data.filter(a=>a.created_at.split('-')[1]==='11').length},
                    {month: 'December', value: r.data.filter(a=>a.created_at.split('-')[1]==='12').length}
                ]
            )

        }).catch(e=>{
            //1console.log(e);
        })
    }
    useEffect(() => {


        refreshRegForms()
        
        setTableElements(_MOCK_USERS_.map((s,i)=>
            <ul className='a-t-tr' key={'a-t-cell-'+i} style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%'}}>
                <li>{s.name} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s.date}</p></li>
                <li>{s.number}</li>
                <li>{s.email}</li>
                <li>{s.country}</li>
                <li>{s.clinicalRegion}</li>
                <li>{s.address}</li>
                <li>{s.timeOfRegistration}</li>
                
                
                
            </ul>  
        ))
        document.querySelector('.recharts-surface').setAttribute('viewBox', '10 0 600 300')
    }, [])
    useEffect(() => {
        
       if(sortByDate){
            var x = _MOCK_USERS_.slice()

            x.sort((a,b)=>{return new Date(a.timeOfRegistration)-new Date(b.timeOfRegistration)})
            //1console.log(x);
            set_MOCK_USERS_(x);
       }
    }, [sortByDate])
    useEffect(() => {
        
        setTableElements(_MOCK_USERS_.map((s,i)=>
            <ul className='a-t-tr' key={'a-t-cell-'+i} style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%'}}>
                <li>{s.name} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s.date}</p></li>
                <li>{s.number}</li>
                <li>{s.email}</li>
                <li>{s.country}</li>
                <li>{s.clinicalRegion}</li>
                <li>{s.address}</li>
                <li>{s.timeOfRegistration}</li>
                
                
                
            </ul>  
        ))
    }, [_MOCK_USERS_])
    const rootStyles={
        display: 'flex', flexDirection:'column', 
        justifyContent:'center', 
        alignItems: 'center',
        margin: '5% 0'
    }

    function onDownloadUserData(){
        let csv = ''
        var items = regForms?.slice();
        // Loop the array of objects
        items = items?.map(a=>{return{
            id:a.id,
            phone:a.phone,
            name:a.name,
            email:a.email,
            clinical_region:a.clinical_region,
            country:a.country,
            created_at:a.created_at,
            address:a.address,
            area:a.area,
            city:a.city
        }})
        for(let row = 0; row < items.length; row++){
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if(row === 0){

            // Loop each property of the object
                for(let key in items[row]){

                                    // This is to not add a comma at the last cell
                                    // The '\r\n' adds a new line
                                    //1console.log(key);
                    // if(key==='password'||key==='remember_me_token'||key==='dummy_id'||key==='user_id')
                    //     continue
                    
                    csv += key.charAt(0).toUpperCase()+key.substr(1,key.length-1) + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                    keysCounter++
                }
            }
            else{
                for(let key in items[row]){
                    //1console.log((items[row][key])?.toString().replace(/,/g,' '));
                    // if(key==='password'||key==='remember_me_token'||key==='dummy_id'||key==='user_id')
                    //     continue
                    // //1console.log((items[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )).replace(/,/g,'-'));
                    csv += (items[row][key])?.toString().replace(/,/g,' ') + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                    keysCounter++
                }
            }

            keysCounter = 0
        }

        //  download the .csv by creating a link
        let link = document.createElement('a')
// //1console.log(csv);
        let rand = v4()
        //1console.log('rand', rand);
        link.id = 'download-csv-user-data-'+rand
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', 'userdata.csv');
        document.body.appendChild(link)
        document.querySelector('#download-csv-user-data-'+rand).click()
    }
    


    




    //---------------------------------------FEEDBACKFORM RESPONSES TABLE------------------------------------
    function getFeedbackFormResponses(){
        axios.get(baseAPI+'/api/analysis/feedbackFormResponses/'+selectedEvent, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data);
            setFeedBackFormResponses(r.data)
            



        }).catch(e=>{
            //1console.log(e);
        })
    }
    useEffect(() => {
        
        getFeedbackFormResponses()
    }, [])

    //generate feedback form responses elements when they are retrieved
    useEffect(() => {

        
        let rows = []
        var rowsObj = []

        let questionsLookup = feedBackFormResponses?.feedback_questions
        //1console.log(questionsLookup);
        //1console.log(feedBackFormResponses?.user_responses);
        let qs = questionsLookup
        let res = feedBackFormResponses?.user_responses

        //make array of objects that includes question name and answers
        // let mergedQuestionsAndAnswers = y?.user_responses?.map(a=>{return{
        //     ...a,
        //     questionName:x.filter(b=>b.id===a.question_id)[0]?.question
        // }})
        try{
            for(let i=0; i<=res?.length-1;i++){
                //each response for user i
                for(let j=0; j<=res[i].responses?.length-1;j++){
                    res[i].responses[j] = { ...res[i].responses[j], questionName:qs.filter(q=>q.id===res[i].responses[j].question_id)[0]?.question}
                }
                
            }
        }
        catch{

        }
        
        //1console.log(res);
        var header = <ul className='row' style={{marginBottom:'2%', justifyContent:'flex-start', marginBottom:'1%'}}>
                        <li style={{flex: '0 0 20%', fontWeight:'bold', color:'#155473'}} >{'Date'}</li>

                        <li style={{flex: '0 0 20%', fontWeight:'bold', color:'#155473'}}>{'Time'}</li>
                        
                        {
                            feedBackFormResponses?.feedback_questions?.map(x=>
                                <li style={{flex: '0 0 20%', fontWeight:'bold', color:'#155473'}}>{x.question}</li> 
                            )
                        }
                    </ul>

        //1console.log('feedBackFormResponses', feedBackFormResponses);
       
            

            

            //1console.log('rowsobj set');
            //1console.log(rowsObj);
            setProcFormResponses(rowsObj)
            
            //map converted responses into jsx elements
           
            //1console.log('%cMeged Qs and As','font-size:30px;color:blue',res);
            rows = res?.map((q,i)=>
                <ul className='row' key={'feedbackform-responses-item-'+i} style={{borderBottom:'1px solid rgba(0,0,0,0.3)', justifyContent:'flex-start', marginBottom:'1%'}}>
                {/* Question */}
                
                {/* date */}
                {/* <li style={{flex: '0 0 20%'}}>{q?.created_at?.split('T')[0]}</li> */}
                {/* Time */}
                <li style={{flex: '0 0 20%'}}>{q?.created_at.split('T')[0]}</li>
                <li style={{flex: '0 0 20%'}}>{q?.created_at.split('T')[1].split('.')[0]}</li>
                {/* Answer */}
                    {/*                 
                    q?.responses?.map(r=>
                        <li style={{flex: '0 0 20%'}}>{r?.answer}</li>    
                    ) */}
                
                {
                    
                    feedBackFormResponses?.feedback_questions?.map(x=>
                        <li style={{flex: '0 0 20%'}}>{q?.responses.find(k=>k.question_id===x.id)?.answer}</li> 
                    )
                }
                
                </ul>
                
                
            )
            
            setFeedBackFormResponsesEls(
                <div className='' style={{width: '80%', backgroundColor: '#F7F2EC', padding: '15px', boxSizing: 'border-box', overflowX: 'auto', height:'500px'}}>
                    {header}
                    {rows}
                </div>
            )
        
        
    }, [feedBackFormResponses])


    
    
    function sortResponsesByTime(attr){
       
        var sortedResponses = procFormResponses
        
        sortedResponses.sort(function(a,b){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            if ( a[attr] < b[attr]){
                return -1;
              }
              if ( a[attr] > b[attr] ){
                return 1;
              }
              return 0;
          });

          
        setProcFormResponses(sortedResponses)


        var header = <ul className='row'>
                <li style={{flex: '0 0 20%'}} >{'Question'}</li>

                <li style={{flex: '0 0 20%'}}>{'Date'}</li>
                
                <li style={{flex: '0 0 20%'}}>{'Time'}</li>
                
                <li style={{flex: '0 0 20%'}}>{'Answer'}</li>
                
                <li style={{flex: '0 0 20%'}}>{'Comment (Write In Choice)'}</li>
            </ul>
            var rows = [].concat.apply([], sortedResponses).map((q,i)=>
                    
                    
                        
                <ul className='row' key={'feedbackform-responses-item-'+i}>
                        {/* Question */}
                        <li style={{flex: '0 0 20%'}}>{q?.question}</li>
                        {/* date */}
                        <li style={{flex: '0 0 20%'}}>{q?.date}</li>
                        {/* Time */}
                        <li style={{flex: '0 0 20%'}}>{q?.time}</li>
                        {/* Answer */}
                        <li style={{flex: '0 0 20%'}}>{q?.answer}</li>
                        {/* Comment */}
                        <li style={{flex: '0 0 20%'}}>{q?.comment}</li>
                </ul>
                

            )
            setFeedBackFormResponsesEls(
                <div className='' style={{width: '80%', backgroundColor: '#F7F2EC', padding: '15px', boxSizing: 'border-box', overflowX: 'auto', height:'500px'}}>
                    {header}
                    {rows}
                </div>
            )
        
    }

    //sort by time
    function sortResponsesByDay(){
       
        var sortedResponses = procFormResponses
        
        

        var temp = [];
        sortedResponses.forEach(s => {
            if(s.date ===selectedDay){
                temp.push(s)
            }
        });
          
        // setProcFormResponses(sortedResponses)


        var header = <ul className='row'>
                <li style={{flex: '0 0 20%'}} >{'Question'}</li>

                <li style={{flex: '0 0 20%'}}>{'Date'}</li>
                
                <li style={{flex: '0 0 20%'}}>{'Time'}</li>
                
                <li style={{flex: '0 0 20%'}}>{'Answer'}</li>
                
                <li style={{flex: '0 0 20%'}}>{'Comment (Write In Choice)'}</li>
            </ul>
            var rows = [].concat.apply([], sortedResponses).map((q,i)=>
                    
                    
                        
                <ul className='row' key={'feedbackform-responses-item-'+i}>
                        {/* Question */}
                        <li style={{flex: '0 0 20%'}}>{q?.question}</li>
                        {/* date */}
                        <li style={{flex: '0 0 20%'}}>{q?.date}</li>
                        {/* Time */}
                        <li style={{flex: '0 0 20%'}}>{q?.time}</li>
                        {/* Answer */}
                        <li style={{flex: '0 0 20%'}}>{q?.answer}</li>
                        {/* Comment */}
                        <li style={{flex: '0 0 20%'}}>{q?.comment}</li>
                </ul>
                

            )
            setFeedBackFormResponsesEls(
                <div className='' style={{width: '80%'}}>
                    {header}
                    {rows}
                </div>
            )
        
    }

    const filterBarStyles = {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#155473',
        color:'white',
        width: '80%',
        height: '40px'
    }
    // $ripple-color: #00589a;
    // $clr-blue: #155473;
    // $clr-black: #000000;
    // $clr-orange: #D87152;
    // $clr-blue-green: #32AAA9;
    // $clr-pale: #F7F2EC; 




    useEffect(() => {

        

        //1console.log(dateFilter);
        let d = dateFilter
        let todayDate = new Date().toLocaleString('fr-CA').split(',')[0]

        let fTime = ''
        if(fromTime==='')
            fTime = '00:00'
        else
            fTime = fromTime

        let tTime = ''
        if(toTime==='')
            tTime = '23:59'
        else
            tTime = toTime

        let day = ''
        if(dateFilter==='')
            day = new Date().toLocaleString('fr-CA').split(',')[0]
        else
            day = dateFilter
        


        
        axios.post(baseAPI+'/api/analysis/registration/forms?page=1&limit=1000', {
            "start_date": fromDay + ' '+ fromTime,
            "end_date": toDay + ' '+ toTime
        }, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r);
            setRegForms(r.data)
            setTimeFilteredRegForms(r.data)
            setTotalRegistrants(r.data.length)
        }).catch(e=>{
            //1console.log(e);
        })
    }, [fromDay,toDay,fromTime, toTime])

    
    useEffect(() => {
        
        if (selectedClinicalRegion!==''&&selectedCountry!=='') {
            setRegForms(timeFilteredRegForms.filter(x=>x.clinical_region===selectedClinicalRegion&&x.country===selectedCountry))
        }
        if (selectedClinicalRegion!==''&&selectedCountry==='') {
            setRegForms(timeFilteredRegForms.filter(x=>x.clinical_region===selectedClinicalRegion))
        }
        if (selectedClinicalRegion===''&&selectedCountry!=='') {
            setRegForms(timeFilteredRegForms.filter(x=>x.country===selectedCountry))
        }
        
    }, [selectedClinicalRegion, selectedCountry])
    return (


        <div style={{minWidth: adminMinWidth+'px'}}>
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%'}}>
                <AdminNav style={{width: navExpanded?'16%':0}} active={4}/>

                <div style={{
                    display: 'flex', flexDirection:'column', 
                    justifyContent:'center', 
                    alignItems: 'center',
                    margin: '5% 0',
                    width:navExpanded?'84%':'100%'
                }} className='registration-forms-a' id='registration-forms'>
            <h3 className='p-title' style={{textAlign: 'center'}}>Registration Forms</h3>



              

            <FilterBar fromDay={fromDay} onChangeFromDay={e=>setFromDay(e.target.value)} ToDay={toDay} onChangeToDay={e=>setToDay(e.target.value)} FromTime={fromTime} onChangeFromTime={e=>setFromTime(e.target.value)} toTime={toTime} onChangeToTime={e=>setToTime(e.target.value)}  style={{marginLeft:'10%',marginRight:'10%', marginBottom:'3%'}}/>
            
            <LineChart width={600} height={300} data={data} style={{borderRadius: '15px', marginTop: '0%'}}>
                <Line type="monotone" dataKey="value" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="month" />
                <YAxis />
            </LineChart>
            <div className='row' style={{justifyContent: 'space-between', width: '78%', marginTop: '2%', marginBottom: '2%'}} >
                <div className='pill-style' style={{padding: '20px', flexDirection: 'row', height: '100px'}}>
                    <img src={formicon} height='50px' style={{filter: 'invert(1)'}}/>
                    <div>
                        <p style={{margin:0}}>Total Registered Users</p>
                        <p style={{margin:0, fontSize: '30px'}}>{totalRegistrants}</p>
                    </div>
                </div>
                <div className='pill-style' style={{padding: '0 20px', width: '345px', flexDirection: 'row', height: '100px'}}>
                    <img src={arrowicon} className='arrow-slider' height='30px' style={{transform: 'rotate(180deg)', marginRight: '5%', cursor: 'pointer'}} onClick={()=>{if(!playanim)setMonthSwitcherIndex(monthSwitcherIndex-1<0?11:monthSwitcherIndex-1)}} />
                    <p style={{fontSize: '2em', width: '60%', animation: playanim?'slideIn 1s 1':''}}>{data[monthSwitcherIndex]?.month}</p>
                    <div>
                        <p style={{margin:0}}>New Registered Users</p>
                        <span style={{marginRight:'10%', fontSize: '30px'}}>{data[monthSwitcherIndex]?.value}</span>
                        <img className='arrow-slider' src={formicon} height='30px' style={{filter: 'invert(1)'}}/>
                    </div>
                    <img src={arrowicon} className='arrow-slider' height='30px' style={{cursor: 'pointer'}} onClick={()=>{if(!playanim)setMonthSwitcherIndex(monthSwitcherIndex+1>11?0:monthSwitcherIndex+1)}}/>
                </div>
            </div>
            <div className='form' style={{height:'auto', flexDirection:'row', width:"80%"}}>
            <DDL name='Filter Country' value={selectedCountry} ddlItems={countryList} onChange={(e)=>setSelectedCountry(e.target.value)} style={{marginBottom:0}}/>
            <DDL name='Filter Clinical Region' value={selectedClinicalRegion} ddlItems={regions} onChange={(e)=>setSelectedClinicalRegion(e.target.value)} style={{marginBottom:0}}/>
            <button className='warning-btn' onClick={()=>{refreshRegForms(); setSelectedClinicalRegion(''); setSelectedCountry('')}}>✖</button>
            </div>
            
            
            <div className='a-table' style={{ height: '500px', overflowY: 'scroll', marginTop:0}}>
                            
                <div className='a-t-tb' style={{marginTop: 0}}>
                <ul className='a-t-th' style={{backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr', textAlign:'center'}}>
                    <li style={{color: '#155473'}}>Name</li>
                    <li style={{color: '#155473'}}>Mobile Number</li>
                    <li style={{color: '#155473'}}>Email</li>
                    <li style={{color: '#155473'}}>Country</li>
                    <li style={{color: '#155473'}}>Clinical Region</li>
                    <li style={{color: '#155473'}}>Address</li>
                    <li style={{color: '#155473'}}>Time of Registration</li>
                    
                    
                </ul>
                    {/* {
                        tableElements
                    } */}
                    
                    {
                       regForms.map((s,i)=>
                        <ul className='a-t-tr' key={'a-t-cell-'+i} style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%', textAlign:'center'}}>
                            <li>{s.name} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s.date}</p></li>
                            <li>{s.phone}</li>
                            <li>{s.email}</li>
                            <li>{s.country}</li>
                            <li>{s.clinical_region}</li>
                            <li>{s.address}</li>
                            <li>{new Date(s.created_at).toLocaleString('en-GB')}</li>
                            
                            
                            
                        </ul>  
                    )
                    }
                </div>
                <div style={{display: 'grid',gridTemplateColumns: '1fr 1fr 0.5fr' }}>
                    <div></div>
                    <div></div>
                    
                </div>
                
            </div>
            <button className='primary-btn' style={{padding:0, width: '8rem', height:'2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-around',marginTop: '5%'}} onClick={onDownloadUserData}><img src={downloadicon} height='25px'/><span>Export</span></button>
            
        </div>

            </div>
            
        </div>
        
    )
}

export default AdminRegistrationForms
