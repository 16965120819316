import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateCreateOrEdit } from "../slices/adminSlice";
import arrowicon from '../../img-src/icons/down.png'
import { getToken, getUser, removeUserSession } from '../../Utils/Common';

import axios from 'axios';
import { baseAPI } from "../../config";
function AdminCreateEditEvents(props) {
    
    const [tableElementsPrev, setTableElementsPrev] = useState()
    const [tableElementsPrevExpanded, setTableElementsPrevExpanded] = useState()
    const [tableElementsScheduled, setTableElementsScheduled] = useState()
    const [tableElementsScheduledExpanded, setTableElementsScheduledExpanded] = useState()
    const [scheduledEventsRetrieved, setScheduledEventsRetrieved] = useState()
    const [scheduleEventsObj, setScheduleEventsObj] = useState([])
    const [runningEvent, setRunningEvent] = useState(null)
    function deleteEvent(id){
        axios.delete(baseAPI+'/api/event/'+id, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log(r);
            refreshEvents()
        }).catch(e=>{
            
        })
    }

    function refreshEvents(){
        // // get scheduled events if any
        axios.get(baseAPI+'/api/event/scheduled', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log(('----------------------------------------------------scheduled events'));
            //1console.log(r.data);
            setScheduledTableEls(r.data)
            setScheduleEventsObj(r.data)
            setScheduledEventsRetrieved(true)
        }).catch(e=>{
            if(e.response?.status===401){
                removeUserSession()
                window.location.href = "/";
            }
            if(e.response?.status===404 || e.response?.data==="No upcoming events"){
                setScheduledTableEls(null)
                setScheduledEventsRetrieved(false)
            }
                //1console.log(e.response);
        })

        
        // // get past events if any
        axios.get(baseAPI+'/api/event/past', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log(r.data);
            // setScheduledTableEls(r.data)
            
            
            //collapsed view
            setTableElementsPrev(Array.isArray(r.data)?
                r.data.map((s,i)=>
                    <ul className='a-t-tr' key={'a-t-cell-prev-events'+i} style={{gridTemplateColumns: '1fr 1fr 1fr',gridColumnGap:'0%'}}>
                        <li>{s?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s?.date}</p></li>
                        <li>{new Date(s?.event_datetime).toLocaleDateString("en-GB")}</li>
                        <li>{s?.private?'Private':'Public'}</li>
                        
                        
                        
                        
                    </ul>  
                )
                :
                <ul className='a-t-tr'  style={{gridTemplateColumns: '1fr 1fr 1fr',gridColumnGap:'0%'}}>
                        <li>{r.data?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{r.data?.date}</p></li>
                        <li>{new Date(r.data?.event_datetime).toLocaleDateString("en-GB")}</li>
                        <li>{r.data?.private?'Private':'Public'}</li>
                        
                        
                        
                        
                </ul>

            )

            // expanded view
            setTableElementsPrevExpanded(Array.isArray(r.data)?
                r.data.map((s,i)=>
                <ul className='a-t-tr' key={'a-t-cell-prev-events'+i} style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%'}}>
                    <li>{s?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s?.date}</p></li>
                    <li>{new Date(s?.event_datetime).toLocaleDateString("en-GB")}</li>
                    <li>{s?.private?'Private':'Public'}</li>
                    <li>{new Date(s?.event_datetime).getHours() + ':' +new Date(s?.event_datetime).getMinutes()}</li>
                    <li>{s?.link}</li>
                    <li style={{display:'flex', width:'100%'}}>
                        <button className='primary-btn-tbl' onClick={()=>dispatch(updateCreateOrEdit('edit '+s.id))}>Edit</button>
                        <button className='warning-btn-tbl' onClick={()=>deleteEvent(s.id)}>Delete</button>
                    </li>
                    
                    
                    
                </ul>
                )
                :
                <ul className='a-t-tr'  style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%'}}>
                    <li>{r.data?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{new Date(r.data?.event_datetime).toLocaleDateString("en-GB")}</p></li>
                    <li>{new Date(r.data?.event_datetime).toLocaleDateString("en-GB")}</li>
                    <li>{r.data?.private?'Private':'Public'}</li>
                    <li>{new Date(r.data?.event_datetime).getHours() + ':' +new Date(r.data?.event_datetime).getMinutes()}</li>
                    <li>{r.data?.link}</li>
                    <li style={{display:'flex', width:'100%'}}>
                        <button className='primary-btn-tbl' onClick={()=>dispatch(updateCreateOrEdit('edit '+r.data.id))}>Edit</button>
                        <button className='warning-btn-tbl' onClick={()=>deleteEvent(r.data.id)}>Delete</button>
                    </li>
                    
                    
                    
                </ul>
            
            )
        }).catch(e=>{
            //1console.log(e.response);
            if(e.response?.status===401){
                removeUserSession()
                // props.history.push('/')
                window.location.href = "/";
            }
            if(e.response?.status===404 || e.response?.data==="No past events"){
                setTableElementsPrev(<p>No past events found</p>)
            }
                //1console.log(e.response);
        })


        // get running event
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            //1console.log('running event', r.data);
            setRunningEvent(r.data)
        }).catch(e=>{
            //1console.log(e);
        })
    }
    useEffect(() => {


        refreshEvents()

        
        
        
    }, [])

    // set scheduled events table and expanded table
    const setScheduledTableEls = (scheduledEventsObj)=>{
        if(scheduledEventsObj===null){
            setTableElementsScheduled(<p className='p-title'>No Scheduled Events.</p>)
            setTableElementsScheduledExpanded(<p className='p-title'>No Scheduled Events.</p>)
        }
        else{
            //handle if response is array or object
            
            if(Array.isArray(scheduledEventsObj)){
               setTableElementsScheduled(scheduledEventsObj.map((s,i)=>
                <ul className='a-t-tr' key={'a-t-cell-scheduled-events'+i} style={{gridTemplateColumns: '1fr 1fr 1fr',gridColumnGap:'0%'}}>
                    <li>{s?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s?.date}</p></li>
                    <li>{new Date(s?.event_datetime).toLocaleDateString("en-GB")}</li>
                    <li>{s?.private?'Private':'Public'}</li>
                    
                    
                    
                    
                </ul>  
                ))
                setTableElementsScheduledExpanded(scheduledEventsObj.map((s,i)=>
                    <ul className='a-t-tr' key={'a-t-cell-scheduled-events'+i} style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%'}}>
                        <li>{s?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s?.date}</p></li>
                        <li>{new Date(s?.event_datetime).toLocaleDateString("en-GB")}</li>
                        <li>{s?.private?'Private':'Public'}</li>
                        <li>{s.event_datetime.split('T')[1].split('.')[0]}</li>
                        <li><a href={s?.link}>Display Link</a></li>
                        <li style={{display:'flex', width:'100%'}}>
                            <button className='primary-btn-tbl' onClick={()=>dispatch(updateCreateOrEdit('edit '+s.id))}>Edit</button>
                            <button className='warning-btn-tbl' onClick={()=>deleteEvent(s.id)}>Delete</button>
                        </li>
                        
                        
                        
                    </ul>  
                )) 
            }
            else{
                setTableElementsScheduled(
                <ul className='a-t-tr'  style={{gridTemplateColumns: '1fr 1fr 1fr',gridColumnGap:'0%'}}>
                    <li>{scheduledEventsObj?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{scheduledEventsObj?.date}</p></li>
                    <li>{new Date(scheduledEventsObj?.event_datetime).toLocaleDateString("en-GB")}</li>
                    <li>{scheduledEventsObj?.private?'Private':'Public'}</li>
                    
                    
                    
                    
                </ul>  
                )
                setTableElementsScheduledExpanded(
                    <ul className='a-t-tr'  style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%'}}>
                        <li>{scheduledEventsObj?.title} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{''}</p></li>
                        <li>{new Date(scheduledEventsObj?.event_datetime).toLocaleDateString("en-GB")}</li>
                        <li>{scheduledEventsObj?.private?'Private':'Public'}</li>
                        <li>{new Date(scheduledEventsObj?.event_datetime).getHours() + ':' +new Date(scheduledEventsObj?.event_datetime).getMinutes()}</li>
                        <li>{scheduledEventsObj?.link}</li>
                        <li style={{display:'flex', width:'100%'}}>
                            <button className='primary-btn-tbl' onClick={()=>dispatch(updateCreateOrEdit('edit '+scheduledEventsObj.id))}>Edit</button>
                            <button className='warning-btn-tbl' onClick={()=>deleteEvent(scheduledEventsObj.id)}>Delete</button>
                        </li>
                    </ul>  
                ) 
            }
            
        }
        
    }
    const [selection, setSelection] = useState(0)
    const [searchPhrase, setSearchPhrase] = useState('')
    const dispatch = useDispatch()
    const createOrEdit = useSelector(state => state.admin.createOrEdit)
    useEffect(() => {
        
        //dispatch(updateCreateOrEdit(selection));
        
    }, [selection])

    // useEffect(() => {
    //     //1console.log('createOrEdit');
    //     //1console.log(createOrEdit);
        
    // }, [createOrEdit])

    useEffect(() => {
        
        if(searchPhrase===''){
            setScheduledTableEls(scheduleEventsObj)
        }
        else{
            //1console.log(scheduleEventsObj);
            //1console.log(scheduleEventsObj.filter(a=>a?.title?.toUpperCase().includes(searchPhrase.toUpperCase())));
            setScheduledTableEls(scheduleEventsObj.filter(a=>a?.title?.toUpperCase().includes(searchPhrase.toUpperCase())))
        }
    }, [searchPhrase])
    return (
        <div style={props.style}>
            <h2 className='p-title' style={{textAlign: 'center'}}>Create/Edit Events</h2>
            <p style={{textAlign: 'center'}}>You can choose to view scheduled events' analytics, edit or view the running event or create a new event.</p>




            {/* RUNNING EVENT */}

            <div style={{display: runningEvent!==null?'block':'none'}}>
                <p className='p-title' style={{textAlign:'center'}}>Current Running Event</p>
                <div className='row' style={{justifyContent: 'space-around',backgroundColor: '#F7F2EC', fontWeight:'bold', flexBasis:'20%',  marginLeft:'5%',marginRight:'5%', borderRadius:'15px 15px 0 0'}}>
                    <p style={{flex: '0 0 10%', marginBottom:0}}>Name</p>
                    <p style={{flex: '0 0 10%', marginBottom:0}}>Privacy</p>
                    <p style={{flex: '0 0 10%', marginBottom:0}}>Date</p>
                    <p style={{flex: '0 0 10%', marginBottom:0}}></p>
                </div>
                <div className='row' style={{justifyContent: 'space-around',backgroundColor: '#F7F2EC', marginBottom:'3%',marginLeft:'5%',marginRight:'5%', borderRadius:'0 0 15px 15px'}}>
                    <p style={{flex: '0 0 10%'}}>{runningEvent?.title}</p>
                    <p style={{flex: '0 0 10%'}}>{runningEvent?.private?'Private':'Public'}</p>
                    <p style={{flex: '0 0 10%'}}>{runningEvent?.event_datetime?new Date(runningEvent?.event_datetime).toLocaleString('en-GB'):''}</p>
                    <p style={{flex: '0 0 10%'}}><button className='primary-btn' onClick={()=>dispatch(updateCreateOrEdit('edit '+runningEvent.id + ' running'))}>Edit</button></p>
                </div>


            </div>



            <div className='row' style={{justifyContent: 'space-around', display: 0&&!selection?'flex':'none'}}>
                <div className='panel-style panel-select' onClick={()=>setSelection(2)} style={{flexDirection: 'column', width: '40%', backgroundColor: '#F7F2EC', overflow: 'hidden', height: '50vh', justifyContent: 'flex-start'}}>
                    <p className='panel-title'>Previous Events</p>
                    <div className='a-table' style={{ height: 'unset', overflowX: 'hidden', width: '100%'}}>
                            
                        <div className='a-t-tb' >
                        <ul className='a-t-th' style={{backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 1fr '}}>
                            <li style={{color: '#155473'}}>Name</li>
                            <li style={{color: '#155473'}}>Date</li>
                            <li style={{color: '#155473'}}>Privacy</li>
                            
                            
                            
                        </ul>
                            {
                                tableElementsPrev
                            }
                        </div>
                        
                        
                    </div>
                </div>
                <div className='panel-style panel-select' onClick={()=>setSelection(1)} style={{flexDirection: 'column', width: '40%', backgroundColor: '#F7F2EC', overflow: 'hidden', height: '50vh', justifyContent: 'flex-start'}}>
                    <p className='panel-title' style={{display: scheduledEventsRetrieved?'block':'none'}}>Scheduled Events</p>
                    <div className='a-table' style={{ height: 'unset', overflowX: 'hidden', width: '100%'}}>
                            
                        <div className='a-t-tb' >
                        <ul className='a-t-th' style={{display: scheduledEventsRetrieved?'grid':'none', backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 1fr '}}>
                        <li style={{color: '#155473'}}>Name</li>
                            <li style={{color: '#155473'}}>Date</li>
                            <li style={{color: '#155473'}}>Privacy</li>
                            
                            
                            
                        </ul>
                            {
                                tableElementsScheduled
                            }
                        </div>
                        
                        
                    </div>
                </div>
            </div>


            <div className='scheduled-events-expanded' style={{backgroundColor: '#F7F2EC', display: 1||selection===1?'block':'none', paddingBottom: '5%'}}>
                <button style={{marginTop: '2%', cursor: 'pointer'}} onClick={()=>setSelection(0)}><img src={arrowicon} style={{transform: 'rotate(90deg)', display:'none'}}/></button>
                <p className='panel-title' style={{display: scheduledEventsRetrieved?'block':'none',border: 'none', textDecoration: 'underline', paddingTop:'0%', fontSize: '1.5rem', paddingBottom: '0'}}>Scheduled Events</p>


                {/* search bar */}
                <div className='row' style={{marginTop:'2%', width:'50%'}}>
                    <p style={{marginRight:'5%'}}>Search for event:</p>
                    <input type='text' value={searchPhrase} onChange={e=>setSearchPhrase(e.target.value)} placeholder='Search by event name' style={{padding:'10px', }} />
                </div>

                <div className='a-table' style={{ height: 'unset', width: '80%', margin: '5% 10%', overflowY: 'auto', height: '50vh'}}>
                            
                    <div className='a-t-tb'  style={{marginTop:0}}>
                    <ul className='a-t-th' style={{display: scheduledEventsRetrieved?'grid':'none',backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr'}}>
                        <li style={{color: '#155473'}}>Name</li>
                        <li style={{color: '#155473'}}>Date</li>
                        <li style={{color: '#155473'}}>Privacy</li>
                        <li style={{color: '#155473'}}>Time</li>
                        <li style={{color: '#155473'}}>Display</li>
                        <li></li>
                        
                        
                    </ul>
                        {
                            tableElementsScheduledExpanded
                        }
                    </div>
                    
                    
                </div>
            </div>
            <div className='scheduled-events-expanded' style={{backgroundColor: '#F7F2EC', display: selection===2?'block':'none', paddingBottom: '5%'}}>
                <button style={{marginTop: '2%', cursor: 'pointer'}} onClick={()=>setSelection(0)}><img src={arrowicon} style={{transform: 'rotate(90deg)'}}/></button>
                <p className='panel-title' style={{border: 'none', textDecoration: 'underline', paddingTop:'0%', fontSize: '1.5rem', paddingBottom: '0'}}>Previous Events</p>
                <div className='a-table' style={{ height: 'unset', width: '80%', margin: '5% 10%', overflowY: 'auto', height: '50vh'}}>
                            
                    <div className='a-t-tb'  style={{marginTop:0}}>
                    <ul className='a-t-th' style={{backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr'}}>
                        <li style={{color: '#155473'}}>Name</li>
                        <li style={{color: '#155473'}}>Date</li>
                        <li style={{color: '#155473'}}>Privacy</li>
                        <li style={{color: '#155473'}}>Time</li>
                        <li style={{color: '#155473'}}>Display</li>
                        <li></li>
                        
                        
                    </ul>
                        {
                            tableElementsPrevExpanded
                        }
                    </div>
                    
                    
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '5%'}}>
            <button className='primary-btn' style={{width: '30%', height: '80px', fontSize: '2rem', marginBottom: '5%'}} onClick={()=>dispatch(updateCreateOrEdit('create'))}>Create Event</button>
            
            </div>
        </div>
    )
}

export default AdminCreateEditEvents
