import React, { useState, useEffect, useRef } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import AudioDialog from "./components/AudioDialog";
import { ScreenRotation, VolumeOff, VolumeUp } from "@mui/icons-material";
import { mobileWidth, walkthroughVideoLink } from "../../config";

const Walkthrough = () => {
  const [fbWarning, setFbWarning] = useState(false);
  const [acceptAudio, setAcceptAudio] = useState(false);
  const [mobile, setMobile] = useState(window.innerWidth <= 768);
  const [mobilePortrait, setMobilePortrait] = useState(
    window.screen.width <= mobileWidth && window.innerWidth <= mobileWidth
  );
  useEffect(() => {
    const handleTouchMove = (e) => {
      e.preventDefault();
    };

    // Add event listener to the document
    document.addEventListener("touchmove", handleTouchMove, { passive: false });

    // Clean up by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
  const videoRef = useRef(null);
  useEffect(() => {
    document.title = "L'Oréal Expo";
    if (navigator.userAgent.includes("FB")) {
      setFbWarning(true);
    }
    setMobile(
      window.screen.width <= mobileWidth || window.innerWidth <= mobileWidth
    );
    const videoElement = videoRef.current;
    const handleVisibilityChange = () => {
      if (document.hidden) {
        videoElement?.pause();
      } else {
        videoElement?.play();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const handleSkip = () => {
    window.location.href = "/login";
  };
  const handleToggleAudio = () => {
    setAcceptAudio(!acceptAudio);
  };
  window.addEventListener("resize", () => {
    setMobilePortrait(
      window.screen.width <= mobileWidth && window.innerWidth <= mobileWidth
    );
  });
  return fbWarning ? (
    <div className="fb-warning-overlay">
      <div className="logo"></div>
      <p>
        Please use the navigation below to view this page in an external
        browser.
      </p>
    </div>
  ) : (
    <>
      {mobilePortrait ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: window.innerHeight,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ScreenRotation />
          <Typography>Please rotate your device</Typography>
        </div>
      ) : (
        <div
          style={{
            height: mobile ? window.innerHeight : "100vh",
            overflow: "hidden",
          }}
        >
          <AudioDialog show={true} setAcceptAudio={setAcceptAudio} />
          <IconButton
            onClick={handleToggleAudio}
            size="large"
            sx={{ top: "5%", left: "90%" }}
          >
            {acceptAudio ? <VolumeUp /> : <VolumeOff />}
          </IconButton>
          <Button
            onClick={handleSkip}
            variant="contained"
            sx={{
              top: mobile ? window.innerHeight / 2 : "85%",
              marginLeft: "auto",
              display: "block",
              marginRight: "8%",
              color: "white",
              bgcolor: "rgba(25, 118, 210, 0.6)",
            }}
            size="large"
          >
            Skip
          </Button>
          <video
            onEnded={handleSkip}
            autoPlay
            muted={!acceptAudio}
            playsInline
            ref={videoRef}
            style={{
              top: 0,
              left: 0,
              position: "absolute",
              height: "100%",
              width: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          >
            <source src={walkthroughVideoLink} type="video/mp4" />
          </video>
        </div>
      )}
    </>
  );
};

export default Walkthrough;
