import lorealLogo from '../../img-src/loreal logo.png';
import burgerIcon from '../../img-src/icons/menu-icon.png';
import AdminNav from './AdminNav';
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { getToken,removeUserSession,removeAdminId } from '../../Utils/Common'
import { adminMinWidth } from '../content'
import PanelTable from '../Util/PanelTable'
import AdminAnalysis from './AdminAnalysis'
import calendarIcon from '../../img-src/icons/calendar.png'
import clockIcon from '../../img-src/icons/clock.png'
import questionIcon from '../../img-src/icons/question.png'
import { fixfontsize } from '../helpers';
import Input from '../Fields/Input'
import { baseAPI } from '../../config';
import { toSqlDate } from '../../Utils/toSqlDate';
function AdminDashboard(props) {
    const [navExpanded, setNavExpanded] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }
    const [usersInLast30Mins, setUsersInLast30Mins] = useState('999')
    const [avgEngagementTime, setAvgEngagementTime] = useState(40)
    const [avgEngagementTimeHours, setAvgEngagementTimeHours] = useState(avgEngagementTime)
    const [analysisActive, setAnalysisActive] = useState(false)

    const [runningEventId, setRunningEventId] = useState(null)

    const [runningEventBlockedUsers, setRunningEventBlockedUsers] = useState(null)
    const [runningEventButtonClicks, setRunningEventButtonClicks] = useState(null)
    const [runningEventTotalInvitees, setRunningEventTotalInvitees] = useState(null)
    const [runningEventEngagementTime, setRunningEventEngagementTime] = useState(null)
    const [runningEventOnlineUsers, setRunningEventOnlineUsers] = useState(0)
    const [runningEventInviteeErrors, setRunningEventInviteeErrors] = useState(null)
    const [runningEventDeletedChats, setRunningEventDeletedChats] = useState(null)
    const [runningEventName, setRunningEventName] = useState(null)
    const [runningEventWinners, setRunningEventWinners] = useState([])
    const [error, setError] = useState('')
    const [showToast, setShowToast] = useState(false)

    const [loading, setLoading] = useState('')
    
    const [showEndEventOverlay, setShowEndEventOverlay] = useState(false)

    const _MOCK_INVITEE_ERRORS = [{name: 'Ahmed Khaled - 01265866978'},{name: 'Salma Aly - 01265866978'},{name: 'Iman Salem - 01265866978'},{name: 'Ahmed Khaled - 01265866978'},{name: 'Ahmed Khaled - 01265866978'}] 
    const _MOCK_USER_UNBLOCK = [{name: 'Ahmed Khaled'},{name: 'Salma Aly'},{name: 'Iman Salem'},{name: 'Ahmed Khaled'},{name: 'Ahmed Khaled'}] 
    
    const [_MOCK_USERS_obj, set_MOCK_USERS_obj] = useState([])
    const [tableElements, setTableElements] = useState('')
  
    const [newWinner, setNewWinner] = useState('')
    function fixFontSize(base, value){
        if(value===null)
            return (parseInt(base)*Math.pow(0.80,1))?.toString()+'px'


        return (parseInt(base)*Math.pow(0.80,value?.toString().length)).toString()+'px'
        

        //return (parseInt(base)*(1.50*value.toString().length)).toString()+'px'
    }
    useEffect(() => {
        if(parseInt(avgEngagementTime)>=60)
            setAvgEngagementTimeHours((avgEngagementTime / 60.0).toFixed(2)) 
        else
            setAvgEngagementTimeHours(avgEngagementTime) 
    }, [avgEngagementTime])


    function confirmEndRunningEvent(){

    }

    function onEndRunningEvent(){
        axios.put(baseAPI+'/api/event/end/'+runningEventId,{

                        //yyyy-MM-dd hh:mm:ss
            "end_date": new Date().toISOString().slice(0,19).replace('T',' ')
        }, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r);
            window.location.href='admin'
        }).catch(e=>{
            if(e?.response?.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
            //1console.log(e.response);
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, 2000);
            setError('Error occurred while ending event. Please try again. '+e.response.status)
        })
    }

    
    useEffect(() => {

        

        document.title = "L'Oréal Expo - Dashboard"
        //get running event analysis
        axios.post(baseAPI+'/api/analysis/running','', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log('running event',r.data);
            
            setRunningEventButtonClicks(r.data.button_clicks)
            setRunningEventTotalInvitees(r.data.total_invitees)
            setRunningEventEngagementTime(r.data.event_engagement_times)
            // setRunningEventOnlineUsers(r.data.online_users)
            setRunningEventOnlineUsers(0)
        }).catch(e=>{
            if(e.response?.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
            //1console.log(e.response);
            setRunningEventId(null)
        })


        
        axios.get(baseAPI+'/api/event/checkRunning', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{

            var runningEvId = r.data.id
            setRunningEventId(r.data.id)
            setRunningEventName(r.data.title)
            setAnalysisActive(r.data.activate_analysis)
            //get blocked users
            axios.get(baseAPI+'/api/event/chat/block/'+runningEvId, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                setRunningEventBlockedUsers(r.data)
                //1console.log(r.data);
            }).catch(e=>{
                if(e.response.status===401){
                    removeUserSession()
                    removeAdminId()
                    props.history.push('/')
                }
                //1console.log('%c error get blocked users', 'color:orange', e.response);
                
            })
            //get invitee errors
            axios.post(baseAPI+'/api/inviteeError/all', {event_id: runningEvId}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                //1console.log(r.data);
                setRunningEventInviteeErrors(r.data)
            }).catch(e=>{
                if(e.response.status===401){
                    removeUserSession()
                    removeAdminId()
                    props.history.push('/')
                }
                //1console.log('%c error get invitee errors', 'color:orange', e.response);
            })

            //get deleted chats
            axios.get(baseAPI+'/api/event/chat/delete/'+runningEvId, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                //1console.log(r.data);
                setRunningEventDeletedChats(r.data.map(a=>{return{d1:a.message,d2:''}}))
                // runningEventDeletedChats?.map(r=>{return{d1:r?.message,d2:''}})
            }).catch(e=>{
                // //1console.log('%c error get deleted chats', 'color:orange', e.response);
                //1console.log(e.response);
                if(e.response.status===401){
                    removeUserSession()
                    removeAdminId()
                    props.history.push('/')
                }
            })

            // get attendees in running event
            axios.post(baseAPI+'/api/analysis/attendees/running', {event_id: runningEvId},{headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                
                set_MOCK_USERS_obj(r.data)
            }).catch(e=>{
                //1console.log(e);
                if(e.response.status===401){
                    removeUserSession()
                    removeAdminId()
                    props.history.push('/')
                }
            })

            //get winners for running event
            axios.get(baseAPI+'/api/event/winners/'+runningEvId, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                //1console.log(r.data?.map(a=>a.name));
                setRunningEventWinners(r.data?.map(a=>a.name))
            }).catch(e=>{
                //1console.log(e);
            })

        }).catch(e=>{
            if(e.response?.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
            //1console.log('%c error check running to get event id', 'color:orange', e.response);
        })
        


        if(parseInt(avgEngagementTime)>=60)
            setAvgEngagementTimeHours((avgEngagementTime / 60.0).toFixed(2)) 
        else
            setAvgEngagementTimeHours(avgEngagementTime) 



        
    }, [])

    useEffect(()=>{
        setTableElements(_MOCK_USERS_obj.map((s,i)=>
        <ul className='a-t-tr' key={'a-t-cell-'+i} style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',gridColumnGap:'0%'}}>
            <li>{s.name} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s.date}</p></li>
            <li>{s.phone}</li>
            <li>{s.email}</li>
            <li>{s.country}</li>
            <li>{s.clinical_region}</li>
            <li>{s.address}</li>
            <li>{s.created_at?.split('T')[0]+ ' '+s.created_at?.split('T')[1].split('.')[0]}</li>
            <li>{s.verification_code}</li>
            
            
            
        </ul>  
    ))
    }, [_MOCK_USERS_obj])

    //invitee errors section
    function onInviteeConfirm(i){
        axios.post(baseAPI+'/api/inviteeError/confirm', {event_id: runningEventId, phone: runningEventInviteeErrors[i].phone}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log(r);
            //get invitee errors
            axios.post(baseAPI+'/api/inviteeError/all', {event_id: runningEventId}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                //1console.log(r.data);
                setRunningEventInviteeErrors(r.data)
            }).catch(e=>{
                //1console.log('%c error get invitee errors', 'color:orange', e.response);
            })

        }).catch(e=>{
            //1console.log(e.response);
        })
    }
    function onInviteeDeny(i){
        axios.post(baseAPI+'/api/inviteeError/deny', {event_id: runningEventId, phone: runningEventInviteeErrors[i].phone}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log(r);
            //get invitee errors
            axios.post(baseAPI+'/api/inviteeError/all', {event_id: runningEventId}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                //1console.log(r.data);
                setRunningEventInviteeErrors(r.data)
            }).catch(e=>{
                //1console.log('%c error get invitee errors', 'color:orange', e.response);
            })

        }).catch(e=>{
            //1console.log(e.response);
        })
    }
    function onUserUnblock(i){
        //1console.log(i,runningEventId);
        axios.put(baseAPI+'/api/event/chat/unblock', {user_id: parseInt(i) , event_id: parseInt(runningEventId) }, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r);
            //get blocked users
            axios.get(baseAPI+'/api/event/chat/block/'+runningEventId, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                setRunningEventBlockedUsers(r.data)
                //1console.log(r.data);
            }).catch(e=>{
                if(e.response.status===404){
                    setRunningEventBlockedUsers([])
                }
                //1console.log('%c error get blocked users', 'color:orange', e.response);
                
            })

        }).catch(e=>{
            //1console.log(e?.response);
        })

        // //1console.log('unblocked '+_MOCK_INVITEE_ERRORS[i]?.name);
    }


    const [genTotalUsers, setGenTotalUsers] = useState(0)
    const [genAvgEngagementTime, setGenAvgEngagementTime] = useState(0)
    const [genPrevEvents, setGenPrevEvents] = useState([])
    useEffect(() => {
        document.title = "L'Oréal Expo - Dashboard"
        let nowMinus30 = new Date()
        nowMinus30.setMinutes(nowMinus30.getMinutes()-30)

        let now = new Date()

        axios.post(baseAPI+'/api/analysis/general', {start_date:toSqlDate(nowMinus30), end_date:toSqlDate(now)},{headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log('%c General Platform Analytics','font-size: 30px; color:green',r.data);
            setGenTotalUsers(r.data.total_active_users)
            setGenAvgEngagementTime(r.data.average_engagement_time)
            
        }).catch(e=>{
            //1console.log(e.response);
        })


        //get previous events for general platform analytics
        axios.get(baseAPI+'/api/analysis/pastEvents',{headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log('%c General Platform Analytics Past','font-size: 30px; color:purple',r.data);
            setGenPrevEvents(r.data)
            
        }).catch(e=>{
            //1console.log(e.response);
        })


    }, [])
    
    function onActivateAnalysis(){
        axios.put(baseAPI+'/api/event/startAnalysis/'+runningEventId ,'',{headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r);
            window.location.href='/admin'
        }).catch(e=>{
            //1console.log(e);
        })
    }

    function addWinner(){
        if(newWinner==='')
            return

        
        let x = runningEventWinners
        x.push(newWinner)

        setRunningEventWinners(x)

        setNewWinner('')
    }
    function removeWinner(i){
        let x = [...runningEventWinners]
        x.splice(i,1)
        setRunningEventWinners(x)
        

    }

    function saveWinners(){
        setLoading('Saving...')
        let payload = runningEventWinners.map((w,i)=>{return{name: w, order: i+1}})
        payload = {winners: payload}
        //1console.log(payload);
        axios.put(baseAPI+'/api/event/winners/'+runningEventId, payload, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            
            setLoading('Winners Saved!')
            //1console.log(r);
            setTimeout(() => {
                setLoading('')
            }, 3000);
        }).catch(e=>{
            //1console.log(e);
            setError('Error occurred while saving winners.')
            setTimeout(() => {
                setError('')
            }, 3000);
        })
    }
    return (


        <div style={{minWidth: adminMinWidth+'px'}}>
            <div className='error-banner' style={{display: error===''?'none':'block'}}>
                <p style={{margin:0}}>{error}</p>
            </div>
            
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                    <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%', height:'92vh', overflow:'hidden'}}>
                <AdminNav style={{width: navExpanded?'16%':0}} active={0}/>

            <section className='dashboard-root-a' style={{...props.style, width: navExpanded?'84%':'100%'}}>
            
            <div className='dash-split'>



                <div className='dash-s1' >

                    <h3 className='p-title' style={{textAlign: 'center'}}><span style={{animation:'blink 1s infinite', fontSize:'20px'}}>{runningEventId===null?'':'🔴'}</span>{runningEventId===null?'No Event Running':'Running Event: '+runningEventName}</h3>

                    <div className='dash-s1-panel-split-s1'>
                        <div className='dash-s1-panel-split-s1-1'>
                        {/* main event analytics */}
                            <p className='panel-title'>Main Event Analytics</p>
                            
                            <div className='panel-row'>
                                <p>Total Users Online Now</p>
                                <p>Total Invited Users</p>
                                <p>Average Engagement Time</p>
                                
                                
                           
                            </div>
                            <div className='panel-row'>
                                <div className='circle-num' style={{fontSize: fixfontsize(85,runningEventOnlineUsers)}}>{runningEventOnlineUsers}</div>
                                <div className='circle-num-data' style={{fontSize: fixfontsize(85,runningEventTotalInvitees)}}>{runningEventTotalInvitees?runningEventTotalInvitees:0}</div>
                                <div className='circle-num-text'><p style={{fontSize: fixfontsize(75, 100)}}>{runningEventEngagementTime===null?0:parseInt(runningEventEngagementTime)/60>=60?(parseInt(runningEventEngagementTime)/3600).toFixed(2):(parseInt(runningEventEngagementTime)/60).toFixed(1) }</p><p>{parseInt(runningEventEngagementTime)/60>=60?'Hour(s)':'Minute(s)'}</p></div>
                            </div>
                            <div className='panel-row'>
                                {/* <a  className='a-link'>View More Analytics</a> */}
                                {/* <span><input type='checkbox' checked={activateAnalysis} onChange={e=>setActivateAnalysis(e.target.checked)}></input>Activate Analysis</span> */}
                                {
                                    analysisActive?
                                    <p>Event analytics are being recorded.</p>
                                    :
                                    <button className='primary-btn' onClick={onActivateAnalysis} style={{display: runningEventId===null?'none':'block'}}>Activate Analysis</button>
                                }
                            </div>
                            
                            
                        </div>
                        <div className='dash-s1-panel-split-s1-2'>
                        {/* button clicks */}
                        <p className='panel-title'>Button Clicks</p>
                            <div className='a-table'>
                            
                                <div className='a-t-tb'>
                                <ul className='a-t-th'>
                                    <li>Button</li>
                                    <li>Clicks</li>
                                    
                                </ul>
                                    {//running event button clicks
                                        runningEventButtonClicks===null||runningEventButtonClicks===[]?
                                        'No button clicks found'
                                        :
                                        runningEventButtonClicks?.map((s,i)=>
                                            <ul className='a-t-tr' key={'a-t-cell-'+i}>
                                                <li>{s.label}</li>
                                                <li>{s.count}</li>
                                                
                                            </ul>  
                                        )
                                    }
                                </div>
                                <div className='overlay-a-table'></div>
                            </div>
                        </div>

                        {/* <PanelTable title={'Title'} headers={['h1','h2','h3']} data={[{d1: '1d1',d2:'1d2', d3:'1d3'},{d1: '1d1',d2:'1d2', d3:'1d3'},{d1: '1d1',d2:'1d2', d3:'1d3'}]} cols={3}/> */}
                    </div>
                    
                    
                    
                    <div className='dash-s1-panel-split-s2'>
                        <div className='dash-s1-panel-split-s2-1'>
                        
                        <p className='panel-title'>Invitee Errors</p>

                        <ul className='a-userlist'>
                            {runningEventInviteeErrors?.map((s,i)=>
                                <li>
                                    <p>{s?.phone}</p>
                                    <div style={{display: 'flex'}}>
                                        <button className='primary-btn' onClick={()=>onInviteeConfirm(i)}>Confirm</button>
                                        <button className='warning-btn' onClick={()=>onInviteeDeny(i)}>Deny</button>
                                        
                                    </div>
                                </li>
                            )}
                        </ul>
                        </div>
                        <div className='dash-s1-panel-split-s2-2'>
                        
                            <p className='panel-title'>Blocked Users (Chat)</p>
                            <ul className='a-userlist'>
                                <p>{runningEventBlockedUsers?'':'No blocked users found.'}</p>
                                {
                                    //running event blocked users
                                    
                                    runningEventBlockedUsers?.map((s,i)=>
                                        <li>
                                            <p>{s.name}</p>
                                            <div style={{display: 'flex'}}>
                                                <button className='primary-btn' onClick={()=>onUserUnblock(s.id)}>Unblock</button>
                                                
                                                
                                            </div>
                                        </li>
                                )}
                            </ul>
                        </div>
                    </div>


                    {/* <div className='dash-s1-panel-split-s2'>
                       
                        <div className='panel-style' style={{width: '30%', height: '100%', backgroundColor: '#F7F2EC', justifyContent: 'flex-start'}}>
                            
                            <p className='panel-title' style={{marginBottom: '20%', marginTop: '15%'}}>Total Invited Users</p>
                            <div className='circle-num-data' style={{fontSize: fixFontSize(85,runningEventTotalInvitees)}}>{runningEventTotalInvitees?runningEventTotalInvitees:0}</div>
                                
                        </div>
                        
                    </div> */}

                    {/* winners table */}
                    <div className='a-table' style={{ height: '400px', overflowY: 'auto', marginTop:0, borderRadius: '15px', backgroundColor:'#F7F2EC', boxShadow: '0 3px 10px 0 rgba(0,0,0, 0.3)', marginBottom: '5%', width: '100%'}}>
                        <p className='p-title' style={{textAlign: 'center', borderBottom: '1px solid black', marginRight:'auto', marginLeft:'auto', width:'fit-content', marginTop:0}}>Winners</p>
                        

                        <div style={{height: '55%', overflow: 'auto'}}>
                            {
                                runningEventWinners?.map((w,i)=>
                                    
                                    <div className='row' style={{width: '100%', justifyContent: 'space-around'}}>
                                        <p style={{width: '60%', marginTop:0}}>{(i+1)+'- '+w}</p>
                                        <button className='warning-btn' onClick={()=>removeWinner(i)} style={{padding: '0.2rem 0.4rem'}}>Remove</button>
                                    </div>    
                                )
                            }

                        </div>
                        
                        <div className='row' style={{width: '100%', justifyContent: 'space-around'}}>
                            <div className='form'>
                                <Input name='Winner' value={newWinner} onChange={(e)=>setNewWinner(e.target.value)} compress2={true} style={{margin:0}}/>
                            </div>
                            
                            <div className='row' style={{width:'30%'}}>
                                <button className='primary-btn' onClick={addWinner}>Add</button>
                                <button className='warning-btn' onClick={()=>setRunningEventWinners([])}style={{padding:'0.3rem 1rem', marginLeft:'5%'}}>Clear All</button>
                            </div>
                            
                            
                        </div>

                        <div className='col'  style={{width: '100%'}}>
                            <p className='loading'>{loading}</p>
                            <button className='primary-btn' onClick={saveWinners}>Save Winners</button>
                            
                        </div>
                        
                        
                    </div>


                    <div className='a-table' style={{ height: '400px', overflowY: 'auto', marginTop:0, borderRadius: '15px', backgroundColor:'#F7F2EC', boxShadow: '0 3px 10px 0 rgba(0,0,0, 0.3)', marginBottom: '5%', width: '100%'}}>
                            <p className='p-title' style={{textAlign: 'center', borderBottom: '1px solid black', marginRight:'auto', marginLeft:'auto', width:'fit-content'}}>Deleted Texts</p>
                        <div className='a-t-tb' style={{marginTop: 0}}>
                            <ul className='a-t-th' style={{backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr '}}>
                                <li style={{color: '#155473', fontSize: '1.2rem'}}>Message</li>
                                
                                
                                
                                
                            </ul>
                                {
                                    runningEventDeletedChats?.map(r=><p style={{borderBottom: '1px solid rgba(0,0,0,0.2)'}}>{r.d1}</p>)||'No deleted Chats'
                                }
                        </div>
                        <div style={{display: 'grid',gridTemplateColumns: '1fr 1fr 0.5fr' }}>
                            <div></div>
                            <div></div>
                            
                        </div>
                        
                    </div>

                    {/* 
                        ===============================LIST OF ATTENDING USERS===============================
                    
                    */}

                    <div className='a-table' style={{ height: '500px', overflowY: 'auto', marginTop:0, borderRadius: '15px', backgroundColor:'#F7F2EC', boxShadow: '0 3px 10px 0 rgba(0,0,0, 0.3)', marginBottom: '5%', width: '100%'}}>
                            <p className='p-title' style={{textAlign: 'center', borderBottom: '1px solid black', marginRight:'auto', marginLeft:'auto', width:'fit-content'}}>List of Attending Users</p>
                        <div className='a-t-tb' style={{marginTop: 0}}>
                            <ul className='a-t-th' style={{backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
                                <li style={{color: '#155473'}}>Name</li>
                                <li style={{color: '#155473'}}>Mobile Number</li>
                                <li style={{color: '#155473'}}>Email</li>
                                <li style={{color: '#155473'}}>Country</li>
                                <li style={{color: '#155473'}}>Clinical Region</li>
                                <li style={{color: '#155473'}}>Address</li>
                                <li style={{color: '#155473'}}>Time of Registration</li>
                                <li style={{color: '#155473'}}>Generated Code</li>
                                
                                
                            </ul>
                                {
                                    tableElements
                                }
                            </div>
                            <div style={{display: 'grid',gridTemplateColumns: '1fr 1fr 0.5fr' }}>
                                <div></div>
                                <div></div>
                                
                        </div>
                        
                    </div>
                    
                    <div className='confirm-overlay' style={{display: showEndEventOverlay?'flex':'none', position:'absolute', zIndex: '10', background: 'rgba(0,0,0,0.2)', width: '100%', height:'100vh', top:0, left:0, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <div className='confirm-container' style={{width:'60%', height: '60%',  display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', background: 'rgba(255,255,255,1)',}}>
                            <p style={{fontSize:'2rem', fontWeight:'bold', marginBottom:'0'}}>Are you sure you want to end this event?</p>
                            <p style={{fontSize:'2rem', fontWeight:'bold', marginBottom:'10%'}}>This action cannot be undone.</p>
                            <div className='row'style={{width: '80%', height:'auto', justifyContent:'space-around'}}>
                                <button className='primary-btn'style={{padding:'0.8rem 3rem', width:'200px'}} onClick={()=>onEndRunningEvent()}>End Event</button>
                                <button className='warning-btn' style={{width:'200px'}} onClick={()=>setShowEndEventOverlay(false)}>Cancel</button>
                            </div>
                        </div>
                    
                    </div>
                    <button className='warning-btn' onClick={()=>setShowEndEventOverlay(true)} style={{display: runningEventId===null?'none':'block'}}>End Running Event</button>
                    <div className='error-toast' style={{animation: showToast?'fadeIn 1s 1':'fadeOut 1s 1', display:showToast?'flex':'none'}}>
                        <p>{error}</p>
                    </div>


                    
                    

                </div>

                







                <div className='dash-s2'>
                    <h3 className='p-title'>General</h3>
                    
                        <div className='dash-s2-s1'>
                        {/* General Platform Analytics */}
                            <p className='panel-title' style={{margin: 0}}>General Platform Analytics</p>
                                
                                <div className='panel-row' style={{textAlign:'center'}}>
                                    <p style={{width:'50%'}}>Total Active Users</p>
                                    <p style={{width:'50%'}}>Average Engagement Time</p>
                                </div>
                                <div className='panel-row'>
                                    <div className='circle-num' style={{fontSize: fixfontsize(85,genTotalUsers)}}>{genTotalUsers}</div>
                                    <div className='circle-num-text'><p style={{fontSize: fixfontsize(75, 1000)}}>{genAvgEngagementTime===null?0:parseInt(genAvgEngagementTime)/60>=60?(parseInt(genAvgEngagementTime)/3600).toFixed(2):(parseInt(genAvgEngagementTime)/60).toFixed(1) }</p><p>{parseInt(genAvgEngagementTime)/60>=60?'Hour(s)':'Minute(s)'}</p></div>
                                </div>
                                <div className='panel-row'>
                                    <a href='/admin/general' className='a-link'>View More Analytics</a>
                                    
                                </div>
                        </div>
                        <div className='dash-s2-s2'style={{justifyContent: 'flex-start'}}>
                        {/* Previous Events */}
                            <p className='panel-title'>Previous Events</p>
                            <div className='a-table' style={{width:'100%'}}>
                            
                                <div className='a-t-tb' >
                                <ul className='a-t-th'>
                                    <li style={{fontSize: '9px'}}>Event</li>
                                    <li style={{fontSize: '8px'}}>Attendees</li>
                                    <li style={{fontSize: '9px'}}>Duration</li>
                                    <li style={{fontSize: '9px'}}>Engagement</li>
                                    
                                </ul>
                                {genPrevEvents.map((s, i) => {
                                    let time = null;
                                    if (s.end_datetime) {
                                        time = Math.abs(Math.ceil((new Date(s.end_datetime.slice(0, -7)) - new Date(s.event_datetime.slice(0, -7))) / (1000 * 60 * 60 * 24)));
                                    }

                                    return <ul className='a-t-tr' key={'a-t-cell-' + i}>
                                        <li>{s.title} <p style={{ color: '#32AAA9', fontSize: '10px', margin: 0 }}>{s.date}</p></li>
                                        <li>{s.total_users}</li>
                                        <li>{time ? `${time} d` : '-'}</li>
                                        <li>{(s.average_engagement_time / 3600).toFixed(2)} hr(s)</li>
                                    </ul>
                                })}
                                </div>
                                <div className='overlay-a-table'></div>
                            </div>
                            
                        </div>

                        <a href='/admin/general' className='a-link'>View More Analytics</a>

                </div>
            </div>
        </section>
    
    

            </div>
            
        </div>

    )
}

export default AdminDashboard
