import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getDummyId, removeDummyId } from '../Utils/Common'
import { tourLink } from '../config'
import { baseAPI } from '../config'
function Tour(props) {
    useEffect(() => {
        document.title = "L'Oréal Expo - Tour"
        axios.get(baseAPI+'/api/app/control').then(r=>{
      
            if(r.data){
                props.history.push('/archive')
            }
        }).catch(e=>{
            props.history.push('/archive')
        })
        if(getDummyId()){
            // //1console.log('we have dummy');

        }
        else{
            props.history.push('/login')
        }


        // window.onbeforeunload = function(){
        //     // Do something
        //     let dumId = getDummyId()

        //     axios.post(baseAPI+'/api/user/setExit', {dummy_id:dumId}).then(r=>{
        //         //1console.log(r);
        //         removeDummyId()
        //     }).catch(e=>{
        //         //1console.log(e);
        //     })
        // }
    }, [])
    return (
        <div style={{height:'100vh'}}>
            <iframe src={tourLink}  style={{width:'100%', height: '100vh', border:'none', overflow:'hidden'}}/>
        </div>
    )
}

export default Tour
