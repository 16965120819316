import React, { useEffect, useState, useRef } from 'react'
import downIcon from '../img-src/icons/down-arrow.png';
import rightIcon from '../img-src/icons/right.png';
import infoIcon from '../img-src/icons/info.png';
import homeIcon from '../img-src/icons/home.png';
import micIcon from '../img-src/icons/mic.png';
import giftIcon from '../img-src/icons/gift.png';
import handoutIcon from '../img-src/icons/handout.png';
import logoutIcon from '../img-src/icons/logout.png';
import progressioIcon from '../img-src/icons/progressio.png';
import closeIcon from '../img-src/icons/close.png';
import menuIcon from '../img-src/icons/menu-icon.png';
import chatIcon from '../img-src/icons/right-arrow.png';
import chatIcon1 from '../img-src/icons/chat-icon.png';
import bgStage from '../img-src/aud-near.jpg';
import bgStageFar from '../img-src/aud-far.jpg';
import sendBtn from '../img-src/icons/send-btn.png';
import ACDExpoIcon from '../img-src/loreal-expo-logo.png';
import edermsLogo from '../img-src/EDerm/EDerm Logo.png';
import winnersIcon from '../img-src/icons/trophy.png';
import logo from '../img-src/loreal-expo-logo.png';
import moreicon from '../img-src/icons/more.png'
import agendaicon from '../img-src/icons/agenda.png'
import emojiIcon from '../img-src/icons/emoji.png'
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { io } from 'socket.io-client';
import ReactGA from 'react-ga';
import {getAdminId, getToken, getUser, removeUserSession, setUserSession} from '../Utils/Common';
import axios from 'axios';
import { socketObj, initSocket } from './service/socket';

import Speakers from './landing/Speakers';
import placeholderImg from '../img-src/EDerm/stars.png';

import { func } from 'prop-types';
import Handouts from './landing/Handouts';
import Agenda from './landing/Agenda';
import Giveaways from './landing/Giveaways';
import AuditoriumFeedbackForm from './AuditoriumFeedbackForm';
import Countdown from "react-countdown";
import loader from '../img-src/icons/loader.gif'
import { audCDHeight, audCDLeft, audCDTop, audCDWidth, audheight, audleft, audtop, audwidth, baseAPI, chatBtnRight, mobileWidth, mobileWidthThreshold, winnerBtnLeft } from '../config';
function Auditorium(props) {
    
    const [streamLive, setstreamLive] = useState(true);
    const [mobileNavClosed, setMobileNavClosed] = useState(false);
    const [chatExpanded, setChatExpanded] = useState(false);
    const [winnersExpanded, setWinnersExpanded] = useState(false)
    const [showWinners, setshowWinners] = useState(false);
    const [streamLink, setStreamLink] = useState('')
    const [isMobile, setIsMobile] = useState(false)

    const [hideNav, setHideNav] = useState(true)
    const [showPopUpForm, setShowPopUpForm] = useState(false);
    const [mobileVersion, setMobileVersion] = useState(false);
    const [videoLink, setVideoLink] = useState('')
    const [timeWatched, setTimeWatched] = useState(0);
    
    const streamLinkRefreshDelta = 60000;
    const openDateRefreshDelta = 120000;
    
    const [hideOverlay, setHideOverlay] = useState(true);
    const [showInfo, setShowInfo] = useState(false)
    const [showSpeakers, setShowSpeakers] = useState(false)
    const [showGiveaways, setShowGiveaways] = useState(false)
    const [showHandouts, setShowHandouts] = useState(false)
    const [showAgenda, setShowAgenda] = useState(false)
    

    const [retrievedShowSpeakers, setretrievedShowSpeakers] = useState(false)
    const [retrievedShowAgenda, setretrievedShowAgenda] = useState(false)
    const [retrievedShowGiveaways, setretrievedShowGiveaways] = useState(false)
    const [retrievedShowHandouts, setretrievedShowHandouts] = useState(false)

    const [contextTarget, setContextTarget] = useState(null);
    //display name for user in chat
    const [chatterName, setChatterName] = useState('');
    const [noEventRunning, setNoEventRunning] = useState(true)
    const chatOverlay = useRef(null);
    const [speakers, setSpeakers] = useState([''])
    const [agendaItems, setAgendaItems] = useState([''])
    const [handouts, setHandouts] = useState([''])
    const [giveaways, setGiveaways] = useState([''])
    const [feedbackFormActivated, setFeedbackFormActivated] = useState(false)

    const [eventLogo, setEventLogo] = useState('')


    //stream metadata
    const [eventTitle, setEventTitle] = useState('')
    const [showEventName, setShowEventName] = useState(false)
    const [eventLink, setEventLink] = useState('')
    const [eventDescription, setEventDescription] = useState('')
    const [openTime, setOpenTime] = useState('')
    const [openDate, setOpenDate] = useState('')
    const [eventId, setEventId] = useState(null)
    const [chatOpenTime, setChatOpenTime] = useState('')

    const [userRole, setUserRole] = useState('user')
    const [eventPayload, setEventPayload] = useState('')

    const [userId, setUserId] = useState(null)
    //init chat socket
    const [socket, setSocket] = useState(null)
    const [chatInfo, setChatInfo] = useState('')
    const [mockChat, setMockChat] = useState(null)
    const [newMsg, setNewMsg] = useState('')
    const [delMsg, setDelMsg] = useState('')
    const [chatError, setChatError] = useState('')
    //handle replies
    const [replyee, setReplyee] = useState('')



    const [activateAnalysis, setActivateAnalysis] = useState(false)
    const [activateChat, setActivateChat] = useState(false)
    const [activateWinners, setActivateWinners] = useState(false)
    const [showEmojiMenu, setShowEmojiMenu] = useState(false)

    const [loading, setLoading] = useState('')
    const [splashText, setSplashText] = useState('')




    //winners
    const [winners, setWinners] = useState('');
    const [showMobileWinners, setshowMobileWinners] = useState(false);
    const refreshWinnersDelta = 60000;

    // on new msg change (get a new message), update local chat and rerender
    useEffect(()=>{
        // console.log(newMsg);
        // console.log(mockChat);
        let allmsgs = mockChat;
        allmsgs?.push({
            id: newMsg.message_block?.id, 
            access: newMsg.message_block?.sender_role, 
            name: newMsg?.user?.name, 
            message:newMsg.message_block?.message, 
            replyId: newMsg.message_block?.reply_to
        })
        // console.log(allmsgs);
        setMockChat(allmsgs)


        let orderByNewest = allmsgs;
        // orderByNewest?.reverse()
        // renderChat(orderByNewest);
        // console.log('%c mockchat',ch, orderByNewest);
        setChatContent(
            orderByNewest?.map((m,i)=>
        <div className={'msg-item'+(m.self?' my-msg':'')} key={i} id={'msg-'+m.id}>
            {userRole==='user'?'':<button className='img-btn' onClick={e=>handleAdminContextMenu(e)}><img src={moreicon} className='admin-more-icon'/></button>}
            <div className='icon' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>{getInitials(m.name)}</div>
            <div className='msg-content'>
                <p className='modifier'>{m.access==='user'?'':m.access}</p>
                <div className='msg-text-wrapper' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                    <p className='sender-name'>{m.name}</p>
                    <p className='msg-text'>{m.message}</p>
                    {
                        m.replyId?
                        <div className='msg-text-wrapper' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                            <p className='sender-name'>{mockChat.filter(r=>r.id===m.replyId)[0]?.name}</p>
                            <p className='msg-text'>{mockChat.filter(r=>r.id===m.replyId)[0]?.message}</p>
            
                        </div>
                        :''
                    }
            
                </div>
            </div>
        
        </div>));




    }, [newMsg])
    // on delete message, update local chat and rerender
    useEffect(()=>{
        console.log('id to remove',delMsg);
        console.log(mockChat);
        let orderByNewest = mockChat;
        // orderByNewest?.reverse()
        // renderChat(orderByNewest);
        let indexToRemove = orderByNewest?.findIndex(c=>c.id===parseInt(delMsg.message_id) )
        console.log(indexToRemove);
        orderByNewest?.splice(indexToRemove,1)
        // console.log('%c mockchat',ch, orderByNewest);
        setChatContent(
            orderByNewest?.map((m,i)=>
        <div className={'msg-item'+(m.self?' my-msg':'')} key={i} id={'msg-'+m.id}>
            {userRole==='user'?'':<button className='img-btn' onClick={e=>handleAdminContextMenu(e)}><img src={moreicon} className='admin-more-icon'/></button>}
            <div className='icon' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>{getInitials(m?.name)}</div>
            <div className='msg-content'>
                <p className='modifier'>{m.access==='user'?'':m.access}</p>
                <div className='msg-text-wrapper' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                    <p className='sender-name'>{m?.name}</p>
                    <p className='msg-text'>{m?.message}</p>
                    {
                        m.replyId?
                        <div className='msg-text-wrapper' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                            <p className='sender-name'>{mockChat.filter(r=>r.id===m.replyId)[0]?.name}</p>
                            <p className='msg-text'>{mockChat.filter(r=>r.id===m.replyId)[0]?.message}</p>
            
                        </div>
                        :''
                    }
            
                </div>
            </div>
        
        </div>));




    }, [delMsg])




    

    // set user role
    function setRole(payload){
        axios.get(baseAPI+'/api/admin/getAll', {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            console.log('verified admin');
            setUserRole('admin')
            
            return
        }).catch(e=>{
            //not admin
            if(e.response.status===401){
                //is mod?
                var moderator = payload[0]?.moderators?.filter(m=>m.phone===getUser())
                console.log(moderator);
                //if no mods found, then this is a user
                setUserRole(moderator.length===0?'user':'moderator')
                return
            }
        })
    }


    //handle ui requests
    function handleExpandChat(){
        setChatExpanded(!chatExpanded);
    }
    function handleExpandWinners(){
       setWinnersExpanded(!winnersExpanded)
    }
    function handleExpandMenu(e){
        e.preventDefault();
        setMobileNavClosed(!mobileNavClosed);
    }
    //add engagement time analytics
    function addWatchTime(userId, eventId){
        // if(userId===null||eventId===null)
        //     return
        if(!activateAnalysis)
            return


        console.log({
            "user_id": userId,
            "event_id":eventId,
            "time": 120
        
        });
        axios.post(baseAPI+'/api/analysis/addEngagementTime', {
            "user_id": userId,
            "event_id":eventId,
            "time": 120
        
        }, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>console.log(r)).catch(e=>console.log(e.response))
        
    }


    
    

    const handleResize = () => {
        if(window.screen.width<=mobileWidth){
            setIsMobile(true)
        }
        else{
            setIsMobile(false)
        }
      }
    useEffect(()=>{
        if(!socket) setSocket(initSocket);
        socket?.on("connect", () => {
            console.log("connected", socket?.id);
        })
        socket?.on('receive', (message) => {
            setNewMsg(message)
        })
        socket?.on('deleteBC', (message_id) => {
            console.log(message_id);
            setDelMsg(message_id)
        })
        socket?.on("disconnected", (error) => {
            console.log("socket disconnected", error);
        });
        socket?.on("error_message", (message) => {
            console.log("socket error message from server", message);
        });
       
        socket?.on("connect_error", (error) => {
            console.log(error);
        });
        return ()=>{socket?.disconnect();}
    },[socket])
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
        document.title = "L'Oréal Expo - Auditorium"
        if(window.screen.width<=mobileWidth){
            setIsMobile(true)
        }
        axios.get(baseAPI+'/api/app/control').then(r=>{
      
            if(r.data){
                props.history.push('/archive')
            }
        }).catch(e=>{
            props.history.push('/archive')

            
        })
       
        ReactGA.initialize('UA-183483789-3');
        ReactGA.pageview(window.location.pathname + window.location.search);
       
        document.title = "L'Oréal Expo - Auditorium"
        
        if(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 600 || Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) <=600)
            setChatExpanded(true)




        
        //add before window close event listener
        
        
        //check if link changed
        var linkCheckInterval = setInterval(() => {
            checkStreamLinkChanged()
        }, streamLinkRefreshDelta);


        
        //get winners every n seconds
        var winnersInterval = setInterval(() => {
            getWinners();
        }, refreshWinnersDelta);

        //check open date time every n seconds
        var openDateInterval = setInterval(() => {
            getOpenDate();
        }, openDateRefreshDelta);

        // checkStreamLinkChanged()
        //close context menu on click outside
        document.addEventListener('click', (e)=>{
                console.log(e.target.className);
                // if(e.target.className==='chat-context-menu' || e.target.className==='chat-context-menu-items' || e.target.className==='chat-context-menu-item' || e.target.className==='admin-more-icon' || e.target.className==='img-btn')
                if(e.target.className==='admin-more-icon' || e.target.className==='img-btn' || e.target.className==='chat-context-menu-item')
                    var x;
                else
                    try{
                        chatContextMenu.current.style.display='none';
                        setShowBlockConfirmation(false);
                    }
                    catch{

                    }
                    
            
            
        })


        //check if event running
        isEventRunning()
        return () =>{
            clearInterval(winnersInterval);
            clearInterval(linkCheckInterval);
            clearInterval(openDateInterval);
            // clearInterval(addEngTimeInterval)
            // addWatchTime(userId, eventId)
            // socketObj.disconnect();
            
            
        }
    }, [])
    
    function getOpenDate(){
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            setOpenDate(new Date() < new Date(r.data.event_datetime))
        }).then(e=>{
            console.log(e);
        })
    }

   useEffect(()=>{
        if(userId!==null&&eventId!==null)
            //send engagement time every 2 mins
            var addEngTimeInterval = setInterval(() => {
                addWatchTime(userId,eventId)
            }, 2*60000);
   }, [userId, eventId])

    useEffect(() => {
        setTimeout(() => {
            setTimeWatched(timeWatched+1)
            
        }, 1000);
        
    }, [timeWatched])

    
    
    
    function incrementViews(){
        // axios.post(baseAPI+'/api/videos/view').then(r=>{}).catch(e=>{
        //     console.log('Error while adding view');
        // })
    }
    
    function checkStreamLinkChanged(){
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            // var id = r.data.link.substring(r.data.url.indexOf('watch?v=')+8)
            // console.log(streamLink);
            // console.log('%c CHEKC RUNNING','font-size:50px',r.data.link, streamLink);
            
            console.log(r.data.link);
            // let id = ''
            // if(r.data.link.includes('youtube'))
            // {
            //     setVideoType('youtube')
            //     id = getYoutubeVideoId(r.data.link)
            // }
               
            // else{
            //     setVideoType('vimeo')
            //     id = getVimeoVideoId(r.data.link)
            // }
            // if(r.data.link.includes('microsoftstream'))
            // {
            //     setVideoType('teams')
            //     id = getYoutubeVideoId(r.data.link)
            // }
            // // console.log(id, streamLink);
            // if(id !== streamLink){
            //     // console.log('%clink changed', 'color: red');
            //     setStreamLink(id);
            // }

            let id = getStreamIframeLink(r.data.link)
            if(id !== videoLink){
                // console.log('%clink changed', 'color: red');
                setVideoLink(id);
            }




            
            
        }).catch(e=>{
            console.log('Error while getting new stream link', e);
        })
    }
    
    function logout(e) {
        // {headers: {'Authorization': 'bearer ' + getToken()}}
        e.preventDefault();
        removeUserSession();
        window.location.href = '/';
    }
    function hidePreStream(){
        document.body.style.overflow = 'hidden';
        document.querySelector('.aud-far').classList.add('fadeOut');
        incrementViews();
        setTimeout(() => {
            document.querySelector('.aud-far').style.display = 'none';
            document.body.style.overflow = 'unset';
        }, 1900);
    }
    function showPopUpFormFn(e){
        // e.preventDefault()
        // addWatchTime(userId, eventId)
        
        setShowPopUpForm(true)
    }

    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;
    const renderTime = (dimension, time) => {
        return (
          <div className="time-wrapper">
            <div className="time">{time}</div>
            <div>{dimension}</div>
          </div>
        );
      };
    const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6
    };
    const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time) => (time / daySeconds) | 0;

    
    const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = +new Date(openTime) /1000; // use UNIX timestamp in seconds

    const remainingTime = endTime - stratTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;


    
    const [chatMsg, setChatMsg] = useState('');
    const [chatContent, setChatContent] = useState();
    const chatContextMenu = useRef(null)
    useEffect(() => {
        if(!noEventRunning)
            document.querySelector('.chat .messages').scrollTop = document.querySelector('.chat .messages').scrollHeight
        
       
            
        
    }, [chatContent])
    function onSendChat(e){
        if(chatMsg==='')
            return
        setChatMsg('');
        setReplyee('')
        _MOCKSENDNEWCHAT_(chatMsg)
        
        
    }
    function getInitials(fullName) {
        
        if(fullName){
            var firstInitial = fullName?.toString()?.substr(0,1).toUpperCase();
            var secondInitial = fullName?.toString()?.split(' ', 2)[1]?fullName?.split(' ', 2)[1].substr(0,1).toUpperCase():'';
    
            return firstInitial+secondInitial;
        }
       
    }

    function handleAdminContextMenu(e){
        e.preventDefault()
        console.log(e);
        setContextTarget(e)
        chatContextMenu.current.focus();
        chatContextMenu.current.style.display= 'block';


        console.log(e.pageY, window.screen.width);
        if(window.screen.width<=500){
            chatContextMenu.current.style.top = e.pageY-50 + 'px';
            chatContextMenu.current.style.left = e.pageX-70 + 'px';
            
        }
        else{
            chatContextMenu.current.style.top = e.pageY + 'px';
            chatContextMenu.current.style.left = e.pageX + 'px';
            
        }
        // chatContextMenu.current.style.top = e.pageY + 'px';
        // chatContextMenu.current.style.left = e.pageX + 'px';
        
    }
    
    function onReplyTo(){
        console.log(contextTarget.target.parentNode.parentNode.id.replace('msg-',''));
        setReplyee(contextTarget.target.parentNode.parentNode.id.replace('msg-',''))
    }
    function onDeleteMsg(){
        let msgId =contextTarget.target.parentNode.parentNode.id.replace('msg-','')
        // console.log('%c ondel msgid', 'color:purple',msgId);
        socket.emit('delete',
            {
             message_id: msgId
            
            })
            
    }
    function onBlockUser(){
        let msgId = contextTarget.target.parentNode.parentNode.id.replace('msg-','')
        console.log(msgId);
        console.log(mockChat.find(m=>m.id===parseInt(msgId)).userId);

        axios.put(baseAPI+'/api/event/chat/block',
        
            {
                "user_id":mockChat.find(m=>m.id===parseInt(msgId)).userId,
                "event_id":eventId
            },
            {
                headers:{
                    'Authorization': 'bearer '+getToken()
                }
            }

        ).then(r=>{
            console.log('user blocked', r.data);
            setChatError('User blocked successfully')
            setTimeout(() => {
                setChatError('')
            }, 3000);
        }).catch(e=>{
            console.log('failed to block user', e.response);
            setChatError('Failed to block user')
            setTimeout(() => {
                setChatError('')
            }, 3000);
            
        })
    }
    // useEffect(() => {
        
    //     if(replyee!==''){

    //     }
    // }, [replyee])
    useEffect(()=>{
        console.log('%c eventid','font-size: 30px', eventId);
        if(eventId!==null){
            axios.get(baseAPI+'/api/event/chat/load/'+eventId, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
                // console.log('%c load chat','font-size:30px; color:cornflowerblue',r.data);

                setMockChat(r.data.map(c=>{return{id: c.id, access: c.sender_role, name:c.sender_role==='admin'?c.admin.name:c.user.name, message:c.message, replyId: c.reply_to, userId: c.sender_role==='admin'?c.admin.id:c.user.id}}))
            }).catch(e=>{
                console.log(e)
                if(e.response?.status===404){
                    setMockChat([])
                }
                if(e?.response?.status===401){
                    removeUserSession()
                    props.history.push('/login-acd')
                }
            })
        }


        //get winners when we have an event id
        getWinners();
            
    }, [eventId])
    
    
    const ch = 'font-style: 30px; color:cornflowerblue'
    useEffect(() => {
        console.log(mockChat);
        let orderByNewest = mockChat;
        orderByNewest?.reverse()
        // renderChat(orderByNewest);
        // console.log('%c mockchat',ch, orderByNewest);
        setChatContent(
            orderByNewest?.map((m,i)=>
        <div className={'msg-item'+(m.self?' my-msg':'')} key={i} id={'msg-'+m.id}>
            {userRole==='user'?'':<button className='img-btn' onClick={e=>handleAdminContextMenu(e)}><img src={moreicon} className='admin-more-icon'/></button>}
            <div className='icon' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>{getInitials(m.name)}</div>
            <div className='msg-content'>
                <p className='modifier'>{m.access==='user'?'':m.access}</p>
                <div className='msg-text-wrapper' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                    <p className='sender-name'>{m.name}</p>
                    <p className='msg-text'>{m.message}</p>
                    {
                        m.replyId?
                        <div className='msg-text-wrapper' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                            <p className='sender-name'>{mockChat.filter(r=>r.id===m.replyId)[0]?.name}</p>
                            <p className='msg-text'>{mockChat.filter(r=>r.id===m.replyId)[0]?.message}</p>
            
                        </div>
                        :''
                    }
            
                </div>
            </div>
        
        </div>));
        
    }, [mockChat])
    function renderChat(msgs){
        if(msgs===null)
            return



        setChatContent(
            msgs.map((m,i)=>
        <div className={'msg-item'+(m.self?' my-msg':'')} key={i} id={'msg-'+m.id}>
            {userRole==='user'?'':<button className='img-btn' onClick={e=>handleAdminContextMenu(e)}><img src={moreicon} className='admin-more-icon'/></button>}
            <div className='icon' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>{getInitials(m.name)}</div>
            <div className='msg-content'>
                <p className='modifier'>{userRole==='user'?'':userRole}</p>
                <div className='msg-text-wrapper' style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                    <p className='sender-name'>{m.name}</p>
                    <p className='msg-text'>{m.message}</p>
                    {
                        m.replyId?
                        <div className='msg-text-wrapper'style={{backgroundColor: m.access==='moderator'?'rgba(50, 170, 169, 0.6)':m.access==='admin'?'rgba(216, 113, 82, 0.6)':'rgba(21, 84, 115, 0.6)'}}>
                            <p className='sender-name'>{mockChat.filter(r=>r.id===m.replyId)[0]?.name}</p>
                            <p className='msg-text'>{mockChat.filter(r=>r.id===m.replyId)[0]?.message}</p>
            
                        </div>
                        :''
                    }
            
                </div>
            </div>
        
        </div>));
        
    }
    function _MOCKSENDNEWCHAT_(cm){
        // let temp = mockChat;
        // temp.push({access: 'Chat Admin', name: chatterName, message: cm, self: true})
        // setMockChat(temp)
        

        let msgPayload = {}
        console.log(replyee);
        console.log('user id: ',userId);
        if(replyee!==''){

            if(userRole==='admin'){
                msgPayload = {
                  
                    message: cm,
                    sender_role: userRole,
                    admin_id: userId,
                    event_id: eventId,
                    reply_to: parseInt(replyee) 
                }
            }
            else{
                msgPayload = {
                  
                    message: cm,
                    sender_role: userRole,
                    user_id: userId,
                    event_id: eventId,
                    reply_to: parseInt(replyee) 
                 
                }
            }
            
        }
        else{
            if(userRole==='admin'){
                msgPayload = {
                  
                    message: cm,
                    sender_role: userRole,
                    admin_id: userId,
                    event_id: eventId
                    
                }
            }
            else{
                msgPayload = {
                  
                    message: cm,
                    sender_role: userRole,
                    user_id: userId,
                    event_id: eventId
                    
                 
                }
            }
        }
        console.log('%c Sending this message','font-size:30px;color:green',msgPayload);
        socket.emit("send", msgPayload);
        
        

    }
    function hideAllSpotlightSections(){
        setShowInfo(false);
        setShowSpeakers(false);
        setShowGiveaways(false);
        setShowHandouts(false);
        setShowAgenda(false);
        setShowPopUpForm(false);
    }
    function copyToClipboard(text) {
        var input = document.body.appendChild(document.createElement("input"));
        input.value = text;
        input.focus();
        input.select();
        document.execCommand('copy');
        input.parentNode.removeChild(input);
    }
    
    function isEventRunning(){
        setLoading('Loading...')
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            setLoading('')
            // console.log('%c CHEKC RUNNING','font-size:100px',r.data);
            setNoEventRunning(false)
            setShowEventName(r.data.title_display_choice==='Everywhere')
            var opD =  new Date(r.data.event_datetime)
            opD.setHours(opD.getHours())

            console.log('======================================================');
            console.log(new Date());
            console.log(opD);
            setOpenDate(new Date() < opD)
            //GET EVENT DETAILS
            axios.get(baseAPI+'/api/event/get/'+r.data.id).then(r=>{
                console.log(r.data);
                //get whole event payload
                setEventPayload(r.data)
                setRole(r.data)
                setEventTitle(r.data[0].title)
                setActivateAnalysis(r.data[0].activate_analysis)
                // let id = ''
                // if(r.data[0].link.includes('youtube'))
                // {
                //     setVideoType('youtube')
                //     id = getYoutubeVideoId(r.data[0].link)
                // }
                   
                // else{
                //     setVideoType('vimeo')
                //     id = getVimeoVideoId(r.data[0].link)
                // }
                setVideoLink(getStreamIframeLink(r.data[0].link))    
                
                // console.log('%c CHEKC RUNNING','font-size:100px',id);
                // setStreamLink(id)
                setEventDescription(r.data[0].description)
                setOpenTime(r.data[0].open_datetime)
                setEventLogo(r.data[0].logo)
                setEventId(r.data[0].id)
                setChatOpenTime(r.data[0].chat_activation_time)
                setFeedbackFormActivated(r.data[0].activate_feedback_form)
                setActivateChat(r.data[0].activate_chat)

                setretrievedShowAgenda(r.data[0].activate_agenda)
                setretrievedShowGiveaways(r.data[0].activate_giveaway)
                setretrievedShowHandouts(r.data[0].activate_handout)
                setretrievedShowSpeakers(r.data[0].activate_speaker)

                if(Array.isArray(r.data[0].speakers))
                    setSpeakers(r.data[0].speakers.map((s)=>{return{name: s.name, imgUrl: s.picture, desc: s.bio}}))
                if(Array.isArray(r.data[0].agendas))
                    setAgendaItems(r.data[0].agendas.map((s)=>{console.log('AGENDA START AND END TIMES',s.start_time,s.end_time,s.start_time&&s.end_time&&(s.start_time!==''&&s.end_time!=='')); return{speakerName: s.speaker_name, title: s.topic, 
                        time: 
                            s.start_time&&s.end_time&&(s.start_time!==' '&&s.end_time!==' ')?
                            new Date(s.start_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})+'-'+new Date(s.end_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                            :''
                    }}))
                if(Array.isArray(r.data[0].handouts))
                    setHandouts(r.data[0].handouts.map(h=>{return{title: h.title, imgUrl: h.picture, file:h.file, desc:h.description}}))
                    
                if(Array.isArray(r.data[0].giveaways))
                    setGiveaways(r.data[0].giveaways)


                //set winners
                axios.get(baseAPI+'/api/event/winners/'+r.data.id, {headers: {'Authorization':'bearer '+getToken()}}).then(r=>{
                    console.log(r.data)
                    let retrievedWinners = r.data.map((w,i)=><li key={i}>{w.name}</li>)
        
                    if(retrievedWinners.length===0){
                        setActivateWinners(false)
                    }
                    else{
                        setActivateWinners(true)
                        setWinners(retrievedWinners)
                    }
                    
                }).catch(e=>{
                    console.log(e);
                })
            }).catch(e=>{
                //error
                console.log(e);
            })


            
            if(getAdminId()){
                setUserId(parseInt(getAdminId()))
            }
            else{
                axios.post(baseAPI+'/api/user/virtualLogin', {phone: getUser()}).then(r=>{
                    setChatterName(r.data.name)
                    setUserId(r.data.id)
                    console.log('virtual login', r.data);
                }).catch(e=>{
                    
                    console.log(e.response);
                })
            }

            


        }).catch(e=>{
            setLoading('')
            console.log('eeeee', e.response);
            if(e.response?.data?.message==='No running events'||e.response?.status===404){
                setNoEventRunning(true)
                setSplashText('No event running. Please come back later.')
                axios.get(baseAPI+'/api/event/checkUpcoming').then(r=>{
                    setSplashText('Stay tuned for the upcoming event: ' + r.data.title + '. On ' + new Date(r.data.event_datetime).toLocaleDateString('en-GB'))
                }).catch((error)=>{console.log(error)})
            }

            
        })
    }
    
    
    
    const countdownRenderer = ({days, hours,minutes, seconds, completed})=>{
        if(!completed)
        return  <div className='countdown-timer'>
                    <div className='countdown-timer-circle'><p>{days}</p><p>Day(s)</p></div>
                    <div className='countdown-timer-circle'><p>{hours}</p><p>Hour(s)</p></div>
                    <div className='countdown-timer-circle'><p>{minutes}</p><p>Minute(s)</p></div>
                    <div className='countdown-timer-circle'><p>{seconds}</p><p>Second(s)</p></div>
                </div>
        else
            return  <div className='countdown-timer'><img src={eventLogo} height='60%' /></div>
            
    }
    
    async function saveClickAnalytics(btn, eventId){

        // console.log('save click analytics', btn, eventId, !activateAnalysis);
        if(!activateAnalysis){
            return
        }
            
        

        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn})
            
        }
            

        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId})
    }

    //check if chat is open until it is open
    const [checkIn1, setCheckIn1] = useState(false)
    const [openChat, setOpenChat] = useState(false)
    const [openStream, setOpenStream] = useState(false)

    //timer to update ui without refresh
    useEffect(() => {


            //exit if no need for counter
            if(openStream&&openChat){
                return
            }
                
            
            var now = new Date()
            console.log(now);

            //open chat when open time is here
            // console.log('%cchat open time', 'color:blue;font-size:30px');
                console.log(new Date(chatOpenTime));
                console.log(now >= new Date(chatOpenTime));
            if(now >= new Date(chatOpenTime)){
                if(chatOpenTime!==''){
                    setOpenChat(true)
                    
                }
            }
            
            //show enter stream btn when time is here
            console.log(openTime);
            console.log('open date', new Date(openTime));
            console.log(now >= new Date(openTime));
            if(now >= new Date(openTime)){
                if(openDate!==' '){
                    setOpenStream(true)
                    
                }
            }

            setTimeout(() => {
                setCheckIn1(!checkIn1)
            }, 1000);
        
        
    }, [checkIn1])



    //link source management
    const [videoType, setVideoType] = useState('')
    
    function getYoutubeVideoId(link){
        return link?.substring(link?.indexOf('watch?v=')+8)
    }
    function getVimeoVideoId(link){
        return link?.substring(link?.indexOf('.com/')+5)
    }
    
    const getStreamIframeLink = (link)=>{


        console.log(link);
        if(link?.includes('youtube')){
            return <iframe src={'https://www.youtube.com/embed/'+link?.substring(link?.indexOf('watch?v=')+8,link?.indexOf('watch?v=')+8+11)+'?modestbranding=1&showinfo=0&rel=0&autoplay='+ +openStream+'&playsinline=1'}  frameBorder="0" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" allowFullScreen></iframe>
        }
        if(link?.includes('vimeo')){
            return <iframe src={'https://player.vimeo.com/video/'+ link?.substring(link?.indexOf('.com/')+5) +'?autoplay='+ +openStream +'&title=0&byline=0&portrait=0'} style={{width:'100%', height: '100%'}} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        }
        if(link?.includes('microsoftstream')){
            return <iframe src={link +'?autoplay='+ +openStream +'&showinfo=true'} style={{width:'100%', height: '100%', border:'none'}} frameBorder="0" allowFullScreen></iframe>
        }
                        
                                
                                
    }
    

    const [showBlockConfirmation, setShowBlockConfirmation] = useState(false)


    function getWinners(){
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            axios.get(baseAPI+'/api/event/winners/'+r.data.id, {headers: {'Authorization':'bearer '+getToken()}}).then(r=>{
                console.log(r.data)
                let retrievedWinners = r.data.map((w,i)=><li key={i}>{w.name}</li>)
    
                if(retrievedWinners.length===0){
                    setActivateWinners(false)
                }
                else{
                    setActivateWinners(true)
                    setWinners(retrievedWinners)
                }
                
            }).catch(e=>{
                console.log(e);
            })
        }).then(e=>{
            console.log(e);
        }).catch((error)=>{console.log(error)})
        
    }
    return (
        
        <div className='auditorium'>
            <div className='aud-no-event' style={{display: noEventRunning||openDate?'flex':'none'}}>
                <img src={ACDExpoIcon} />
                {/* <p>{noEventRunning||openDate?'No event running. Please come back later.':'Loading...'}</p> */}
                <p>{splashText}</p>
                <img src={loader} style={{display: loading==='Loading...'?'block':'none', width: 'auto'}} />
                    
            </div>
            <div className='aud-far' >
                <img src={bgStageFar} className='aud-far-bg' />
                <img src={rightIcon} style={{display: openStream?'block':'none'}} className='next ripple' onClick={()=>{saveClickAnalytics('Countdown - Auditourium', eventId).then((r)=>{console.log(r); hidePreStream()})}}/>
                
                
                    <div className='countdown' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', 
                        width:!isMobile&&audCDWidth, 
                        height: !isMobile&&audCDHeight, 
                        top: !isMobile&&audCDTop, 
                        left: !isMobile&&audCDLeft,
                        // minWidth: isMobile&&'347px'
                    }}>
                    {openTime===''?'':<Countdown date={+new Date(openTime).setHours(new Date(openTime).getHours())}  renderer={countdownRenderer}/>}
                
                 
                        
                    </div>
                    
                   
                {/* <div className='nav-prestream'>
                    
                    <div >
                        
                    </div>
                    <div>
                        <img src={homeIcon} onClick={()=>{
                            
                            saveClickAnalytics('Nav Home - Auditourium Countdown', eventId)
                            // if(feedbackFormActivated)
                            if(true)
                            {setHideOverlay(false); showPopUpFormFn()}
                            else{props.history.push('/tour')}}}
                        />
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{
                            saveClickAnalytics('Nav Home - Auditourium Countdown', eventId)
                            // if(feedbackFormActivated)
                            if(true)
                            {setHideOverlay(false); showPopUpFormFn()}
                            else{window.location.href='/tour'}
                        }}>Home</p>
                    
                    </div>
                    
                    
                    <div><p style={{fontSize: '0.8rem', marginLeft: '-50%'}} >Powered By</p><img src={progressioIcon} /></div>
                    
                    <img src={menuIcon} className='burger-icon' onClick={handleExpandMenu}/>
                </div> */}

                <div className='row' style={{height: '10vh', width:'100%', position:'absolute', bottom:0, background:'rgba(0,0,0,0.3'}}>
                <div style={{height:'100%'}}>
                        <img src={homeIcon} height={'60%'} onClick={()=>{
                            
                            saveClickAnalytics('Nav Home - Auditourium Countdown', eventId)
                            
                            props.history.push('/tour')}}
                        />
                        <p style={{width: '100%', textAlign: 'center', margin:0, color:'white'}} onClick={()=>{
                            saveClickAnalytics('Nav Home - Auditourium Countdown', eventId)
                            
                            props.history.push('/tour')
                        }}>Home</p>
                    
                    </div>
                </div>
           
            </div>
            
            <img src={bgStage} className='stage-bg'/>
            

            {/*
            ---------------------------------------STREAM CONTENT---------------------------------------            
            */}
            <div className='stream-content' style={{top: audtop, left: audleft, height: audheight, width: audwidth}}>
                <h1 className='stream-title'>{showEventName?eventTitle:''}</h1>
                
                    <button id='chat-btn' className="expand-btn" onClick={handleExpandChat} style={{display: activateChat?'block':'none', position: 'absolute',right: !chatExpanded?chatBtnRight:'0',top: 0,height: chatExpanded?'15%':'15%',background: chatExpanded?'transparent':'white', zIndex: '3', paddingLeft: chatExpanded?0:'4vw', marginRight: chatExpanded?'0':'8px'}}><img src={chatExpanded?chatIcon:chatIcon1} style={{transform: chatExpanded?'rotate(180deg)':'rotate(0)', zIndex: 1}} height='100%' />
                    </button>

                    
                    {/* remove 0&& */}
                    <button id='winners-btn' className="expand-btn" onClick={handleExpandWinners} style={{display: activateWinners?'block':'none', position: 'absolute',left: !winnersExpanded?winnerBtnLeft:'0',top: 0,height: winnersExpanded?'15%':'15%',background: winnersExpanded?'transparent':'white', zIndex: '3', paddingRight: winnersExpanded?0:'5vw', marginLeft: winnersExpanded?'0.5%':'8px', borderRadius: '15px 0 0 15px '}}><img src={winnersExpanded?chatIcon:winnersIcon} style={{transform: winnersExpanded?'rotate(0deg)':'rotate(0)', zIndex: 1, marginLeft:'1vw'}} height='100%' />
                    </button>


                <div className='chat winners' style={{visibility: winnersExpanded?'visible':'hidden', justifyContent:'flex-start'}}>
                    <p className='title' style={{color:'black', marginTop:'15px', marginBottom:'20px'}}>Winners</p>
                    <ul>
                        {winners}
                    </ul>
                </div>
                <div className='player-container' >
                    
                    
                        {/* '?modestbranding=1&showinfo=0&rel=0&autoplay=0&playsinline=1' */}
                        {/* https://www.youtube.com/watch?v=PtufPgEgDG4 */}

                        {/* {videoType==='youtube'?
                                <iframe src={'https://www.youtube.com/embed/'+streamLink+'?modestbranding=1&showinfo=0&rel=0&autoplay='+ +openStream+'&playsinline=1'}  frameBorder="0" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" allowFullScreen></iframe>
                                :
                                <iframe src={'https://player.vimeo.com/video/'+ streamLink +'?autoplay='+ +openStream +'&title=0&byline=0&portrait=0'} style={{width:'100%', height: '100%'}} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>} */}

                        {videoLink}
                        {/* <iframe src={'https://www.youtube.com/embed/'+streamLink+'?modestbranding=1&showinfo=0&rel=0&autoplay=1&playsinline=1'}  frameBorder="0" sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation" allowFullScreen></iframe> */}
                        {}
                        {//1366 x 768

                        }
                    </div>
                    <div className='chat' style={{visibility: chatExpanded?'visible':'hidden'}}>

                        {/* <div className='messages' style={{position: 'absolute', height: '110%', overflowY: 'hidden', borderRadius: '15px', zIndex:'2'}}>
                            <iframe src="https://minnit.chat/LiveQandA?embed&&nickname=" style={{width:'100%', height: '100%', position: 'absolute'}}  ></iframe>
                        </div> */}
                        <div className='mobile-tabs' style={{display: mobileVersion?'flex':'none'}}>
                            <p onClick={()=>{setshowMobileWinners(false)}} className={showMobileWinners?'':'active'}>Chat</p>
                            <p onClick={()=>{setshowMobileWinners(true)}} className={showMobileWinners?'active':''} style={{display:activateWinners?'flex':'none'}}>Winners</p>
                        </div>
                        <div className='mobile-winners' style={{display: showMobileWinners?'block':'none', top:'30px'}}>
                            <p className='title'>Winners</p>
                            <ul>
                                {winners}
                            </ul>
                        </div>
                        


                        <div className='chat-overlay' style={{display: openChat?'none':'flex'}}>
                            <p className='p-title'>Q&A is not active now.</p>
                            <p className='p-title'>Come back at {new Date(chatOpenTime).toLocaleString('en-GB')}</p>
                        </div>

                        <div className='chat-context-menu' ref={chatContextMenu} >
                            <div className='block-confirmation' style={{display: showBlockConfirmation?'':'none'}}>
                                <p>Are you sure you want to block this user?</p>
                                <div className='row'>
                                    <button className='primary-btn' onClick={()=>onBlockUser()}>Block</button>
                                    <button className='warning-btn' onClick={()=>setShowBlockConfirmation(false)}>Cancel</button>

                                </div>
                            </div>
                            <ul className='chat-context-menu-items'>
                                
                                <li className='chat-context-menu-item' onClick={()=>onReplyTo()}>Reply</li>
                                <li className='chat-context-menu-item' onClick={()=>onDeleteMsg()} style={{display: userRole==='admin'?'block':'none'}}>Delete</li>
                                <li className='chat-context-menu-item' onClick={()=>setShowBlockConfirmation(true)} style={{display: userRole==='admin'?'block':'none'}}>Block User</li>
                            </ul>
                        </div>
                        {/* remove display none */}
                        <div className='header' >
                            <p className='line1'>Live Q&A</p>
                            <p className='line2'>You are a chat {userRole}</p>
                            <span>{chatInfo}</span>
                        </div>
                        {/* remove display none */}
                        <div className='messages' >
                            {chatContent}
                        </div>
                        {/* remove display none */}
                        <div className='send-msg' style={{position:'relative'}}>
                            <div className='emoji-menu' style={{position:'absolute', display: showEmojiMenu?'block':'none'}}>
                                <ul>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'😀');setShowEmojiMenu(false)}}>😀</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'😁');setShowEmojiMenu(false)}}>😁</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'😂');setShowEmojiMenu(false)}}>😂</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'❤️');setShowEmojiMenu(false)}}>❤️</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'😍');setShowEmojiMenu(false)}}>😍</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'🥰');setShowEmojiMenu(false)}}>🥰</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'👍');setShowEmojiMenu(false)}}>👍</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'🌹');setShowEmojiMenu(false)}}>🌹</li>
                                    <li className='emoji-item' onClick={()=>{setChatMsg(chatMsg+'💐');setShowEmojiMenu(false)}}>💐</li>
                                </ul>
                            </div>
                            <span style={{position: 'absolute', top:'2px', left: '5%', fontSize:'0.8rem', color:'green'}}>
                                {
                                    replyee!==''?
                                    'Reply'
                                    :
                                    ''
                                
                                }
                            </span>
                            <span className='info' style={{position: 'absolute', top:0, left: '5%'}}>{chatError}</span>
                            <input type='text' placeholder='Enter your message here.' value={chatMsg} onKeyUp={e=>e.key==='Enter'?onSendChat(e):''} onChange={e=>setChatMsg(e.target.value)} style={{marginLeft:'3%'}}></input>
                            
                            <button  onClick={()=>setShowEmojiMenu(!showEmojiMenu)}style={{height:'100%', display:'flex', alignItems:'center'}}><img src={emojiIcon}  /></button>
                            <button  onClick={()=>onSendChat()}style={{height:'100%', display:'flex', alignItems:'center'}}><img src={sendBtn}  /></button>
                        </div>

                        
                    </div>
            </div>

           

            <div className='nav-overlay-wrapper' style={{display: hideOverlay?'none':'flex', animation: !hideOverlay?'fadeIn 1.5s 1':''}}>
                <div className='nav-spotlight' style={{overflowY:'auto'}}>
                    <button className='close-nav-overlay' style={{display: showPopUpForm?'none':'block'}}  onClick={()=>{setHideOverlay(true); hideAllSpotlightSections()}}><img src={closeIcon}/></button>
                    <div className='spotlight-info' style={{display: showInfo?'flex':'none'}}>
                        <img src={eventLogo} style={{width: '80%'}} />
                        <h2>{eventTitle}</h2>
                        <p style={{textAlign:'center', paddingBottom:'5%'}}>{eventDescription}</p>
                    </div>

                    <Speakers speakers={speakers} style={{display: showSpeakers?'block':'none'}} showTitle={false} />
                    <Handouts handouts={handouts} style={{display: showHandouts?'block':'none'}} showTitle={false}/>
                    <Giveaways giveaways={giveaways} style={{display: showGiveaways?'block':'none'}} showTitle={false}/>
                    <Agenda agendaItems={agendaItems} style={{display: showAgenda?'flex':'none', height: '100%', flexDirection: 'column'}} showTitle={false}/>
                    <AuditoriumFeedbackForm style={{display: showPopUpForm?'flex':'none'}} history={props.history} />

                </div>
                
            </div>
            <div className='auditorium-nav'>
                <nav>
                    
                    <div >
                        <img src={infoIcon} onClick={()=>{saveClickAnalytics('Nav Event Description - Auditourium', eventId).then(()=>{setHideOverlay(false); setShowInfo(true)})}}/>
                    </div>
                    <div>
                        <img src={homeIcon} onClick={()=>{
                            
                            saveClickAnalytics('Nav Home/FeedbackForm - Auditourium', eventId)
                            if(feedbackFormActivated)
                            
                            {setHideOverlay(false); showPopUpFormFn()}
                            else{props.history.push('/tour')}}}
                        />
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{
                            saveClickAnalytics('Nav Home/FeedbackForm - Auditourium', eventId)
                            if(feedbackFormActivated)
                            
                            {setHideOverlay(false); showPopUpFormFn()}
                            else{props.history.push('/tour')}
                        }}>Home</p>
                    
                    </div>
                    <div style={{display: retrievedShowSpeakers?'flex':'none'}} >
                        <img src={micIcon} onClick={()=>{saveClickAnalytics('Nav Speakers - Auditourium', eventId); setHideOverlay(false); setShowSpeakers(true)}}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{saveClickAnalytics('Nav Speakers - Auditourium', eventId);setHideOverlay(false); setShowSpeakers(true);}}>Speakers</p>
                    </div>
                    <div style={{display: retrievedShowGiveaways?'flex':'none'}} >
                        <img src={giftIcon} onClick={()=>{saveClickAnalytics('Nav Giveaways - Auditourium', eventId);setHideOverlay(false); setShowGiveaways(true)}}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{saveClickAnalytics('Nav Giveaways - Auditourium', eventId);setHideOverlay(false); setShowGiveaways(true)}}>Giveaways</p>
                    </div>
                    <div style={{display: retrievedShowHandouts?'flex':'none'}} >
                        <img src={handoutIcon} onClick={()=>{saveClickAnalytics('Nav Handouts - Auditourium', eventId);setHideOverlay(false); setShowHandouts(true)}}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{saveClickAnalytics('Nav Handouts - Auditourium', eventId);setHideOverlay(false); setShowHandouts(true)}}>Handouts</p>
                    </div>
                    <div style={{display: retrievedShowAgenda?'flex':'none'}} >
                        <img src={agendaicon} onClick={()=>{saveClickAnalytics('Nav Agenda - Auditourium', eventId);setHideOverlay(false); setShowAgenda(true)}}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={()=>{saveClickAnalytics('Nav Agenda - Auditourium', eventId);setHideOverlay(false); setShowAgenda(true)}}>Agenda</p>
                    </div>
                    {/* <div>
                        <img src={logoutIcon} onClick={showPopUpFormFn}/>
                        <p style={{width: '100%', textAlign: 'center'}} onClick={showPopUpFormFn}>Exit Event</p>
                    </div> */}
                    
                    <div><p style={{fontSize: '0.8rem', marginLeft: '-50%'}} >Powered By</p><img src={progressioIcon} /></div>
                    
                    <img src={menuIcon} className='burger-icon' onClick={handleExpandMenu}/>
                </nav>
            </div>
            
            <div className='nav-expanded' style={{display: mobileNavClosed?'flex':'none'}}>
                <ul>
                    <a href='' onClick={(e)=>{handleExpandMenu(e); }}><li><img src={closeIcon} style={{filter: 'invert(1)', width: '30%'}}/></li></a>
                    <a href='' onClick={(e)=>{e.preventDefault(); saveClickAnalytics('Nav Event Description - Auditourium', eventId).then(()=>{setHideOverlay(false); setShowInfo(true)})}}><li><img src={infoIcon}/><span>Info</span></li></a>
                    <a href='' onClick={(e)=>{
                            e.preventDefault()
                            saveClickAnalytics('Nav Home/FeedbackForm - Auditourium', eventId)
                            if(feedbackFormActivated)
                            
                            {setHideOverlay(false); showPopUpFormFn()}
                            else{props.history.push('/archive')}}}><li><img src={homeIcon}/><span>Home</span></li></a>
                    <a href='' style={{display: retrievedShowSpeakers?'flex':'none'}} onClick={(e)=>{e.preventDefault(); saveClickAnalytics('Nav Speakers - Auditourium', eventId);setHideOverlay(false); setShowSpeakers(true)}}><li><img src={micIcon}/><span>Speakers</span></li></a>
                    <a href='' style={{display: retrievedShowGiveaways?'flex':'none'}}onClick={(e)=>{e.preventDefault(); saveClickAnalytics('Nav Giveaways - Auditourium', eventId);setHideOverlay(false); setShowGiveaways(true)}}><li><img src={giftIcon}/><span>Giveaways</span></li></a>
                    <a href='' style={{display: retrievedShowHandouts?'flex':'none'}}onClick={(e)=>{e.preventDefault(); saveClickAnalytics('Nav Handouts - Auditourium', eventId);setHideOverlay(false); setShowHandouts(true)}}><li><img src={handoutIcon}/><span>Handouts</span></li></a>
                    <a href='' style={{display: retrievedShowAgenda?'flex':'none'}}onClick={(e)=>{e.preventDefault(); saveClickAnalytics('Nav Agenda - Auditourium', eventId);setHideOverlay(false); setShowAgenda(true)}}><li><img src={agendaicon}/><span>Agenda</span></li></a>
                    
                    {/* <a href='' onClick={showPopUpFormFn} style={{display: 'none'}}><li><img src={logoutIcon}/><span>Exit Event</span></li></a> */}
                    <a href=''><li><span>Powered By</span><img src={progressioIcon}/></li></a>
                </ul>
            </div>
        </div>
    )
}

export default Auditorium
