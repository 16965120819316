
import React, { useState, useEffect } from 'react'

function PanelTable(props) {

    const panelStyles = {
        width: '35%',
        height: '100%',
        marginLeft: '3%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '15px',
        borderRadius: '15px',
        boxSizing: 'border-box',
        backgroundColor: '#F7F2EC',
        boxShadow: '0 3px 10px 0 rgb(0 0 0 / 30%)'
    }
    const [localData, setLocalData] = useState([])
    useEffect(() => {
        setLocalData(props.data)
        
    }, [props])
    return (
        <div className='dash-s1-panel-split-s1-2' style={{...panelStyles, ...props.style}} >
            {/* button clicks */}
            <p className='panel-title'>{props.title}</p>
                <div className='a-table' style={{height:'100%', width: '100%'}}>
                
                    <div className='a-t-tb'>
                    <ul className='a-t-th' style={{gridTemplateColumns: props?.cols===3?'1fr 1fr 1fr':'2fr 1fr', gridColumnGap:'10%'}}>
                        {
                            props.headers.map((h,i)=>
                                <li key={'p-'+i}>{h}</li>
                            )
                        }
                        
                    </ul>
                        {//running event button clicks
                            localData===null||localData===[]?
                            'No data found'
                            :
                            localData?.map((s,i)=>
                                <ul className='a-t-tr' key={'a-t-cell1-'+i} style={{gridTemplateColumns: props?.cols===3?'1fr 1fr 1fr':'2fr 1fr'}}>
                                    <li>{s?.d1}</li>
                                    <li>{s?.d2}</li>
                                    <li>{s?.d3}</li>
                                    
                                </ul>  
                            )
                        }
                    </div>
                    <div className='overlay-a-table'></div>
                </div>
            </div>
    )
}

export default PanelTable
