import React from 'react'
import progressioIcon from '../../img-src/icons/progressio.png';
function Footer() {
    return (
        <footer className='footer-c'>
            <div><p style={{fontSize: '0.8rem', marginLeft: '-50%'}} >Powered By</p><img src={progressioIcon} /></div>
        </footer>
    )
}

export default Footer
