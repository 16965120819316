import React, { useState, useEffect } from 'react'
import DDL from '../Fields/DDL'
import {fixfontsize} from '../helpers'
import axios from 'axios';
import { getToken } from '../../Utils/Common';
import PanelTable from '../Util/PanelTable';
import burgerIcon from '../../img-src/icons/menu-icon.png'
import calendarIcon from '../../img-src/icons/calendar.png'
import clockIcon from '../../img-src/icons/clock.png'
import lorealLogo from '../../img-src/loreal logo.png'
import { removeUserSession,removeAdminId } from '../../Utils/Common';
import AdminNav from './AdminNav';
import FilterBar from '../Fields/FilterBar';
import downloadicon from '../../img-src/icons/download.png'
import { v4 } from 'uuid';
import { adminMinWidth } from '../content';
import { baseAPI } from '../../config';
import questionIcon from '../../img-src/icons/question.png'
function AdminAnalysis(props) {
    const [selectedEvent, setSelectedEvent] = useState('');
    const [navExpanded, setNavExpanded] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const listOfEvents = ['Event 1', 'Event 2', 'Event 3'];
    const inviteeErrors = [{name: 'Ahmed Khaled'},{name: 'Salma Aly'},{name: 'Iman Salem'},{name: 'Ahmed Khaled'},{name: 'Ahmed Khaled'},{name: 'Ahmed Khaled'},{name: 'Salma Aly'},{name: 'Iman Salem'},{name: 'Ahmed Khaled'},{name: 'Ahmed Khaled'}] 
    
    const [pastEvents, setPastEvents] = useState([''])
    const [pastEventsEls, setPastEventsEls] = useState('')

    

    //selected event details
    const [totalInvitedUsers, setTotalInvitedUsers] = useState(0)
    const [avgEngagementTime, setAvgEngagementTime] = useState(0)
    const [totalUsers, setTotalUsers] = useState(0)
    const [attendees, setAttendees] = useState([])
    const [numResponses, setNumResponses] = useState(0)
    const [buttonClicks, setButtonClicks] = useState([])
    const [selectedEventId, setSelectedEventId] = useState(null)
    const [blockedUsers, setBlockedUsers] = useState(null)
    const [deletedChat, setDeletedChat] = useState(null)
    const [chatReplay, setChatReplay] = useState(null)
    const [eventName, setEventName] = useState('Event Name')
    const [selectedEventStartDate, setSelectedEventStartDate] = useState('')
    const [selectedEventEndDate, setSelectedEventEndDate] = useState('')
    const [error, setError] = useState('')
    const [fromTime, setFromTime] = useState('')
    const [toTime, setToTime] = useState('')
    const [fromDay, setFromDay] = useState('')
    const [toDay, setToDay] = useState('')
    //set from time and from day filters to minimum 
    const [timeCap, setTimeCap] = useState(null)
    const [dayCap, setDayCap] = useState(null)
    //logout and clear session
    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }


    function onDownloadUserData(){
        setError('')
        
        axios.post(baseAPI+'/api/analysis/attendees/past', {event_id:selectedEvent,csv:true}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            setError('')
            let link = document.createElement('a')
            
            let rand = v4()
            
            link.id = 'download-csv-user-data-'+rand
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(r.data));
            link.setAttribute('download', 'attendees.csv');
            document.body.appendChild(link)
            document.querySelector('#download-csv-user-data-'+rand).click()
        }).catch(e=>{
            //1console.log(e);
            if(e?.response.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
            setError('An error has occurred while downloading your file. Please try again')
        })
        
    }
    useEffect(() => {
        document.title = "L'Oréal Expo - Past Events"
        //get past events
        axios.get(baseAPI+'/api/event/past', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            // //1console.log('%cAdmin Analysis - Get Past events','font-size: 30px; color: cornflowerblue', r.data);
            setPastEvents(r.data)
            setAttendees(r.data.attendees)
            
            // setScheduledTableEls(r.data)
        }).catch(e=>{
            if(e?.response.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
            //1console.log(e.response);
        })


        
    }, [])


    useEffect(() => {
        
        //1console.log(fromDay);
        let selectedev = pastEvents.filter(a=>a.id===parseInt(selectedEvent))[0]
        setEventName(selectedev?.title)
        setSelectedEventStartDate(selectedev?.event_datetime)
        setSelectedEventEndDate(selectedev?.end_datetime)
        

        let payload = {}
        if(fromDay===''||fromTime===''||toDay===''||toTime===''){
            
            payload = {
                "event_id":selectedEvent
            }
        }
        else{
            payload = {
                "event_id":selectedEvent,
                start_date: fromDay+ ' ' + fromTime,
                end_date: toDay + ' ' + toTime
                
            }
        }
        
        //1console.log(payload);
        axios.post(baseAPI+'/api/analysis/past', payload,{headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            
            //1console.log('%cAdmin Analysis - Get Past events','font-size: 30px; color: green', r.data);
            setTotalInvitedUsers(r.data.total_invitees)
            setAvgEngagementTime(r.data.average_engagement_time)
            setTotalUsers(r.data.total_users)
            setAttendees(r.data.attendees)
            setNumResponses(r.data.responses)
            setButtonClicks(r.data.button_clicks)
            setSelectedEventId(r.data.id)
            //1console.log(r.data.event.analysis_activation_time?.split('T')[0]);
            setTimeCap(r.data.event.analysis_activation_time?.split('T')[1].split('.')[0].slice(0,-3))
            setDayCap(r.data.event.analysis_activation_time?.split('T')[0])

            //get attendees
            axios.post(baseAPI+'/api/analysis/attendees/past',{event_id:selectedEvent, csv:false}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                setAttendees(r.data)
            }).catch(e=>{
                //1console.log(e);
            })
            
            axios.get(baseAPI+'/api/event/chat/block/'+selectedEvent, {headers: {'Authorization':'bearer '+getToken()}}).then(r=>{
                //1console.log('%cblocked users for event '+selectedEvent,'color:purple', r.data)
                setBlockedUsers(r.data)
                
            }).catch(e=>{
                //1console.log('%cblocked users for event '+selectedEvent,'color:orange',e.response)
                setDeletedChat([])
            })


            axios.get(baseAPI+'/api/event/chat/delete/'+selectedEvent, {headers: {'Authorization':'bearer '+getToken()}}).then(r=>{
                //1console.log('%cdeleted chats for event '+selectedEvent,'color:purple', r.data)
                setDeletedChat(r.data)
                
            }).catch(e=>{
                //1console.log('%cdeleted chats for event '+selectedEvent,'color:orange',e.response)
                setDeletedChat([])
            })

            axios.get(baseAPI+'/api/event/chat/load/'+selectedEvent, {headers: {'Authorization':'bearer '+getToken()}}).then(r=>{
                //1console.log('loaded chats',r.data)
                setChatReplay(r.data)
                
            })
            // setScheduledTableEls(r.data)
                setChatReplay([])


        }).catch(e=>{
            //1console.log(e);
        })
    }, [selectedEvent, fromDay,toDay,fromTime,toTime])
    

    function getInitials(fullName) {
        
        if(fullName!==null||fullName){
            var firstInitial = fullName?.toString()?.substr(0,1).toUpperCase();
            var secondInitial = fullName?.toString()?.split(' ', 2)[1]?fullName?.split(' ', 2)[1].substr(0,1).toUpperCase():'';
    
            return firstInitial+secondInitial;
        }
       
    }
    return (

        <div style={{minWidth: adminMinWidth+'px'}}>
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%'}}>
                <AdminNav style={{width: navExpanded?'16%':0}} active={3}/>

                

                {/* 
                    ========================================BODY========================================
                
                */}


                <div className='a-analysis' style={{width: navExpanded?'84%':'100%', transition: '0.3s'}}>
                        <h2 className='p-title' style={{textAlign: 'center'}}>Choose Event to View Analysis</h2>
                        

                        <div className='text-field' style={{marginLeft:'10%', justifyContent:'flex-start'}}>   
                            <span className='label'>Event</span>
                            
                            <select name={'Event'} value={selectedEvent} onChange={e=>setSelectedEvent(e.target.value)} >
                                <option value='' >Please Select</option>
                                {
                                    pastEvents.length>0?
                                    pastEvents?.map(p=>
                                        <option value={p.id}>
                                        {p?.title+' - '+p?.created_at?.split('T')[0]}
                                    </option>
                                    ):''
                                }
                                
                                
                            </select>
                        </div>
                        <hr style={{width: '80%'}}/>
                        

                        <h3 className='p-title' style={{textAlign: 'center'}}>Past Event Analysis</h3>
                        <div className='row' style={{marginBottom:'2%'}}>
                            <h3 className='p-title' style={{textAlign: 'left', marginLeft: '10%', marginRight: 'auto'}}>{eventName}</h3>
                            <div className='pill-style' style={{marginRight: '2%'}}>
                                <img src={calendarIcon} width={'30px'} style={{filter: 'invert(1)', marginRight: '5%'}}/>
                                <p>Date: {selectedEventStartDate===''||selectedEventStartDate?new Date(selectedEventStartDate).toLocaleString('en-GB'):''}</p>
                            </div>
                            <div className='pill-style' style={{marginRight: '10%'}}>
                                <img src={clockIcon} width={'30px'} style={{filter: 'invert(1)', marginRight: '5%'}}/>
                                <p>Date: {selectedEventEndDate===''||selectedEventEndDate?new Date(selectedEventEndDate).toLocaleString('en-GB'):''}</p>
                            </div>
                        </div>
                        
                        <FilterBar 
                            fromDay={fromDay} 
                            onChangeFromDay={e=>setFromDay(e.target.value<=dayCap?dayCap:e.target.value)} 
                            ToDay={toDay} 
                            onChangeToDay={e=>setToDay(e.target.value)} 
                            FromTime={fromTime} 
                            onChangeFromTime={e=>setFromTime(e.target.value<=timeCap?timeCap:e.target.value)} 
                            toTime={toTime} 
                            onChangeToTime={e=>setToTime(e.target.value)}  
                            style={{marginLeft:'10%',marginRight:'10%'}}
                        />
                        
                        
                        <div className='row' style={{marginBottom: '0%', width: '100%', height: '400px', padding: '2% 10%', boxSizing: 'border-box', justifyContent: 'flex-start'}}>
                            <div className='panel-style' style={{margin:0, width: '70%', height: '100%'}}>
                                <h4 className='panel-title'>Past Event Analytics</h4>

                                <div className='a-analysis' style={{width: '100%', display: 'flex', justifyContent: 'center', height: '70%'}}>
                                    <div className='row' style={{width: '100%', display: 'flex', justifyContent: 'center', height: '100%'}}>
                                    <div className='panel-row' style={{justifyContent: 'space-around', height: '100%'}}>
                                        <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', height: '85%', marginBottom:'5%'}}>
                                            <p>Total Invited Users</p>
                                            <div className='circle-num' style={{fontSize: fixfontsize(95,totalInvitedUsers), width: '8rem', height: '8rem'}}>{totalInvitedUsers}</div>
                                        </div>
                                        <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', height: '85%', marginBottom:'5%'}}>
                                            <p>Total Users</p>
                                            <div className='circle-num' style={{fontSize: fixfontsize(95, totalUsers), width: '8rem', height: '8rem'}}><p >{totalUsers}</p></div>
                
                                        </div>
                                        <div style={{width:'30%', display: 'flex', flexDirection:'column', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', height: '85%', marginBottom:'5%'}}>
                                            <p>Average Engagement Time</p>
                                            <div className='circle-num-text' style={{width: '8rem', height: '8rem'}}><p style={{fontSize: fixfontsize(75, avgEngagementTime===null?0:100)}}>{avgEngagementTime===null?0:parseInt(avgEngagementTime)/60>=60?(parseInt(avgEngagementTime)/3600).toFixed(2):(parseInt(avgEngagementTime)/60).toFixed(2) }</p><p>{parseInt(avgEngagementTime)/60>=60?'Hour(s)':'Minute(s)'}</p></div>
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                            {/* PAST EVENTS BUTTON CLICKS */}
                            <PanelTable title='Button Clicks' headers={['Button', 'Clicks']} data={buttonClicks.map(b=>{return{d1:b.label,d2:b.count}})} style={{width: '30%', height: '100%', padding: '15px', justifyContent: 'flex-start'}}/> 
                            
                        </div>
                        
                        <div className='row' style={{marginBottom: '1%', width: '100%', height: '400px', padding: '2% 10%', boxSizing: 'border-box', justifyContent: 'space-between'}}>

                            
                            
                            <div className='panel-style' style={{margin:0, width: '65%', height: '100%'}}>
                                <h4 className='panel-title' style={{marginBottom:0}}>List of attending users</h4>
                                <div className='a-table' style={{height: '100%', width: '100%', paddingTop:0}}>
                                    <div className='a-table-header' style={{display: 'grid', gridTemplateColumns:'1fr 1fr 1fr 1fr', fontWeight: 'bold', borderBottom: '1px solid rgba(0,0,0,0.3)'}}>
                                        <p>Name</p>
                                        {/* <p>Mobile</p> */}
                                        {/* <p>Email</p> */}
                                        <p>Country</p>
                                        <p>Clinical Region</p>
                                        <p>Address</p>
                                    </div>
                                    <div className='a-table-body' style={{display: 'grid', gridTemplateRows:'1fr 1fr 1fr 1fr',  borderBottom: '1px solid rgba(0,0,0,0.3)'}}>
                                        {
                                            attendees?.map(a=>
                                                <div className='row' style={{display: 'grid', gridTemplateColumns:'1fr 0.5fr 1fr 1fr', borderBottom: '1px solid rgba(0,0,0,0.3)',borderBottom: '1px solid rgba(0,0,0,0.3)'}}>
                                                    <p>{a?.name}</p>
                                                    <p>{a?.country}</p>
                                                    <p>{a?.clinical_region}</p>
                                                    <p>{a?.address.replace(/%/g, ', ')}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            
                                <button className='primary-btn' style={{padding:0, width: '8rem', height:'2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-around',marginTop: '5%'}} onClick={onDownloadUserData}><img src={downloadicon} height='25px'/><span>Export</span></button>
                        </div>



                            <div className='panel-style' style={{margin:0, width: '30%', height:'100%', justifyContent: 'flex-start'}}>
                                <h4 className='panel-title' style={{marginBottom: '20%'}}>User Feedback</h4>
                                <div style={{width:'80%', display: 'flex', flexDirection:'column', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', height: 'auto', marginBottom:'5%'}}>
                                            
                                            <div className='circle-num-text' style={{width:'10rem', height: '10rem'}}><p style={{fontSize: fixfontsize(95, numResponses||'0')}}>{numResponses||'0'}</p><p>Response(s)</p></div>
                
                                        </div>
                            </div>
                            
                        </div>

                        
                        
                        <div className='row'style={{margin: '0 8%'}}>
                                
                            <div className='panel-style' style={{marginBottom: '5%', marginLeft:'2%', marginRight: '2%'}}>
                                <p className='panel-title'>Blocked Users (Chat)</p>
                                <div className='' style={{width: '80%',height: '400px', overflowY: 'auto'}}>
                                    
                                    {
                                        // JSON.stringify(blockedUsers) 
                                        blockedUsers?.map(u=><p style={{borderBottom: '1px solid black'}}>{u?.name}</p>)
                                    }
                                </div>
                            </div>
                            <div className='panel-style' style={{marginBottom: '5%', marginLeft:'2%', marginRight: '2%'}}>
                                <p className='panel-title'>Deleted texts (Chat)</p>
                                <div className='' style={{width: '80%',height: '400px', overflowY: 'auto'}}>
                                    
                                    {
                                        // JSON.stringify(deletedChat) 

                                        deletedChat?.map(c=>
                                            <p style={{borderBottom: '1px solid rgba(0,0,0,0.3)'}}>
                                                {c.message}
                                            </p>
                                        )
                                    }
                                </div>
                            </div>

                            <div className='panel-style' style={{marginBottom: '5%', marginLeft:'2%', marginRight: '2%'}}>
                                <p className='panel-title' style={{width: '60%'}}>Chat Replay</p>
                                <div className='' style={{width: '80%',height: '400px', overflowY: 'auto'}}>
                                    
                                    {
                                        chatReplay?.map((m,i)=>
                                        <div className={'msg-item'+(m.self?' my-msg':'')} key={i} id={'msg-'+m.id} style={{width: '100%', backgroundColor: 'white', borderRadius: '15px 15px 15px 0'}}>
                                            {m.access==='user'?'':<button className='img-btn'></button>}
                                            {/* <div className='icon'>{m.user.name}</div> */}
                                            <div className='msg-content'>
                                                <p className='modifier'>{m.access==='user'?'':m.access}</p>
                                                <div className='msg-text-wrapper'>
                                                    <p className='sender-name' style={{fontWeight:'bold'}}>{m.user?.name}</p>
                                                    <p className='msg-text'>{m.message}</p>
                                                    {
                                                        // m.replyId?
                                                        // <div className='msg-text-wrapper'>
                                                        //     <p className='sender-name'>{mockChat[m.replyId].name}</p>
                                                        //     <p className='msg-text'>{mockChat[m.replyId].message}</p>
                                            
                                                        // </div>
                                                        // :''
                                                    }
                                            
                                                </div>
                                            </div>
                                        
                                        </div>)
                                    }
                                </div>
                            </div>

                        </div>
                        

                        
                        
                    </div>



            </div>
            
        </div>
        
    )
}

export default AdminAnalysis
