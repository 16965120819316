import React, { useState, useEffect } from 'react'

function LoadingBar(props) {
    return (
        <div className='loading-bar' style={props.style}>
            <div className='loading-bar-progress' style={{width: props.status}}></div>
        </div>
    )
}

export default LoadingBar
