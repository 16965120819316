import { createSlice } from '@reduxjs/toolkit'


export const speakerSlice = createSlice({
  name: 'speaker',
  initialState: {
    value: 0,
    speakers: [{name: '', bio: '', file: ''}]
    
  },
  reducers: {
    setName: (state, action)=>{
        state.speakers[action.payload.i].name = action.payload.value
    },
    setBio: (state, action)=>{
        state.speakers[action.payload.i].bio = action.payload.value
    },
    setFile:(state, action)=>{
        state.speakers[action.payload.i].file = action.payload.value
    },
    addSpeaker: (state)=>{
      state.speakers = [...state.speakers, {name: '', bio: '', file: ''}]
      
      

      

    },
    removeSpeaker:(state, action)=>{
      state.speakers.splice(action.payload.i, 1)
    },
    updateSpeakers:(state, action)=>{
      state.speakers = action.payload
    }
    
  },
})

// Action creators are generated for each case reducer function
export const {setName, setBio, setFile, addSpeaker, removeSpeaker,updateSpeakers } = speakerSlice.actions

export default speakerSlice.reducer

