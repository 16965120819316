import React, { useState, useEffect } from 'react'
import Input from '../Fields/Input'
import DDL from '../Fields/DDL';
import { useSelector, useDispatch } from 'react-redux'
import { updateChat } from "../slices/adminSlice";

function Chat(props) {
    const [ChatLocal, setChatLocal] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(updateChat(ChatLocal.map(m=>{
          if(m.Number===''){
             let x;
          }
           
          else
            return{phone: m.Number}
        
        })))
    }, [ChatLocal])
    const onChange = (index, event) => {
        if(event.target.name!=='Role'){
          event.preventDefault();
          event.persist();
        }
            
        
        
    
        setChatLocal((prev) => {
          return prev.map((item, i) => {
            if (i !== index) {
              return item;
            }
    
            return {
              ...item,
              [event.target.name]: event.target.value
            };
          });
        });
      };

    const handleAdd = (e) => {
    e.preventDefault();
    const inputState = {
        Number: ""
        
        

        
    };

   
    setChatLocal((prev) => [...prev, inputState]);
    
    };
    function handleRemove(e, i){
        
        e.preventDefault()
        
        setChatLocal((prev) => prev.filter((item) => item !== prev[i]));
    }

    useEffect(() => {
      
      //initialize with saved data if available
      //1console.log(props.initValues);
      if(props.initValues!==null&&props.initValues&&props.initValues?.length>0){
        var x = props.initValues.map(s=>{
          
          
            return{Number: s.phone}
          
        })
        setChatLocal(x)
    }
    }, [])
    return (
        <div className='form-wrapper' style={{filter: props.active?'none':'grayscale(100)', opacity: props.active?'1':'0.4'}}>
           
            {/* {JSON.stringify(ChatLocal)} */}
            {ChatLocal.map((s,i)=><div  style={{width: '100%'}} key={'chat-role-a-'+i}>
                                    <p className='speaker-num'></p>
                                    <button className='warning-btn' onClick={(e)=>handleRemove(e, i)} style={{marginLeft: '31%', padding: '0.3rem 1rem', marginBottom: '1%', }}>Remove User</button>
                                    <div style={{display: 'flex', flexWrap: 'wrap', padding: '0 5%', justifyContent: 'center'}}>
                                        
                                        <Input type='number' name='Number' value={s.Number}  onChange={(e)=>onChange(i,e)} />
                                        {/* <DDL name='Role' value={s.Role} onChange={e=>onChange(i,e)} ddlItems={['Admin', 'Moderator']} /> */}
                                    </div>

                                </div>)}
            
            <button className='a-link' onClick={handleAdd}style={{marginBottom:'2%'}}>+ Add More</button>
        </div>
    )
}

export default Chat
