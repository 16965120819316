
import speakersIcon from '../../img-src/icons/mic.png'
// import Flickity from 'react-flickity-component';
import logo from '../../img-src/speakers/Professor Thomas Luger.png';
// import { Carousel, CarouselItem } from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
import {Carousel} from '3d-react-carousal';
import { set } from 'react-ga';
import { v4 } from 'uuid';
import React, { useState, useEffect } from 'react'
import defaultImg from '../../img-src/icons/speaker-placeholder.png'
function Speakers(props) {


   
    

        const [slides, setSlides] = 
        useState(
            ['']
        )
        const [localSpeakers, setLocalSpeakers] = useState([])
    useEffect(() => {
        setLocalSpeakers(props.speakers)
        
    }, [props])
   useEffect(() => {
       let x = localSpeakers
       setSlides(x.map((s,i)=><div className='carousel-card' key={'auditorium-speakers-s'+i}>
            <p className='speaker-name'>{s.name}</p>
            
            <img  src={s.imgUrl||defaultImg} alt="1" />
            <p className='speaker-desc'>{s.desc}</p>
        </div>))
    // setSlides(localSpeakers.map((s,i)=>i===4||i===5||i===6?null:<div className='carousel-card' key={'auditorium-speakers-s'+i}>
    //         <p className='speaker-name'>{s.name}</p>
            
    //         <img  src={s.imgUrl} alt="1" />
    //         <p className='speaker-desc'>{s.desc}</p>
    //     </div>))
   }, [localSpeakers])
    return (
        <div className='speakers-c' style={props.style}>
            <div className='title' style={{visibility: props.showTitle?'visible':'hidden', marginBottom: 0}}>
                <img src={speakersIcon} />
                <h2>Speakers</h2>
                
            </div>
            <h2 className='alt-title' style={{display: !props.showTitle?'block':'none'}}>Speakers</h2>
           <div style={{height: '100%', display: props.showTitle?'flex':'block', flexDirection: 'column', justifyContent: 'center', marginTop: '5%', marginBottom: '5%'}}>
               <Carousel slides={slides} />
           </div>
            
        </div>
    )
}

export default Speakers
