import React, { useState, useEffect, useCallback } from 'react'
import { v4 } from 'uuid'
import Input from '../Fields/Input'
import Upload from '../Fields/Upload'
import { useSelector, useDispatch } from 'react-redux'
import {setName, setBio, setFile, addSpeaker, removeSpeaker, updateSpeakers} from '../slices/speakerSlice'
import store from '../../store'
import { connect } from 'react-redux'
import useForceUpdate from 'use-force-update'
import { getToken } from '../../Utils/Common'
import axios from 'axios'
import { baseAPI } from '../../config'
function Speakers(props) {
    
    const [s1, setS1] = useState()
    
    const [error, setError] = useState('')
    const speaker = useSelector(state=>state.speaker.speakers)
    const [speakersLocal, setSpeakersLocal] = useState([{name: '', bio: '', fileName: ''}])
    const [sl, setSl] = useState()
    const dispatch = useDispatch()
    const [prevState, setprevState] = useState()
    // const [setRetrievedSpeakers, setSetRetrievedSpeakers] = useState(true)
    const [sps, setSps] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    // useEffect(() => {
        
    //     // //1console.log('speakers has changed! from speaker component');
    //     // //1console.log(speaker);
    // }, [speaker])

    useEffect(() => {
        var x = sps.map(s=>{return{name: s.Name, bio: s.Bio, picture: s.Picture}})
        dispatch(updateSpeakers(x))
    }, [sps])
    

    

    useEffect(() => {
        // //1console.log(speaker);
        // //1console.log(store.getState())
        //1console.log(speaker.length);
        setprevState(speaker.length)
        //1console.log(props.initValues);

        //initialize with saved data if available
        if(props.initValues!==null){
            var x = props.initValues.map(s=>{return{Name: s.name, Bio: s.bio, Picture: s.picture}})
            setSps(x)
        }
    }, [])


    const onChange = (index, event) => {
        if(event.target.name!=='Picture'){
            event.preventDefault();
            event.persist();
        }
        // if(event.target.name==='Bio'&&event.target.value.length>75){
        //     return
        // }
        
    
        setSps((prev) => {
          return prev.map((item, i) => {
            if (i !== index) {
              return item;
            }
    
            return {
              ...item,
              [event.target.name]: event.target.value
            };
          });
        });
      };

    const handleAddSpeaker = (e) => {
    e.preventDefault();
    const inputState = {
        Name: "",
        Bio: "",
        Picture: ''

        
    };

   
        setSps((prev) => [...prev, inputState]);
    
    };
    function handleRemoveSpeaker(e, i){
        
        e.preventDefault()
        
        setSps((prev) => prev.filter((item) => item !== prev[i]));
    }




    


    const onDropFile = useCallback((acceptedFiles, i) => {
        setIsUploading(true)
        //1console.log(acceptedFiles[0]);
        var formData = new FormData()
        formData.append('file', acceptedFiles[0])

        axios.post(baseAPI+'/api/files/image/upload',  formData, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data.url);
            onChange(i, {target: {value: r.data.url, name: 'Picture'}})
            setIsUploading(false)
            
        }).catch(e=>{
            //1console.log(e);
            setIsUploading(false)
        })

    }, [])

    
    return (
        <div className='form-wrapper' style={{filter: props.active?'none':'grayscale(100)', opacity: props.active?'1':'0.4'}}  id='speakers'>
            {/* {s1}
            {JSON.stringify(speaker) }
            {JSON.stringify(sl)} */}
            {/* {JSON.stringify(sps)} */}
            {sps.map((s,i)=><div  style={{width: '100%'}} key={'speaker-a-'+i}>
                                    <p className='speaker-num'>Speaker {i+1}</p>
                                    <button className='warning-btn' onClick={(e)=>handleRemoveSpeaker(e, i)} style={{marginLeft: '7%', padding: '0.3rem 1rem', marginBottom: '5%'}}>Remove Speaker</button>
                                    <div style={{display: 'flex', flexWrap: 'wrap', padding: '0 5%'}}>
                                        
                                        <Input type='text' name='Name' value={s.Name}  onChange={(e)=>onChange(i,e)} />
                                        <Upload name='Picture' onDrop={(acceptedFiles) => {onDropFile(acceptedFiles, i)}} fileName={s.Picture?.split('/')[4]?.slice(13)} compress={true} accept="image/png, image/gif, image/jpeg" isLoading={isUploading}/>
                                        <Input type='text' name='Bio' value={s.Bio} onChange={(e)=>onChange(i,e)} />
                                    </div>

                                </div>)}
            
            <button className='a-link' onClick={handleAddSpeaker} style={{margin:'2% 0'}}>+ Add Speaker</button>
        </div>
    )
}

const mapStateToProps = state => {
    return{
        sl: state.speaker
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setName: (x)=>dispatch(setName(x)),
        addSpeaker: ()=>dispatch(addSpeaker())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Speakers)
