import React, {useState, useEffect } from 'react';
import logo from '../img-src/loreal-expo-logo.png';
import dercosLogo from '../img-src/dercos-logo.png';
import larocheLogo from '../img-src/laroche-logo.png';
import vichyLogo from '../img-src/vichy-logo.png';
import ceraveLogo from '../img-src/cerave-logo.jpg'
import {setUserSession, getToken, removeUserSession, getUser} from '../Utils/Common';
import axios from 'axios';
import loader from '../img-src/icons/loader.gif'
import ACDExpoIcon from '../img-src/loreal-expo-logo.png';
import { baseAPI } from '../config';
function LoginACD(props) {
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const [next, setNext] = useState(false)
    const [currentEventId, setCurrentEventId] = useState('')
    const [smsCode, setSMSCode] = useState('')
    const [eventLogo, setEventLogo] = useState('')
    const [eventTitle, setEventTitle] = useState('')
    const [showRequestAccessBtn, setShowRequestAccessBtn] = useState(false)
    const [showEventName, setShowEventName] = useState(false)
    const [activateAnalysis, setActivateAnalysis] = useState(false)
    function getCurrentEventId(){
        setLoading('Loading...')
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            setCurrentEventId(r?.data?.id)
            setLoading('')
            setEventLogo(r?.data?.logo)
            setEventTitle(r.data.title)
            setShowEventName(r.data.title_display_choice==='Everywhere'||r.data.title_display_choice==='Event Login Only')
            setActivateAnalysis(r.data.activate_analysis)

             //if the user has already logged in from /login, auto fill number and send sms
             if(getUser()?.length>5){
                setMobile(getUser())
                //send sms
                setLoading('Loading...');

                
                axios.post(baseAPI+'/api/user/sendSms', {phone: getUser(), event_id: r?.data?.id}).then(r=>{
                    setLoading('');
                    setError('')
                    //1console.log(r.data);
                    setSMSCode(r.data.code)
                    setEventTitle(r.data.title)
                    //setUserSession('token','user')
                    // setUserSession(r.data.token.token, mobile);
                    // window.location.href = 'https://tour.acdexpo.com/';
                    setNext(true)
                }).catch(e=>{
                    setLoading('');
                    setError('The mobile number you entered is incorrect, please login again or register.')
                    if(e.response?.data?.message==="User not invited in this event"){
                        setError('You are not invited to this event.')
                        setShowRequestAccessBtn(true)
                    }
                    
                    //1console.log(e.response);
                })
            }
            // //1console.log(r.data);
        }).catch(e=>{
            //1console.log(e.response);
            if(e.response.status===404){
                setCurrentEventId('')
                setLoading('')
                axios.get(baseAPI+'/api/event/checkUpcoming').then(r=>{
                    setError('Stay tuned for the upcoming event: ' + r.data.title + '. On ' + new Date(r.data.event_datetime).toLocaleDateString('en-GB'))
                }).catch(e=>{
                    setError('No event running, please check back later')
                })
                
            }
                
        })
    }
    useEffect(() => {
        document.title = "L'Oréal Expo - Event Login"
        axios.get(baseAPI+'/api/app/control').then(r=>{
      
            if(r.data){ //if access is restricted, redirect to archive
                // window.location.href='/archive'
                props.history.push('/archive')
            }
            else{   //else load page normally
               
            }
        }).catch(e=>{
            props.history.push('/archive')
        })
        getCurrentEventId()

        
    }, [])
    
    function onSubmit(){
       setError('')
        // var isValidMobile = validateMobile(mobile);
        // if(!isValidMobile){
            
        //     var errormsg = isValidMobile?'': 'Please enter a valid mobile number. \n';
        
        //     setError(errormsg);
        //     return;
        // }
        

        setLoading('Loading...');
        saveClickAnalytics('Login - SMS Login', currentEventId)
        
        axios.post(baseAPI+'/api/user/login', {phone: mobile, password: password, event_id: currentEventId}).then(r=>{
            setLoading('');
            //1console.log(r);
            setUserSession(r.data.token,mobile)
            // setUserSession(r.data.token.token, mobile);
            // window.location.href = 'https://tour.acdexpo.com/';
            props.history.push('/landing')
        }).catch(e=>{
            setLoading('');
            setError('The password you entered is incorrect, please login again or register.')
            //1console.log(e);
        })
        
        

        setError('');
    }
    function onSendSMS(){
        // var isValidMobile = validateMobile(mobile);
        // if(!isValidMobile){
            
        //     var errormsg = isValidMobile?'': 'Please enter a valid mobile number. \n';
        
        //     setError(errormsg);
        //     return;
        // }
        setLoading('Loading...');

        saveClickAnalytics('Next - SMS Login', currentEventId)
        axios.post(baseAPI+'/api/user/sendSms', {phone: mobile, event_id: currentEventId}).then(r=>{
            setLoading('');
            setError('')
            //1console.log(r.data);
            setSMSCode(r.data.code)
            setEventTitle(r.data.title)
            //setUserSession('token','user')
            // setUserSession(r.data.token.token, mobile);
            // window.location.href = 'https://tour.acdexpo.com/';
            setNext(true)
        }).catch(e=>{
            setLoading('');
            setError('The mobile number you entered is incorrect, please login again or register.')
            if(e.response?.data?.message==="User not invited in this event"){
                setError('You are not invited to this event.')
                setShowRequestAccessBtn(true)
            }
            
            //1console.log(e.response);
        })
    }
    function validateMobile(num){
        // const re = /^01[0-9]\d{8}$/;
        // return re.test(num)
        const re = /^(?:(\+))?(?:[0-9]{0,3}[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{4}\)))?(\d{4})(?:(?!\(\d{4})\))?[\s.\/-]?)?$/
        return re.test(num)
    }
    async function saveClickAnalytics(btn, eventId){
        if(!activateAnalysis){
            
            return
        }
        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn})
            
        }
        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId})
    }
    function onRequestAccess(){
        setLoading('Loading...')
        setError('')
        axios.post(baseAPI+'/api/inviteeError/create', {phone: mobile, event_id: currentEventId}).then(r=>{
            //1console.log(r);
            setLoading('Your request has been sent. It will be reviewed by an admin shortly.')
            setShowRequestAccessBtn(false)
        }).catch(e=>{
            //1console.log(e);
            setError('Error occurred while requesting access. Please try again.')
        })
    }
    return (
        <div className='split-form'>
            <div className='aud-no-event' style={{display: currentEventId===''?'flex':'none'}}>

                <img src={ACDExpoIcon} />
                {/* <p style={{display: loading==='Loading...'?'none':'block'}}>{loading}</p> */}
                <img src={loader} style={{display: loading==='Loading...'?'block':'none', width: 'auto'}} /> 
                <p>{error}</p>
            </div>
            <div className='splash'>
                {
                    // eventLogo===''?
                    // <img src={logo} className='logo' alt='loreal expo'/>
                    // :
                    // <img src={eventLogo} className='logo' alt='loreal expo'/>

                    <img src={eventLogo?eventLogo:logo} className='logo' alt='loreal expo'/>
                }
                
                
                <div className='sponsors-container'>
                <img src={dercosLogo}  alt='loreal expo' height='100%'/>
                <img src={larocheLogo}  alt='loreal expo' height='100%'/>
                <img src={vichyLogo}  alt='loreal expo' height='100%'/>
                <img src={ceraveLogo}  alt='loreal expo' height='100%'/>
                </div>
            </div>


            <div className='form' style={{display:!next?'flex':'none'}}>
                {
                    // eventLogo===''?
                    // <img src={logo} className='logo' alt='loreal expo'/>
                    // :
                    // <img src={eventLogo} className='logo' alt='loreal expo'/>

                    <img src={eventLogo?eventLogo:logo} className='logo' alt='loreal expo'/>
                }
                
                <div style={{textAlign: 'center'}}>
                    <h1>EVENT LOGIN</h1>
                    <h2>{showEventName?eventTitle:''}</h2>
                    <p className='subtitle'>Please enter your mobile number to login.</p>
                </div>
                
                <div className='text-field'>
                    <span className='label'>Number</span>
                    <input type='text' value={mobile} onChange={e=>setMobile(e.target.value)} placeholder='Enter Your Mobile Number' />
                    
                </div>
                <a href='/registration'>Don't have an account? Register here.</a>
                <button className='cta-btn-blue' onClick={onSendSMS}>Next</button>
                <button className='cta-btn-blue' onClick={()=>onRequestAccess()} style={{display: showRequestAccessBtn?'block':'none'}}>Request Access</button>
                <p className='error' style={{width: '80%'}}>{error}</p>
                <p className='loading'>{loading}</p>
                
            </div>
            <div className='form' style={{display:next?'flex':'none'}}>
                {
                    // eventLogo===''?
                    // <img src={logo} className='logo' alt='loreal expo' />
                    // :
                    // <img src={eventLogo} className='logo' alt='loreal expo'/>

                    <img src={eventLogo?eventLogo:logo} className='logo' alt='loreal expo'/>
                }
                
                <div style={{textAlign: 'center'}}>
                    <h1>LOGIN</h1>
                    <h2>{eventTitle}</h2>
                    {/* <p className='subtitle' style={{backgroundColor:'#155473', color: 'white', padding:'5px', maxWidth: '60vw', wordBreak: 'break-word'}}>Please enter the password you received via SMS.</p> */}
                    <p className='subtitle' style={{backgroundColor:'#155473', color: 'white', padding:'5px', maxWidth: '60vw', wordBreak: 'break-word'}}>Please enter the code below to verify that you are human</p>
                    <p className='subtitle' style={{backgroundColor:'#155473', color: 'white', padding:'5px', maxWidth: '60vw', wordBreak: 'break-word', fontFamily:'typewriter', fontSize:'30px'}}>{smsCode}</p>
                    
                </div>
                
                <div className='text-field'>
                    <span className='label'>Password</span>
                    <input type='text' value={password} onChange={e=>setPassword(e.target.value)} placeholder='Enter Your Password' />
                    
                </div>
                <div className='' style={{display: 'flex', flexDirection: 'column'}}>
                    {/* <button className='cta-btn-blue' onClick={()=>setNext(false)}>Previous</button> */}
                    <a href='/registration'>Don't have an account? Register here.</a>
                    <button className='cta-btn-blue' onClick={()=>onSubmit()}>Login</button>



                </div>
                
                <p className='error' style={{width: '80%'}}>{error}</p>
                <p className='loading'>{loading}</p>
                
            </div>
            
            
        </div>
    )
}

export default LoginACD
