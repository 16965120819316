import React, { useState, useEffect } from 'react'
import lorealLogo from '../../img-src/loreal logo.png';
import burgerIcon from '../../img-src/icons/menu-icon.png';
import AdminNav from './AdminNav';
import ManageEvent from './ManageEvent';
import AdminDashboard from './AdminDashboard';
import { getToken, removeAdminId, removeUserSession  } from '../../Utils/Common';
import { fixfontsize } from '../helpers';
import axios from 'axios';
import PanelTable from '../Util/PanelTable';
import { adminMinWidth } from '../content';
import calendarIcon from '../../img-src/icons/calendar.png'
import clockIcon from '../../img-src/icons/clock.png'
import { baseAPI } from '../../config';
import questionIcon from '../../img-src/icons/question.png'
function AdminAccess(props) {
    const _MOCK_ADMINS_ = [{name: 'Annual Competition', email: 'https://www.youtube.com/', password: 'apasdd'},{name: 'Annual Competition', email: 'https://www.youtube.com/', password: 'apasdd'},{name: 'Annual Competition', email: 'https://www.youtube.com/', password: 'apasdd'},{name: 'Annual Competition', email: 'https://www.youtube.com/', password: 'apasdd'}]
    const [newAdminName, setNewAdminName] = useState('')
    const [newAdminEmail, setNewAdminEmail] = useState('')
    const [newAdminPassword, setNewAdminPassword] = useState('')
    const [newAdminPhone, setNewAdminPhone] = useState('')
    const [closeAccess, setCloseAccess] = useState(false)
    const [disable, setDisable] = useState(false)
    const [loading, setLoading] = useState('')
    const [error, setError] = useState('')
    const [errorAdminList, setErrorAdminList] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    const [navExpanded, setNavExpanded] = useState(true)

    const [allAdmins, setAllAdmins] = useState([])
    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }
    function onSubmit(){
        axios.post(baseAPI+'/api/admin/create',{
            "name": newAdminName,
            
            "email": newAdminEmail,
            "password": newAdminPassword
        } ,{headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log(r);
            setNewAdminPhone('')
            setNewAdminPassword('')
            setNewAdminName('')
            setNewAdminEmail('')
            getAllAdmins()
        }).catch(e=>{
            //1console.log(e.response);
            var err=''
            e.response.data?.errors?.forEach(l => {
                err+=l?.message + '. '
            });
            setErrorAdminList(err)
        })
        
    }


    function getAllAdmins(){
        axios.get(baseAPI+'/api/admin/getAll',{headers: {'Authorization': 'bearer ' + getToken()}} ).then(r=>{
            //1console.log(r);
            setAllAdmins(r.data)
        }).catch(e=>{
            //1console.log(e);
        })
    }
    useEffect(() => {
        document.title = "L'Oréal Expo - Admin Access"
        //get platform control state
        axios.get(baseAPI+'/api/app/control', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            setCloseAccess(r.data)
        }).catch(e=>{
            //1console.log(e)
        })

        //get list of admins
        getAllAdmins()
        
    }, [])


    function onDeleteAdmin(id){
        axios.delete(baseAPI+'/api/admin/'+id,{headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            getAllAdmins()
        }).catch(e=>{
            setError('Error occurred while deleting admin. Please try again.')
        })
    }
    function onChangeAccess(e){
        setLoading('Loading...')
        setError('')
        setDisable(true)
        axios.put(baseAPI+'/api/app/control', {"platform_control":e.target.checked}, {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
            //1console.log(r);
            setLoading('')
            setError('')
            axios.get(baseAPI+'/api/app/control', {headers: {'Authorization': 'bearer ' + getToken()}}).then(r=>{
                setCloseAccess(r.data)
                setDisable(false)
            }).catch(e=>{
                setLoading('')
                setError('Error occurred while gettting control state.')
                //1console.log(e)
                setDisable(false)
            })
        }).catch(e=>{
            setLoading('')
            setError('Error occurred while updating control state.')
            //1console.log(e);
            setDisable(false)
        })
    }
    const rootStyles={
        display: 'flex', flexDirection:'column', 
        justifyContent:'center', 
        alignItems: 'center',
        margin: '0% 0',
        width:'60%'
    }


    
    
    return (

        <div style={{minWidth: adminMinWidth+'px'}}>
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                    <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%'}}>
                <AdminNav style={{width: navExpanded?'16%':0}} active={5}/>

                <div style={rootStyles} style={{width:navExpanded?'84%':'100%', display:'flex', alignItems:'center', flexDirection:'column'}} id='access'>
                    <h3 className='p-title' style={{textAlign: 'center'}}>Admin Access</h3>
                    <div className='a-table' style={{height: '100%', overflow: 'hidden'}}>
                                    
                        <div className='a-t-tb' >
                        <ul className='a-t-th' style={{backgroundColor: '#F7F2EC', color: '#155473',gridTemplateColumns: '1fr 1fr 1fr 0.5fr'}}>
                            <li style={{color: '#155473'}}>Name</li>
                            <li style={{color: '#155473'}}>Email</li>
                            <li style={{color: '#155473'}}>Id</li>
                            <li></li>
                            
                            
                        </ul>
                            {
                                allAdmins.map((s,i)=>
                                    <ul className='a-t-tr' key={'a-t-cell-'+i} style={{gridTemplateColumns: '1fr 1fr 1fr 0.5fr', gridColumnGap: 0}}>
                                        <li>{s.name} <p style={{color:'#32AAA9', fontSize: '10px', margin: 0}}>{s.date}</p></li>
                                        <li>{s.email}</li>
                                        <li>{s.id}</li>
                                        <li><button className='warning-btn' style={{padding:0}} onClick={()=>onDeleteAdmin(s.id)}>Remove</button>
                                        </li>
                                        
                                    </ul>  
                                )
                            }
                        </div>
                        <div style={{display: 'grid',gridTemplateColumns: '1fr 1fr 1fr 0.5fr' }}>
                            <input style={{width: '80%'}} value={newAdminName} onChange={e=>setNewAdminName(e.target.value)} placeholder='Admin Name'/>
                            <input style={{width: '80%'}} value={newAdminEmail} onChange={e=>setNewAdminEmail(e.target.value)} placeholder='Admin Email'/>
                            <input style={{width: '80%'}} value={newAdminPassword} onChange={e=>setNewAdminPassword(e.target.value)} placeholder='Admin Password'/>
                            {/* <input style={{width: '80%'}} value={newAdminPhone} onChange={e=>setNewAdminPhone(e.target.value)} placeholder='Admin Phone'/> */}
                            <button className='primary-btn' style={{padding:0, marginRight:'auto'}} onClick={onSubmit}>Add Admin</button>
                        </div>
                        <p className='error'>{errorAdminList}</p>
                        <hr style={{width: '100%', margin: '5% 0'}} />

                        <h3 className='p-title' style={{textAlign: 'center'}}>Controlling Platform Access</h3>

                        <div style={{backgroundColor: '#F7F2EC', display: 'flex', padding: '5%', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold'}}>
                            <p style={{width: '70%'}}>Close the main hall and room access  (no redirection to general tour)
                            {/* <p className='loading'>{loading}</p>  */}
                            <p className='error'>{error}</p> 
                            </p>
                            
                            <div className="toggleWrapper" style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <input type="checkbox" name="toggle1" className="mobileToggle" id="toggle1" checked={closeAccess} disabled={disable} onChange={e=>onChangeAccess(e)}/>
                            <label htmlFor="toggle1"></label>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
        
    )
}

export default AdminAccess
