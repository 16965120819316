import React, { useState, useEffect } from 'react'
import lorealLogo from '../../img-src/loreal logo.png';
import burgerIcon from '../../img-src/icons/menu-icon.png';
import AdminNav from './AdminNav';
import ManageEvent from './ManageEvent';
import AdminDashboard from './AdminDashboard';
import questionIcon from '../../img-src/icons/question.png'
import { removeAdminId, removeUserSession  } from '../../Utils/Common';
import { adminMinWidth } from '../content';
function AdminMain(props) {
    const [navExpanded, setNavExpanded] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        document.title = "L'Oréal Expo - Manage"
        if(document.body.clientWidth<=800||document.body.clientHeight<=500 || window.innerWidth <=800 || window.innerHeight <=500){
            setIsMobile(true)
            document.body.style.overflow='hidden'
        }

        
            
    }, [])

    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }
    return (
        <div style={{minWidth: adminMinWidth+'px'}}>
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%'}}>
                <AdminNav style={{width: navExpanded?'16%':0, zIndex: 5}} active={1}/>

                <div className='' style={{width: navExpanded?'84%':'100%', height: '100%', transition: '0.3s'}} >
                    
                    <ManageEvent  />
                </div>
                
                {/* <div style={{background: 'lavender', width: navExpanded?'80%':'100%', height: '100%', transition: '0.3s'}}>
                    
                </div> */}
            </div>
            
        </div>
    )
}

export default AdminMain
