import io from "socket.io-client";
import { baseAPI } from "../../config";

// export const socketObj = io("wss://api.acdexpo.com", {
//     transports: ["polling"]
//   });

export const initSocket = ()=>{
  return io("wss://"+baseAPI?.split('https://')[1], {
    transports: ["websocket"]
  });
}