
import agendaIcon from '../../img-src/icons/agenda.png';
import React, { useState, useEffect } from 'react'
import { v4 } from 'uuid';
function Agenda(props) {
    var agendaItems = [];
    const [aI, setAI] = useState();
    useEffect(() => {
        
        // //1console.log(props);
        //sort by time then map into elements
        agendaItems = props.agendaItems.sort((a,b)=>{
            if(a.time < b.time) { return -1; }
            if(a.time > b.time) { return 1; }
            return 0;
        }
            
        ).map((ai,i)=>
            <div className='agenda-item' key={'auditorium-agenda-item'+i}>
                <div className='ai-details'>
                    <p className='ai-speaker-name'>{ai.speakerName}</p>
                    <p className='ai-title'>{ai.title}</p>
                </div>
                <p className='ai-time'>{ai.time}</p>
            </div>
        )
        setAI(agendaItems);
    }, [props])
    return (
        <div className='agenda-c' style={props.style}>
            <div className='title' style={{visibility: props.showTitle?'visible':'hidden'}}>
                <img src={agendaIcon} style={{filter:'invert(1)', width:'50px'}}/>
                <h2>Agenda</h2>
                
            </div>
            <h2 className='alt-title' style={{display: !props.showTitle?'block':'none'}}>Agenda</h2>
            <div className='agenda-items-wrapper'>
                {aI}
            </div>
            
        </div>
    )
}

export default Agenda
