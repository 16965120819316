import React, { useState, useEffect } from 'react'
import downloadicon from '../../img-src/icons/download.png'
import calendaricon from '../../img-src/icons/calendar.png'
import formicon from '../../img-src/icons/form.png'
import arrowicon from '../../img-src/icons/right-arrow.png'
import questionIcon from '../../img-src/icons/question.png'
import searchicon from '../../img-src/icons/search.png'
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import axios from 'axios'

import { adminMinWidth } from '../content'

import lorealLogo from '../../img-src/loreal logo.png';
import burgerIcon from '../../img-src/icons/menu-icon.png';
import AdminNav from './AdminNav';

import { getToken, removeAdminId, removeUserSession  } from '../../Utils/Common';
import { fixfontsize } from '../helpers';

import PanelTable from '../Util/PanelTable';

import calendarIcon from '../../img-src/icons/calendar.png'
import clockIcon from '../../img-src/icons/clock.png'
import { v4 } from 'uuid'
import FilterBar from '../Fields/FilterBar'
import DDL from '../Fields/DDL'
import { baseAPI } from '../../config'
const columnWidth = '200px'
function AdminFeedbackForms(props) {
    
    // const data = [{month: 'January', value: 500},{month: 'Febuary', value: 450},{month: 'March', value: 380},{month: 'April', value: 600},{month: 'May', value: 1200},{month: 'June', value: 300},{month: 'July', value: 200},{month: 'August', value: 50},{month: 'September', value: 150}, {month: 'October', value: 300},{month: 'November', value: 400},{month: 'December', value: 500}];
    const [sortByDate, setSortByDate] = useState(false)
    const [dateFilter, setDateFilter] = useState('')
    const [_MOCK_USERS_, set_MOCK_USERS_] = useState([])
    const [tableElements, setTableElements] = useState()
    
    
    const [monthSwitcherIndex, setMonthSwitcherIndex] = useState(0)
    const [playanim, setPlayanim] = useState(false)

    const [navExpanded, setNavExpanded] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const [feedBackFormResponses, setFeedBackFormResponses] = useState(null)
    const [feedBackFormResponsesEls, setFeedBackFormResponsesEls] = useState(null)

    const [totalRegistrants, setTotalRegistrants] = useState(0)
    const [data, setData] = useState([])

    //proccessed form responses into readable format
    const [procFormResponses, setProcFormResponses] = useState(null)
    const handleLogout = (e)=>{
        e.preventDefault();
        removeUserSession();
        removeAdminId()
        props.history.push('/admin-login')
    }

    //filter selected values
    
    const [selectedDay, setSelectedDay] = useState(null)
    

    const [regForms, setRegForms] = useState([])


    const [pastEvents, setPastEvents] = useState([])
    const [selectedEvent, setSelectedEvent] = useState('')

    const [tabState, setTabState] = useState(0)
    useEffect(() => {
        document.title = "L'Oréal Expo - Feedback Forms"
        axios.get(baseAPI+'/api/event/past', {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data);
            setPastEvents(r.data)
        }).catch(e=>{
            //1console.log(e);
            if(e?.response.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
        })
    }, [])
    
    useEffect(() => {
        
        getFeedbackFormResponses()
    }, [selectedEvent])
    function onSubmit(){
        
    
    }
    useEffect(() => {
        
        
        setPlayanim(true)
        setTimeout(() => {
            setPlayanim(false)
        }, 1000);
    }, [monthSwitcherIndex])


    useEffect(() => {
        
       if(sortByDate){
            var x = _MOCK_USERS_.slice()

            x.sort((a,b)=>{return new Date(a.timeOfRegistration)-new Date(b.timeOfRegistration)})
            //1console.log(x);
            set_MOCK_USERS_(x);
       }
    }, [sortByDate])

    const rootStyles={
        display: 'flex', flexDirection:'column', 
        justifyContent:'center', 
        alignItems: 'center',
        margin: '5% 0'
    }

    function onDownloadUserData(){
        let csv = ''
        var items = regForms?.slice();
        // Loop the array of objects
        items = items?.map(a=>{return{
            id:a.id,
            phone:a.phone,
            name:a.name,
            email:a.email,
            clinical_region:a.clinical_region,
            country:a.country,
            created_at:a.created_at,
            address:a.address,
            area:a.area,
            city:a.city
        }})
        for(let row = 0; row < items.length; row++){
            let keysAmount = Object.keys(items[row]).length
            let keysCounter = 0

            // If this is the first row, generate the headings
            if(row === 0){

            // Loop each property of the object
                for(let key in items[row]){

                                    // This is to not add a comma at the last cell
                                    // The '\r\n' adds a new line
                                    //1console.log(key);
                    // if(key==='password'||key==='remember_me_token'||key==='dummy_id'||key==='user_id')
                    //     continue
                    
                    csv += key.charAt(0).toUpperCase()+key.substr(1,key.length-1) + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                    keysCounter++
                }
            }
            else{
                for(let key in items[row]){
                    //1console.log((items[row][key])?.toString().replace(/,/g,' '));
                    // if(key==='password'||key==='remember_me_token'||key==='dummy_id'||key==='user_id')
                    //     continue
                    // //1console.log((items[row][key] + (keysCounter+1 < keysAmount ? ',' : '\r\n' )).replace(/,/g,'-'));
                    csv += (items[row][key])?.toString().replace(/,/g,' ') + (keysCounter+1 < keysAmount ? ',' : '\r\n' )
                    keysCounter++
                }
            }

            keysCounter = 0
        }

        //  download the .csv by creating a link
        let link = document.createElement('a')
// //1console.log(csv);
        let rand = v4()
        //1console.log('rand', rand);
        link.id = 'download-csv-user-data-'+rand
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', 'userdata.csv');
        document.body.appendChild(link)
        document.querySelector('#download-csv-user-data-'+rand).click()
    }
    


    




    //---------------------------------------FEEDBACKFORM RESPONSES TABLE------------------------------------
    function getFeedbackFormResponses(){
        axios.get(baseAPI+'/api/analysis/feedbackFormResponses/'+selectedEvent, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
            //1console.log(r.data);
            setFeedBackFormResponses(r.data)
            



        }).catch(e=>{
            //1console.log(e);
            if(e?.response.status===401){
                removeUserSession()
                removeAdminId()
                props.history.push('/')
            }
        })
    }
    useEffect(() => {
        
        getFeedbackFormResponses()
    }, [])

    //generate feedback form responses elements when they are retrieved

    const [ratingSummaryEls, setRatingSummaryEls] = useState('')
    useEffect(() => {

        
        let rows = []
        var rowsObj = []

        let questionsLookup = feedBackFormResponses?.feedback_questions
        //1console.log(questionsLookup);
        //1console.log(feedBackFormResponses?.user_responses);
        let qs = questionsLookup
        let res = feedBackFormResponses?.user_responses




        //SUMMARY
        /*
            get an array in the format
            [
                {question: '', answers: ['','',...]}
            ]
        
        

        */
       let summary = []
        qs?.forEach(q=>{
            if(q.type==='rating'||q.type==='mcq'){
                let temp = []
                //let s = []
                res.forEach(a=>{
                    a.responses.forEach(r=>{
                        if(r.question_id===q.id){
                            //temp = {...temp, answer: r.answer}
                            temp.push(r.answer)
                            
                        }
                            
                    })
                })
                summary.push({question:q.question, answers:temp.sort()})
                
             }
            
        })

        //1console.log(summary);
        //get percentages of each answer for each question
        summary.forEach(q=>{
    
   
            let aStats = {}
            const totalItems = q.answers.length
            const uniqueItems = [...new Set(q.answers)]
            uniqueItems?.forEach(currAns => {
              const numItems = q.answers.filter(c => c === currAns) 
            //   //1console.log(`in Question ${q.question},  answer ${currAns} represents ${numItems.length * 100 / totalItems}%`)
            })
       
            
        })
        setRatingSummaryEls(summary.map((q,qi)=>{
    
   
            
            const totalItems = q.answers.length
            const uniqueItems = [...new Set(q.answers)]
            
            const sum = q.answers.reduce((a, b) => parseInt(a) + parseInt(b), 0);
            const avgRating = (sum / q.answers.length) || 'N/A';
            return <div className='panel-style' style={{justifyContent:'flex-start', alignItems:'flex-start', height:'auto', marginBottom:'5%', backgroundColor:'#F7F2EC'}}>
                        <p style={{marginLeft:'4%', fontWeight:'bold', fontSize:'1.5rem'}}>Q{qi+1} - {q.question}</p>
                        <p style={{width:'90%', marginLeft:'4%', display: avgRating==='N/A'?'none':'flex'}}><b>{'Average rating: '+(parseFloat(avgRating).toFixed(2).replace(/\.0+$/,'') )}</b></p>
                        {/* <p>10 out of 10 people answered this question.</p> */}
                        <div className='bar-chart-container'>

                            {
                                uniqueItems?.map(currAns => {
                                    const numItems = q.answers.filter(c => c === currAns) 
                                    //1console.log(`in Question ${q.question},  answer ${currAns} represents ${numItems.length * 100 / totalItems}%`)
                                    return <div className='bar-container'>
                                                <div className='bar'>
                                                    <div className='bar-fill' style={{height: (numItems.length * 100 / totalItems)+'%'}}></div>
                                                    <p className='answer'>{parseFloat(numItems.length * 100 / totalItems ).toFixed(2).replace(/\.0+$/,'')}%</p>
                                                </div>
                                                <p className='percentage'>{currAns}</p>
                                            </div>
                                    
                                  })
                            }
                            

                            
                        </div>
                            
                    </div>
            
       
            
        }))


        //make array of objects that includes question name and answers
        // let mergedQuestionsAndAnswers = y?.user_responses?.map(a=>{return{
        //     ...a,
        //     questionName:x.filter(b=>b.id===a.question_id)[0]?.question
        // }})
        try{
            for(let i=0; i<=res?.length-1;i++){
                //each response for user i
                for(let j=0; j<=res[i].responses?.length-1;j++){
                    res[i].responses[j] = { ...res[i].responses[j], questionName:qs.filter(q=>q.id===res[i].responses[j].question_id)[0]?.question}
                }
                
            }
        }
        catch{

        }
        let max = 0;
        feedBackFormResponses?.feedback_questions.forEach( i =>{
            
            if(i.question.length>max){
                max = i.question.length
                
            }
        })
        //1console.log('max',max);
        let qColWidth = max >=150?'600px':'300px'
        //1console.log('qcolwidth', qColWidth);
        
        //1console.log('res',feedBackFormResponses?.feedback_questions.length);
        var header = <ul className='a-fb-res-h' style={{gridTemplateColumns:'150px 150px '+((qColWidth+' ').repeat(feedBackFormResponses?.feedback_questions.length))}}>
                        <li  className='a-feedbackform-responses-header'>{'Date'}</li>

                        <li className='a-feedbackform-responses-header'>{'Time'}</li>
                        
                        {
                            feedBackFormResponses?.feedback_questions?.map((x,i)=>
                                <li  className='a-feedbackform-responses-header'>{'Q'+(i+1)+ '- '+ x.question}</li> 
                            )
                        }
                    </ul>

        //1console.log('feedBackFormResponses', feedBackFormResponses);
       
           
            

            //1console.log('rowsobj set');
            //1console.log(rowsObj);
            setProcFormResponses(rowsObj)
            
            
            //1console.log('%cMeged Qs and As','font-size:30px;color:blue',res);

           
            rows = res?.map((q,i)=>
                <ul className='a-fb-res' key={'feedbackform-responses-item-'+i} style={{ marginBottom:'1%',gridTemplateColumns:'150px 150px '+((qColWidth+' ').repeat(feedBackFormResponses?.feedback_questions.length))}}>
                {/* Question */}
                
                {/* date */}
                {/* <li style={{flex: '0 1 20%'}}>{q?.created_at?.split('T')[0]}</li> */}
                {/* Time */}
                <li style={{padding:'10px',textAlign:'center', borderBottom:'1px solid black'}}>{q?.created_at.split('T')[0]}</li>
                <li style={{padding:'10px',textAlign:'center', borderBottom:'1px solid black'}}>{q?.created_at.split('T')[1].split('.')[0]}</li>
                {/* Answer */}
                    {/*                 
                    q?.responses?.map(r=>
                        <li style={{flex: '0 1 20%'}}>{r?.answer}</li>    
                    ) */}
                
                {
                    
                    feedBackFormResponses?.feedback_questions?.map(x=>
                        <li style={{padding:'10px', textAlign:'center', borderBottom:'1px solid black'}}>{q?.responses.find(k=>k.question_id===x.id)?.answer}</li> 
                    )
                }
                
                </ul>
                
                
            )
            
            setFeedBackFormResponsesEls(
                <div className='' style={{width: '80%', backgroundColor: '#F7F2EC', padding: '15px', boxSizing: 'border-box', overflowX: 'auto', height:'500px', borderRadius:'0 1 15px 15px'}}>
                    {header}
                    {rows}
                </div>
            )
        
        
    }, [feedBackFormResponses])


    
    
  

    //sort by time
    

    const filterBarStyles = {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: '#155473',
        color:'white',
        width: '80%',
        height: '40px'
    }
    // $ripple-color: #00589a;
    // $clr-blue: #155473;
    // $clr-black: #000000;
    // $clr-orange: #D87152;
    // $clr-blue-green: #32AAA9;
    // $clr-pale: #F7F2EC; 





    


    const tabSelectorStyles = {
        display: 'flex',
        justifyContent:'flex-start',
        width: '80%',
        borderBottom: '1px solid black',
        
    }
    return (


        <div style={{minWidth: adminMinWidth+'px'}}>
            <header className='a-header'>
                <div className='mobile-overlay' style={{display: isMobile?'flex':'none'}}>
                    <a href='' className='a-h-logo' ><img src={lorealLogo}/></a>
                    <p>Please login using a laptop or desktop.</p>
                </div>
                <div className='a-h-start'>
                    <button className='a-h-burgerBtn'><img src={burgerIcon} onClick={()=>setNavExpanded(!navExpanded)} /></button>
                    <a href='' className='a-h-logo'><img src={lorealLogo}/></a>
                </div>
                <div className='a-h-end'>
                <a href='' className='a-link-img' onClick={()=>props.history.push('/admin/doc')} ><img src={questionIcon}  style={{height: '40px'}}/></a>
                    <a href='' className='a-link' onClick={e=>handleLogout(e)}>Logout</a>
                </div>
            </header>
            
            <div style={{display: 'flex', width: '100%'}}>
                <AdminNav style={{width: navExpanded?'16%':0}} active={4}/>

                <div style={{
                    display: 'flex', flexDirection:'column', 
                    justifyContent:'flex-start', 
                    alignItems: 'center',
                    margin: '2% 0',
                    width:navExpanded?'84%':'100%'
                }} className='registration-forms-a' id='registration-forms'>



                <h3 className='p-title' style={{textAlign: 'center', marginBottom:'5%'}}>Feedback Forms</h3>

                {/* past events dropdown */}
                <div className='filter-bar' style={{borderRadius:'15px', marginBottom:'2%'}}>
                    <div className='text-field' style={{justifyContent:'flex-start', marginLeft:'2%'}}>   
                        <span className='label' style={{color:'white', marginRight:'5%', fontWeight:'bold'}}>{'Select Event'}</span>
                        
                        <select name={'Event Name'} value={selectedEvent} onChange={e=>setSelectedEvent(e.target.value)} style={{height:'80%'}}>
                            <option value='' >Please Select</option>
                            {
                                pastEvents?.map((ev,i)=>
                                    <option value={ev?.id} key={'admin-reg-forms-past-events-'+i}>{ev?.title}</option>
                                )
                            }
                            
                            
                        </select>
                    </div>
                </div>
                {/* select summary or responses */}
                <div className='tab-selector' style={{...tabSelectorStyles, marginBottom:'3%'}}>
                    <button style={{borderBottom: tabState===0?'4px solid #155473':'4px solid transparent', paddingBottom:'15px', marginRight:'3%'}} onClick={()=>setTabState(0)}>Summary</button>
                    <button style={{borderBottom: tabState===1?'4px solid #155473':'4px solid transparent', paddingBottom:'15px'}} onClick={()=>setTabState(1)}>Responses</button>
                </div>
            
                
            

            

            
            
            
            

                {/* FEEDBACK FORM RESPONSES */}
            
                {/* bar charts */}
                <div className='summary-feedbackforms' style={{width:'100%', display:tabState===0?'flex':'none', flexDirection:'column',alignItems:'center'}}>
                    {ratingSummaryEls}
                </div>

                {/* table of responses */}
                <div style={{width:'100%', display:tabState===1?'flex':'none', flexDirection:'column',alignItems:'center'}}>

                {feedBackFormResponsesEls}
                </div>


                
            </div>

            </div>
            
        </div>
        
    )
}

export default AdminFeedbackForms
