import { createSlice } from '@reduxjs/toolkit'


export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    createOrEdit: '',
    handouts: [],
    giveaways: [],
    agenda: [],
    chat: [],
    feedbackQuestions: [],
    basicInfo: {title: '', private: false, logo: '', description: '', event_datetime: '', open_datetime: ''}
    
  },
  reducers: {
    updateCreateOrEdit:(state, action)=>{
      state.createOrEdit = action.payload
    },
    updateHandouts:(state, action)=>{
      state.handouts = action.payload
    },
    updateGiveaways:(state, action)=>{
        state.giveaways = action.payload
    },
    updateAgenda:(state, action)=>{
        state.agenda = action.payload
    },
    updateChat:(state, action)=>{
        state.chat = action.payload
    },
    updateQuestions:(state, action)=>{
        state.feedbackQuestions = action.payload
    },
    updateBasicInfo:(state, action)=>{
      state.basicInfo = action.payload
    }
    
  },
})

// Action creators are generated for each case reducer function
export const {updateHandouts,updateGiveaways,updateAgenda,updateChat,updateQuestions,updateCreateOrEdit, updateBasicInfo } = adminSlice.actions

export default adminSlice.reducer

