import { createSlice } from '@reduxjs/toolkit'


export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    dummyId: ''
    
  },
  reducers: {
    setDummyIdGlobal: (state, action)=>{
        state.dummyId = action.payload.value
    }
    
  },
})

// Action creators are generated for each case reducer function
export const {setDummyIdGlobal} = globalSlice.actions

export default globalSlice.reducer

