
import React, { useState, useEffect } from 'react'
import giveawayIcon from '../../img-src/icons/giveaway.png';
import arrow from '../../img-src/icons/down.png';
import handoutImg from '../../img-src/EDerm/stars.png';
import DDL from '../Fields/DDL';
import Input from '../Fields/Input';
import { egyptCities } from '../content';
import axios from 'axios';
import { getToken, getUser } from '../../Utils/Common';
import { RegionDropdown } from 'react-country-region-selector';
import defaultImg from '../../img-src/icons/giveaway-placeholder.png'
import { baseAPI } from '../../config';
function Giveaways(props) {
    const [city, setCity] = useState('')
    const [area, setArea] = useState('')
    const [address, setAddress] = useState('')
    const [expandAddress, setExpandAddress] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const [giveaways, setGiveaways] = useState([])
    const [currentEventId, setCurrentEventId] = useState(null)
    useEffect(() => {

        //show title is to identify between whether this component is rendered in the landing or auditorium components
        if(props.showTitle){
            
            setCity(props?.city)
            setArea(props?.area)
            setAddress(props?.address)
        }
        
        
        // //1console.log(props.giveaways.picture);
        //style={{backgroundImage: 'url('+defaultImg+')', backgroundSize:'50%', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}
        setGiveaways(props.giveaways.map((g,i)=>
        <div className='giveaway-card'  key={'giveaways-landing-'+(i)}>
            <p className='giveaway-name'>{g.title}</p>
            <div className='giveaway-img' style={{backgroundImage: 'url('+(g.picture||defaultImg)+')', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}></div>
            <p className='giveaway-dec'>{g.description}</p>
        
        </div>
        )
        )
        


    }, [props])
    async function saveClickAnalytics(btn, eventId){
        if(eventId===null){
            return axios.post(baseAPI+'/api/button/click/add', {label: btn})
            
        }
            

        else
            return axios.post(baseAPI+'/api/button/click/add', {label: btn, event_id: eventId})
    }
    function getCurrentEventId(){
        axios.get(baseAPI+'/api/event/checkRunning').then(r=>{
            setCurrentEventId(r.data.id)
        }).catch((error)=>{
            console.log(error);
        })
    }
    useEffect(() => {
        
        getCurrentEventId()
    }, [])
    function onSubmit(){
        setLoading('Loading...')
        setError('')
        saveClickAnalytics('Edit Address - Landing', currentEventId)
        axios.post(baseAPI+'/api/user/virtualLogin', {phone: getUser()}).then(r=>{
            axios.put(baseAPI+'/api/user/address/update/'+r.data.id, {address: address, city:city, area:area}, {headers: {'Authorization': 'bearer '+getToken()}}).then(r=>{
                //1console.log(r);
                setLoading('Saved!')
            }).catch(e=>{
                setLoading('')
                setError('An error occurred, please try again.')
                //1console.log(e);
            })
        }).catch(e=>{
            setLoading('')
            setError('An error occurred, please try again.')
        })
        
    }
    
    return (
        <div className='giveaways-c' style={props.style}>
            <div className='title' style={{visibility: props.showTitle?'visible':'hidden'}}>
                <img src={giveawayIcon} />
                <h2>Giveaways</h2>
                
            </div>
            <h2 className='alt-title' style={{display: !props.showTitle?'block':'none'}}>Giveaways</h2>
            <div className='giveaway-wrapper'>
                {giveaways}
                
                
            </div>
            <div className='giveaway-wrapper' style={{opacity: 1, display: props.showTitle?'flex':'none'}}>
                
                <div className='form'>
                    <button className='cta-btn-blue' onClick={()=>setExpandAddress(!expandAddress)}><span>Edit Address</span></button>
                    <div style={{visibility:expandAddress?'visible':'hidden', overflow: 'hidden', width: '100%', marginTop: '5%'}}>
                        {/* <DDL name='City' value={city} onChange={e=>setCity(e.target.value)} ddlItems={egyptCities} /> */}
                        <div className='text-field'>
                            <span className='label'>City</span>
                            <RegionDropdown
                            country={props.country}
                            value={city}
                            onChange={(val) => setCity(val)} />
                            
                        </div>
                        <Input name='Area' value={area} onChange={e=>setArea(e.target.value)} />
                        <Input name='Address' value={address} onChange={e=>setAddress(e.target.value)} />
                        <button className='primary-btn' disabled={loading==='Loading...'} style={{transition:'0s'}} onClick={()=>onSubmit()}>Submit</button>
                        <p className='loading'>{loading}</p>
                        <p className='error'>{error}</p>
                    </div>
                    
                </div>
                
            </div>
            
        </div>
    )
}

export default Giveaways
